import { useState, useEffect } from "react";
import { createMuiTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import Divider from "@mui/material/Divider";
import { Typography, Grid, Button } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import WindowOutlinedIcon from "@mui/icons-material/WindowOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@material-ui/core/IconButton";
import menu from "../../assets/menu.svg";
import box from "../../assets/boxView.svg"
import selectedList from "../../assets/selectedList.svg"
import selectedBox from "../../assets/selectedBox.svg"
import TabularData from "./TabularData";
import GridTable from "./GridTable";
import { useAppDispatch } from "../../redux/store";
import { documentFilterStatus } from "../../redux/slices/filterDocumentSlice";
import { uploadDocStatus } from "../../redux/slices/uploadFileSlice";
import { newFileUpload } from "../../redux/slices/newDocUploadSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import BoxView from "./BoxView";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { uploadDataCategory } from "../../redux/slices/getUploadDocSlice"
import { uploadBtnDataCategory } from "../../redux/slices/getUploadBtnSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { AnySchema } from "yup";
import { documentStatus } from "../../redux/slices/getDocumentManagementSlice";
import InputLabel from '@mui/material/InputLabel';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// i have to dispatch uploadDataCategory on dropdown select
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  header: {
    justifyContent: "space-between",
    marginTop: "26px",
    marginBottom: "17px",
    width: "70%",
    paddingLeft: "3%",
    // paddingRight: "1%",
    fontSize: "18px !important",
    float: "left",
  },
  // check in dev about font size and marginRight
  checkBoxLabel: {
    fontSize: "18px",
    "& .MuiCheckbox-root": {
      marginRight: "13px",
    },
    // "& .css-ahj2mt-MuiTypography-root": {
    //   fontSize: "18px",
    //   fontWeight: 500,
    //   lineHeight: "137%",
    //   color: "black",
    //   fontFamily: "Poppins !important",
    // },
    [`& span`] : {
      fontSize: "18px",
      fontFamily: "Poppins !important",
    }
  },

  listDesign: {
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "1%",
    fontSize: "18px !important",
    marginTop: "17px",
    marginBottom: "17px",
    width: "26%",

  },

  modalBox: {
    width: 400,
    backgroundColor: '#FFFFFF',
    // border: '2px solid #000',
    padding: '32px',
    marginTop: '23%',
    marginLeft: '50%',
    borderRadius: '8px',
    height: '220px',
    transform: 'translate(-50%, -50%)',
  },
  typemodaltitle: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#1071BA',
    textAlign: 'center',
    marginBottom: '23px',
  },
  typemodalsubtitle: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#1071BA',
  },
  typeBtnContainer: {
    marginTop: '40px',
    marginLeft: '93px',
  },
  fileUploadContainer: {
    marginTop: '40px',
    marginLeft: '73px',
  },
  uploadBtn: {
    backgroundColor: '#1071BA',
    width: '115px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    color: '#FFFFFF',
  },
  resetBtn: {
    width: '115px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    marginLeft: '5px',
    color: '#1071BA',
  },
  upload: {
    backgroundColor: 'white',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    marginBottom: '6px',
    width: "196px",
  },
  fileuploadbtn: {
    // backgroundColor: '#1071BA',
    width: '123px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    color: '#1071BA',
    marginLeft: "12px",
  },
  docuploadbtn: {
    width: '200px',
    height: '54px',
    border: '1px solid #1071BA',
    boxSizing: 'border-box',
    borderRadius: '8px',
    color: '#1071BA',
    fontSize: 18,
  },
  fileSelectBtn: {
    width: '123px',
    height: '34px',
    border: '1px solid #1071BA',
    borderRadius: '5px',
    color: '#FFFFFF',
    backgroundColor: '#1071BA',
  }

}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const PageDetail = ({ docupdate,isDocToWork}: any) => {
  const [pageView, setPageView] = useState("list");
  const [filter, setFilter] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const optionNo = useSelector((state: RootState) => state.submenulist.value);
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(" ");
  const [userId, setUserId] = useState(" ");
  const token = localStorage.getItem("Access_Token");
  const [submitCredential, setSubmitCredential] = useState(
    {
      access_token: token,
      document_file: null,
      question_id: " ",
    }
  )

  useEffect(() => {
    // console.log(optionNo, "asdfghjkllll", filter);
    setFilter([]);
  }, [optionNo]);
  // purpose done by above
  const handleFilter = (status: any) => {
    let statusExist = false;
    filter?.find((val) => {
      if (val === status) {
        statusExist = true;
      }
    });
    if (statusExist) {
      const a = [...filter];
      const b = a.filter((val) => val != status);
      setFilter(b);
    } else {
      const a = [...filter];
      a.push(status);
      setFilter(a);
    }
  };
  useEffect(() => {
    const payload = {
      access_token: docupdate.access_token,
      applicability: docupdate.applicability,
      status: [...filter],
      type: docupdate.type,
    };

    dispatch(documentFilterStatus({ ...payload }));

  }, [filter]);
  const domains = useSelector((state: RootState) => state.documentlist.docList);
  const uploadCredential = useSelector((state: RootState) => state.uploadlist.uploadList);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const [typeModal, setTypeModal] = useState(false);
  const handleTypeModalOpen = () => setTypeModal(true);
  const handleTypeModalClose = () => setTypeModal(false);
  const [replaceData, setReplaceData] = useState({
    access_token: token,
    type: " ",
  });

  const uploadType = (type: string) => {
    setModalOpen(false)
    setTypeModal(true)
    // setReplaceData({ ...replaceData, type: type });
    setReplacePayload({ ...replacePayload, type: type })
    setUploadPayload({ ...uploadPayload, type: type })
    // here i have to set one more state that is upload state
  }
  useEffect(() => {
    if (replaceData.type.length > 1) {
      dispatch(uploadDataCategory({ ...replaceData }))
    }
  }, [replaceData])

  const [uploadLinkModal, setUploadLinkModal] = useState(false);
  const handleLinkModalOpen = () => {
    setReplaceData({ ...replaceData, type: 'upload' });   // new today
    handleTypeModalClose()
    linkUploadModalOpen();                        // here previous close
    // setUploadLinkModal(true);                      // need to close it
    setUploadPayload({ ...uploadPayload, upload_type: 'upload' });  //uploadPayload should be carry
    setUnlinkedPayload({ ...unlinkedPayload, upload_type: 'upload' });  // unlinkedPayload no need anymore
    // dispatch(uploadBtnDataCategory({ ...replaceData })) //delet
    setDocReplace(false)  //it will be carry
  }
  const handleLinkModalClose = () => setUploadLinkModal(false);

  const [replaceModal, setReplaceModal] = useState(false);
  const replaceModalOpen = () => {
    setReplaceData({ ...replaceData, type: 'replace' });   // new today
    handleTypeModalClose();
    setReplaceModal(true);
    setReplacePayload({ ...replacePayload, upload_type: 'replace' })
    setDocReplace(true);
  }
  const replaceModalClose = () => setReplaceModal(false);

  const [replaceName, setReplaceName] = useState('');
  const [linkName, setLinkName] = useState('');
  // console.log(linkName,'xxxxxxxxxxxxxx');
  const [isReplace, setIsReplace] = useState(false);
  const [isNewUpload, setNewUpload] = useState(false);
  const [islinkUpload, setIslinkUpload] = useState(false);
  const [replacePayload, setReplacePayload] = useState({
    access_token: token,
    question_id: '',   // extra add row id in this state
    iso_control: '',
    upload_type: '',
    file: '',
    type: '',
    id: '',
  });
  // console.log(replacePayload,'rep');

  const [uploadPayload, setUploadPayload] = useState({
    access_token: token,
    file: '',
    upload_type: '',
    question_id: '',
    type: '',
    id: -1,
  })

  const [unlinkedPayload, setUnlinkedPayload] = useState(
    {
      access_token: token,
      file: '',
      upload_type: '',
    }
  )
  const [isNewFileSelected, setNewFileSelected] = useState(false);
  const [isNewFileUploaded, setNewFileUploaded] = useState(false);
  const getreplacePayload = (value: any) => {
    setReplacePayload({ ...replacePayload, question_id: value.question_id, iso_control: value.iso_control, id: value.id })
    setNewFileSelected(true);
    setReplaceName('');
  }
  const [isContorlLinkedSelected, setControlLinkSeleted] = useState(false);
  const getlinkUploadPayload = (value: any) => {
    setUploadPayload({ ...uploadPayload, question_id: value.question_id, id: value.id })
    setControlLinkSeleted(true)
    setLinkName(''); // for clearing history
  }

  const handleChange = (event: any) => {
    setReplaceName(event.target.value);
  };
  const handleLinkName = (event: any) => {
    setLinkName(event.target.value);
  }


  const [uploadModal, setUploadModal] = useState(false);
  const handleUploadModalOpen = () => setUploadModal(true);
  const handleUploadModalClose = () => setUploadModal(false);
  const [fileName, setFileName] = useState('');
  const replaceAction = () => {
    setReplaceName('');
    replaceModalClose();
    setReplaceModal(false);
    handleUploadModalOpen();
  }
  const cancelReplace = () => {
    setReplaceName('');
    replaceModalClose();
    setNewFileSelected(false);
  }
  const onFileChange = (event: any) => {
    {
      !isDocReplace ?
        (
          setUploadPayload({ ...uploadPayload, file: event.target.files[0] })

        ) :
        setReplacePayload({ ...replacePayload, file: event.target.files[0] })
    }
    setNewFileUploaded(true);
    setFileName(event.target.files[0].name);
  };
  const uploadFile = () => {
    handleUploadModalClose();
    setFileName('');
    setNewFileUploaded(false);  // this is for to show the file name when we upload new file there 

    if (!isDocReplace) {
      setNewUpload(false)
      dispatch(newFileUpload({ ...uploadPayload }))
        .then(unwrapResult)
        .then((response: any) => {
         
          dispatch(documentFilterStatus({ ...docupdate, status: [] }));
          dispatch(documentStatus({ ...docupdate }));
          // dispatch(documentStatus({ ...docupdate }));
          // dispatch(documentStatus({ ...docupdate }));
          setNewUpload(true)
        })
        .catch((err) => {
          console.log(err, 'errrrrr');
          setNewUpload(false);
        })
    }
    else {
      setIsReplace(false);
      dispatch(uploadDocStatus({ ...replacePayload }))
        .then(unwrapResult)
        .then((response: any) => {
          setIsReplace(true);
        })
        .catch((err) => {
          setIsReplace(false);
        });
    }
  }
 
  useEffect(() => {
    if (isReplace) {
      dispatch(documentStatus({ ...docupdate }));
      dispatch(documentStatus({ ...docupdate }));
    }
  }, [isReplace]);

  useEffect(() => {
    if (isNewUpload) {
      // dispatch(documentStatus({ ...docupdate }));
      dispatch(documentFilterStatus({ ...docupdate, status: [] }));
    }
  }, [isNewUpload]);

  // for link and dont link upload 
  const [linkUploadModal, setLinkUploadModal] = useState(false);
  const linkUploadModalOpen = () => setLinkUploadModal(true);
  const linkUploadModalClose = () => setLinkUploadModal(false);
  // const linkUpload = () => {
  //   handleLinkModalClose();
  //   linkUploadModalOpen();
  //   setdocUploadType({ ...docuploadType, link: true });  
  // }
  const unLinkeUpload = () => {
    handleLinkModalClose();
    handleUploadModalOpen();
  }
  const [isDocReplace, setDocReplace] = useState(false)
  const linkUploadAction = () => {
    setLinkName('');   // for clearing history
    linkUploadModalClose();
    handleUploadModalOpen();
    setControlLinkSeleted(false)
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Divider />
        <Grid container className={classes.header}>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.includes("Approved") ? true : false}
                    onChange={(e) => handleFilter("Approved")}
                    // style={{fontFamily: '"Poppins" !important'}}
                  />
                }
                label="Approved"
                className={classes.checkBoxLabel}
              />
            </FormGroup>
            <Typography style={{fontFamily: '"Poppins" !important'}}></Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.includes("Published") ? true : false}
                    onChange={(e) => handleFilter("Published")}
                  />
                }
                label="Published"
                className={classes.checkBoxLabel}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.includes("In draft") ? true : false}
                    onChange={(e) => handleFilter("In draft")}
                  />
                }
                label=" In Draft"
                className={classes.checkBoxLabel}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
            {/* <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      e.target.checked ? handleFilter("View all") : " "
                    }
                  />
                }
                label="View all"
                className={classes.checkBoxLabel}
              />
            </FormGroup> */}
          </Grid >

        </Grid>


        {/* <Grid item xs={4} sm={4} md={4} lg={4} xl={4}> */}
        <Grid container className={classes.listDesign}>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <IconButton onClick={(e) => setPageView("box")}>
              {pageView === "box" ? <img src={selectedBox} alt="boxSelected"></img> : <img src={box} alt="box"></img>}

              {/* box view is here  */}
            </IconButton>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
            <IconButton onClick={(e) => setPageView("list")}>
              {pageView === "list" ? <img src={selectedList} alt="menuicon"></img> : <img src={menu} alt="menuicon"></img>}

            </IconButton>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>

            <Button variant="outlined" component="span" onClick={handleModalOpen} className={classes.docuploadbtn}>
              Upload
            </Button>
          </Grid>
        </Grid>
        {/* </Grid>
        </Grid> */}
        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            <Typography className={classes.typemodaltitle} variant="h6" component="h2">
              Choose the document type to upload
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Choose document type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                // label="Age"
                // onChange={handleChange}
                label="Choose document type"
              >
                <MenuItem value={'Policies'} onClick={() => uploadType('Policies')}>Policy</MenuItem>
                <MenuItem value={'Procedures'} onClick={() => uploadType('Procedures')}>Procedure</MenuItem>
                <MenuItem value={'Standarts'} onClick={() => uploadType('Standards')}>Standard</MenuItem>
                <MenuItem value={'Guidelines'} onClick={() => uploadType('Guidelines')}>Guideline</MenuItem>
                <MenuItem value={'Meeting'} onClick={() => uploadType('Meeting')}>Meeting minutes</MenuItem>
                <MenuItem value={'Others'} onClick={() => uploadType('Others')}>Other documents</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Modal>
        {/* modal no.2 is starting from here */}
        {/*not disabling */}
        <Modal
          open={typeModal}
          onClose={handleTypeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            {/* <Typography className={classes.typemodaltitle} variant="h6" component="h2">
                    Replace or upload?
                  </Typography> */}
            <Typography className={classes.typemodalsubtitle} variant="h6" component="h2">
              Do you want to replace the existing document or upload a new document?
            </Typography>
            <Grid container className={classes.typeBtnContainer}>
              <Grid item>
                <Button variant="contained" className={classes.uploadBtn} onClick={handleLinkModalOpen}>Upload</Button>
              </Grid>
              <Button variant="outlined" className={classes.resetBtn} onClick={replaceModalOpen}>Replace</Button>
              <Grid item>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* modal 3 for upload according to client no need more */}

        {/* <Modal
          open={uploadLinkModal}
          onClose={handleLinkModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            <Typography className={classes.typemodaltitle} variant="h6" component="h2">
              Do you want to link document to the security control?
            </Typography>

            <Grid container className={classes.typeBtnContainer}>
              <Grid item>
                <Button variant="contained" className={classes.uploadBtn} onClick={linkUpload}>Link</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" className={classes.resetBtn} onClick={unLinkeUpload}>Don’t link</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal> */}
        {/* modal 3.1 for upload  */}
        <Modal
          open={linkUploadModal}
          onClose={linkUploadModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            <Typography className={classes.typemodaltitle} variant="h6" component="h2">
              Choose the security control to link the document
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-helper-label">Choose the document security control to link</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={linkName}
                onChange={handleLinkName}
                label="Choose the document security control to link"
              >
                {
                  uploadCredential.map((value, index) => {
                    return (
                      <MenuItem value={value.control_name} onClick={(e) => { getlinkUploadPayload(value) }}>{value.control_name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <Grid container className={classes.typeBtnContainer}>
              <Grid item>
                <Button variant="contained" className={classes.uploadBtn} onClick={
                  (e) => {
                    isContorlLinkedSelected ?
                      linkUploadAction() : toast.warning('please select file first');
                  }
                }>Upload</Button>

              </Grid>
              <Grid item>
                <Button variant="outlined" className={classes.resetBtn} onClick={() => { linkUploadModalClose(); setLinkName('') }}>Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* Modal 4 for replace document */}

        <Modal
          open={replaceModal}
          onClose={replaceModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            <Typography className={classes.typemodaltitle} variant="h6" component="h2">
              Choose the document name to replace
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Choose the document name to replace</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={replaceName}
                onChange={handleChange}
                label="Choose the document name to replace"
              >
                {
                  uploadCredential.map((value, index) => {
                    return (
                      <MenuItem value={value.name} onClick={(e) => getreplacePayload(value)}>{value.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
            <Grid container className={classes.typeBtnContainer}>
              <Grid item>
                <Button variant="contained" className={classes.uploadBtn}
                  onClick={(e) => {
                    isNewFileSelected ?
                      replaceAction() : toast.warning('please select file first');
                  }}
                >Replace</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" className={classes.resetBtn} onClick={cancelReplace}>Cancel</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* Modal 5 for upload to replace  document */}
        <Modal
          open={uploadModal}
          onClose={handleUploadModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalBox}>
            <Typography className={classes.typemodaltitle} variant="h6" component="h2">

              Choose the file to upload
            </Typography>
            <Typography className={classes.typemodalsubtitle} style={{ color: "#838383" }} variant="h6" component="h2">
              File name: {fileName}
            </Typography>

            <Grid container className={classes.fileUploadContainer}>
              <Grid item>
                <input type="file" onChange={onFileChange} id="raised-button-file" style={{ display: "none" }} />
                <label htmlFor="raised-button-file">
                  <Button variant="contained" className={classes.fileSelectBtn} component="span">
                    Choose file
                  </Button>
                </label>
              </Grid>
              <Grid item>
                <Button variant="outlined" className={classes.fileuploadbtn}
                  onClick={(e) => {
                    isNewFileUploaded ?
                      uploadFile() : toast.warning('please select file first');
                  }}
                >Upload</Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {/* // modal 6 for replace type upload  */}

        {/* </Grid> */}
        <Divider />

        {pageView === "box" ?
          domains ?
            <BoxView docCredential={docupdate} isDocToWork={isDocToWork}/>
            : null
          : domains ?
            <GridTable docCredential={docupdate} isDocToWork={isDocToWork}/>
            : null
        }
      </div>
    </ThemeProvider>
  );
};
export default PageDetail;
