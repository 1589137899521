import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getIsoControls } from "../../api/risk/isoControls";

export const isoControlsFetch = createAsyncThunk(
  "isoControls",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getIsoControls(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface isoControls {
  id: number;
  title: string;
  control_id: string;
  description: string;
}

export type initialStateIsoList = {
  isoControlsList: Array<isoControls>;
};

const initialState: initialStateIsoList = {
  isoControlsList: [],
};

const allIsoControls = createSlice({
  name: "isoControlsSlice",
  initialState,
  reducers: {
    resetIsoControls: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(isoControlsFetch.fulfilled, (state, { payload }) => {
      state.isoControlsList = [];
      payload.forEach((value: any) => {
        state?.isoControlsList?.push({
          id: value.id,
          title: value.title,
          control_id: value.control_id,
          description: value.description
        });
      });
    });
  },
});

export const { resetIsoControls } = allIsoControls.actions;

export default allIsoControls.reducer;
