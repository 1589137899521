import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStatement } from "../../api/statement/getStatement";

export const getStatementRequest = createAsyncThunk(
  "/rm/get/statement/",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getStatement(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateStatement = {
  get_statement: Array<Object>;
};

const initialState: initialStateStatement = {
  get_statement: [],
};

const getStatementData = createSlice({
  name: "statementData",
  initialState,
  reducers: {
    resetStatementData: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getStatementRequest.fulfilled, (state, { payload }) => {
        state.get_statement = payload;
    });
  },
});

export const { resetStatementData } = getStatementData.actions;

export default getStatementData.reducer;
