import React, { useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { width } from "@mui/system";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { RootState } from "../redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/store";
import { securityControlGraphData } from "../redux/slices/securityControlGraphData";
import { securityControlDashboard } from "../redux/slices/securityControlDashboard";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: "100%",
    // height: 100,
    borderRadius: 10,
    // boxShadow: "2px 3px 8px -1px rgba(0,0,0,0.2)",
    backgroundColor: "#FFFFF"
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    backgroundColor: "white",
    // paddingBottom:"81px !important",
  },
  heading: {
    fontSize: 25,
    fontWeight: 600,
  },
  securityControlCompTable: {
    // width: "582px",
    marginTop: "19px",
    height: "210px",
    background: "#F8F8F8",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    [`& th`]: {
      borderBottom: "1px solid #E8E8E8",
    },
    [`& td`]: {
      textAlign: "center",
      verticalAlign: "middle",
    }
  },
  securityControlCompDropdown: {
    display: "flex",
    marginTop: "17px",
    // width: "582px",
    justifyContent: "space-between",
  },
  dropSelectLeft: {
    // border: "1px solid #F8F8F8",
    marginRight: 13,
    backgroundColor: "#F8F8F8",
    [`& fieldset `]: {
      // border: "1px solid #F8F8F8",
      borderRadius: "8px",
    }
  },
  dropSelectRight: {
    // border: "1px solid #F8F8F8",
    backgroundColor: "#F8F8F8",
    [`& fieldset `]: {
      // border: "1px solid #F8F8F8",
      borderRadius: "8px",
    }
  },
  dropdownContainer: {
    // backgroundColor: "#F8F8F8",
  },
  graphTitle: {
    fontSize: "14px",
    color: "#1071BA",
    marginTop: "19px",
    marginLeft: "23px",
  },
  graph: {
    marginTop: "2%",
    borderRadius: 8,
    ".&recharts-legend-wrapper": {
      width: "0px !important",
    }
  },
  graphContainer: {
    backgroundColor: "#F8F8F8",
    marginTop: "19px",
    paddingRight: 28,
    paddingBottom: 12,
    borderRadius: 8,
  },
  selectedItem: {
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "rgba(16, 113, 186, 0.09) !important",
    },
    display: "flex !important",
    justifyContent: "space-between !important",
    padding: "7px 24px 7px 24px !important"
  },
  dropItemConatinerr: {
    display: "flex",
  }

}))

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const SecurtiyDashboardCard = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const access_token = localStorage.getItem("Access_Token");
  let securityControlDashboardData = useSelector((state: RootState) => state.securityControlDashboardData);
  let securityControlGraphDataa = useSelector((state: RootState) => state.securityControlGraphData.graphData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  let notImplementedLength = 0;
  let partiallyImpLength = 0;
  if (securityControlDashboardData.NI.length > 0) {
    securityControlDashboardData.NI.map(value=> notImplementedLength+=value.Count);
  }
  if (securityControlDashboardData.PI.length > 0) {
    securityControlDashboardData.PI.map(value=> partiallyImpLength+=value.Count);
  }
  useEffect(() => {
    dispatch(securityControlGraphData({ access_token }));
    dispatch(securityControlDashboard({ access_token }));
  }, [])

  return (
    <div className={classes.card}>
      <div className={classes.cardContentWrapper}>
        <Typography className={classes.heading}>Security control compliance</Typography>
        <table className={classes.securityControlCompTable}>
          <tr>
            <th style={{ textAlign: "left", paddingLeft: 30 }}>Controls</th>
            <th>Not implemented</th>
            <th>Partially Implemented</th>
          </tr>

          <tr>
            <td style={{ textAlign: "left", paddingLeft: 30 }}>Core</td>
            <td>{securityControlDashboardData.Core_NI}</td>
            <td>{securityControlDashboardData.Core_PI}</td>
          </tr>
          <tr>
            <td style={{ textAlign: "left", paddingLeft: 30 }}>Technical</td>
            <td>{securityControlDashboardData.Tech_NI}</td>
            <td>{securityControlDashboardData.Tech_PI}</td>
          </tr>
        </table>
        <div className={classes.securityControlCompDropdown}>
          {/* <FormControl fullWidth variant="outlined" className={classes.dropdownContainer}>
            <InputLabel style={{color:"black",fontFamily:"Poppins", fontSize: 14}}>{`Not implemented  (${notImplementedLength})`}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.dropSelectLeft}
              label={`Not implemented  (${notImplementedLength})`}
            >
             {securityControlDashboardData.NI.slice(0,5).map(value => {
               return(
                 <MenuItem className={classes.selectedItem} value={`${value.Domain}`}>
                   <span style={{fontFamily: "Poppins, sans-serif", fontSize: 14}}>{value.Domain}</span>
                   <span style={{fontFamily: "Poppins, sans-serif", fontSize: 14}}>{` (${value.Count})`}</span>
                </MenuItem>
               )
             })
             }  
              
            </Select>
          </FormControl> */}

          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            style={{ width: "100%", borderRadius: 8, marginRight: 15, background: "#F8F8F8", color: "black", textTransform: "none" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", width: "100%", height: 50 }}>
              <Typography style={{ fontSize: 14 }}>{`Not implemented  (${notImplementedLength})`}</Typography>
              <KeyboardArrowDownIcon />
            </div>
          </Button>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                // width: '17%',
              },
            }}
          >
            {securityControlDashboardData.NI.map(value => {
              return (
                <MenuItem className={classes.selectedItem} value={`${value.Domain}`}>
                  <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14 }}>{value.Domain}</span>
                  <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14, marginLeft: 30 }}>{value.Count}</span>
                </MenuItem>
              )
            })
            }
          </Menu>

          {/* <FormControl fullWidth className={classes.dropdownContainer}>
            <InputLabel id="demo-simple-select-label" style={{ color: "black", fontFamily: "Poppins", fontSize: 14 }}>{`Partially implemented (${partiallyImpLength})`}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.dropSelectRight}
              label={`Partially implemented (${partiallyImpLength})`}
            >
              {securityControlDashboardData.PI.slice(0, 5).map(value => {
                return (
                  <MenuItem className={classes.selectedItem} value={`${value.Domain}`}>
                    <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14 }}>{value.Domain}</span>
                    <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14 }}>{` (${value.Count})`}</span>
                  </MenuItem>
                )
              })
              }
            </Select>
          </FormControl> */}

          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open2 ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick2}
            style={{ width: "100%", borderRadius: 8, background: "#F8F8F8", color: "black", textTransform: "none" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", width: "100%", height: 50 }}>
              <Typography style={{ fontSize: 14 }}>{`Partially implemented (${partiallyImpLength})`}</Typography>
              <KeyboardArrowDownIcon />
            </div>
          </Button>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose2}
            PaperProps={{
              style: {
                maxHeight: 200,
                // width: '17%',
              },
            }}
          >
            {securityControlDashboardData.PI.map(value => {
                return (
                  <MenuItem className={classes.selectedItem} value={`${value.Domain}`}>
                    <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14 }}>{value.Domain}</span>
                    <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14, marginLeft: 30 }}>{value.Count}</span>
                  </MenuItem>
                )
              })
              }
          </Menu>

        </div>
        <Typography className={classes.graphTitle}>Control compliance progress</Typography>
        <ResponsiveContainer width="95%" height={270} className={classes.graphContainer}>
          <LineChart height={250} data={securityControlGraphDataa}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }} className={classes.graph}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="days">
              <Label value="Days" offset={0} position="bottom" textAnchor="end" />
            </XAxis>
            <YAxis label={{ value: 'Controls', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Legend content={renderLegend} />
            {/* <Legend
         payload={
         data.map(
         item => ({
         id: item,
         type: "square",
         value: '${item.name} (${item.value}%)',
      })
    )
  }
/> */}
            <Line type="linear" dataKey="Technical" stroke="#1071BA" dot={false} />
            <Line type="linear" dataKey="Core" stroke="#F15642" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>

    </div>
  );
}

const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end",paddingTop: "20px"}}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
        <div style={{ height: 5, width: 5, background: "#1071BA", marginRight: 5 }}></div>
        <span style={{ color: "#1071BA" }}>Technical</span>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
        <div style={{ height: 5, width: 5, background: "#F15642", marginRight: 5 }}></div>
        <span style={{ color: "#F15642" }}>Core</span>
      </div>
    </div>
  );
}



export default SecurtiyDashboardCard;