import { server_url } from "../../types/constants";
import axios from "axios";

export async function getAllPlans() {
  const url = `${server_url}/authentication/plan/`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
