import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReport } from "../../api/report/getReport";

export const getReportThunk = createAsyncThunk(
  "report/",
  async ({ uuid }: { uuid: string }, thunkApi) => {
    try {
      const { user }: any = thunkApi.getState();
      return await getReport(user.access_token, uuid);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export interface Recommendation {
  recommendation: string;
  documents?: string[];
  documents_list?: string[];
}

export interface ReportType {
  question_id: string;
  icoControl: string;
  domain: string;
  category: string;
  response: string;
  response_explanation: string;
  recommendations: Recommendation[];
}

export type initialStateReport = {
  reports: ReportType[];
  category_name: string;
};
const initialState: initialStateReport = {
  reports: [],
  category_name: "",
};
const report = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {
    resetReport: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReportThunk.fulfilled, (state, { payload }) => {
      state.category_name = payload.survey_category.category_name;
      // for (let i = 0; i < payload.reports.length; i++) {
      //   state.reports.push({
      //     question_id: payload.reports[i].question_id,
      //     icoControl: payload.reports[i].isoControl,
      //     domain: payload.reports[i].domain,
      //     category: payload.reports[i].category,
      //     response: payload.reports[i].response,
      //     response_explanation: payload.reports[i].response_explanation,
      //     recommendations: [...payload.reports[i].recommendations],
      //   });
      // }
      payload?.reports.forEach((value: any) => {
        state.reports.push({
          question_id: value.question_id,
          icoControl: value.isoControl,
          domain: value.domain,
          category: value.category,
          response: value.response,
          response_explanation: value.response_explanation,
          recommendations: [...value.recommendations],
        });
      });
    });
  },
});
export const { resetReport } = report.actions;
export default report.reducer;
