import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import {
  changeStatusSurveyThunk,
  loadSurveyQuestions,
  resetSurvey,
  setActiveDomain,
  setSidebarDomains,
  Survey_Question,
} from "../../redux/slices/surveySlice";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { unwrapResult } from "@reduxjs/toolkit";
import Sidebar from "./Sidebar/Sidebar";
import Questionnaire from "./Questionnaire/Questionnaire";
import Appbar from "./Appbar/Appbar";
import Loading from "../../layout/Loading";
import { ParamTypes } from "../../types/survey";
import { REFRESH_TOKEN } from "../../types/constants";
import { loadUser } from "../../redux/slices/userSlice";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
  })
);

function findActiveDomain(
  surveyQuestions: Survey_Question[],
  stepsArray: string[]
) {
  const survey_question = surveyQuestions.find((surveyQuestion) => {
    if (
      surveyQuestion.response === "NR" &&
      surveyQuestion.question.parent_question.length === 0
    ) {
      return surveyQuestion.question.domain;
    }
  });
  if (survey_question) {
    return stepsArray.indexOf(survey_question.question.domain);
  }
  return 0;
}

export default function Assessment() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { uuid } = useParams<ParamTypes>();
  const survey = useSelector((state: RootState) => state.survey.survey);
  const surveyQuestions = useSelector(
    (state: RootState) => state.survey.survey_questions
  );
  const category_name = useSelector(
    (state: RootState) => state.survey.category_name
  );
  const activeDomain = useSelector(
    (state: RootState) => state.survey.activeDomain
  );
  const sidebarDomains = useSelector(
    (state: RootState) => state.survey.sidebarDomains
  );
  const tempInitialValuesFormik: any = {};

  if (survey && survey.survey_status === "OD") {
    history.push(`/feedback/${survey.uuid}/`);
  }
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);

  useEffect(() => {
    dispatch(resetSurvey());
    dispatch(loadSurveyQuestions({ uuid }));
  }, []);

  useEffect(() => {
    const domainAndQuestionCount = new Map<string, number>();
    surveyQuestions.forEach((surveyQuestion: Survey_Question) => {
      if (domainAndQuestionCount.get(surveyQuestion.question.domain)) {
        if (domainAndQuestionCount.get(surveyQuestion.question.domain)) {
          const numberOfQuestions =
            (domainAndQuestionCount.get(surveyQuestion.question.domain) || 1) +
            1;
          domainAndQuestionCount.set(
            surveyQuestion.question.domain,
            numberOfQuestions
          );
        }
      } else {
        domainAndQuestionCount.set(surveyQuestion.question.domain, 1);
      }
    });
    const stepsArray: string[] = [];
    for (const [key] of domainAndQuestionCount) {
      if (stepsArray.length > 0) {
        if (stepsArray[stepsArray.length - 1] !== key) {
          stepsArray.push(key);
        }
      } else {
        stepsArray.push(key);
      }
    }
    const first_domain = findActiveDomain(surveyQuestions, stepsArray);
    dispatch(setSidebarDomains(stepsArray));
    if (activeDomain?.length > 1) {
    } else {
      dispatch(setActiveDomain(stepsArray[first_domain]));
    }
    // if(activeDomain?.length <= 1){
    //   dispatch(setActiveDomain(stepsArray[first_domain]));
    // }
  }, [surveyQuestions]);
  const domainSurveyQuestions = surveyQuestions.filter(
    (surveyQuestion: Survey_Question) => {
      if (surveyQuestion.question.domain === activeDomain) {
        tempInitialValuesFormik[
          `${surveyQuestion.response}-${surveyQuestion.question.id}-${survey.id}`
        ] = surveyQuestion.response_explanation;
        return surveyQuestion;
      }
    }
  );

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const checkAllResponded = (checkQuestions: Survey_Question[]) => {
    for (let i = 0; i < checkQuestions.length; i++) {
      if (
        checkQuestions[i].response === "NR" &&
        checkQuestions[i].question.parent_question.length < 1
      ) {
        enqueueSnackbar("Please complete all question", {
          variant: "warning",
          key: checkQuestions[i].question.question_id,
        });
        return false;
      } else if (
        checkQuestions[i].response === "NR" &&
        checkQuestions[i].question.parent_question.length > 0
      ) {
        if (
          checkQuestions[i].question.parent_question.filter(
            ({ question_id }: any) => {
              const parentQuestionIndex = surveyQuestions.findIndex(
                (survey_question: Survey_Question) =>
                  survey_question.question.question_id === question_id
              );
              if (
                parentQuestionIndex > -1 &&
                surveyQuestions[parentQuestionIndex].response === "FI"
              ) {
                return question_id;
              }
            }
          ).length === checkQuestions[i].question.parent_question.length
        ) {
          enqueueSnackbar("Please complete all question", {
            variant: "warning",
            key: checkQuestions[i].question.question_id,
          });
          return false;
        }
      }
    }
    return true;
  };
  const checkMessageResponse = (
    checkCurrentDomainQuestions: Survey_Question[],
    values: any
  ) => {
    for (let i = 0; i < checkCurrentDomainQuestions.length; i++) {
      if (
        checkCurrentDomainQuestions[i].question.parent_question.length === 0 &&
        (checkCurrentDomainQuestions[i].response === "PI" ||
          checkCurrentDomainQuestions[i].response === "NI") &&
        values[
          `${checkCurrentDomainQuestions[i].response}-${checkCurrentDomainQuestions[i].question.id}-${survey.id}`
        ].length < 1
      ) {
        enqueueSnackbar("Please provide an answer or add missing comment", {
          variant: "warning",
        });
        return false;
      } else if (
        checkCurrentDomainQuestions[i].question.parent_question.length > 0 &&
        (checkCurrentDomainQuestions[i].response === "PI" ||
          checkCurrentDomainQuestions[i].response === "NI") &&
        values[
          `${checkCurrentDomainQuestions[i].response}-${checkCurrentDomainQuestions[i].question.id}-${survey.id}`
        ].length < 1
      ) {
        if (
          checkCurrentDomainQuestions[i].question.parent_question.filter(
            ({ question_id }: any) => {
              const parentQuestionIndex = surveyQuestions.findIndex(
                (survey_question: Survey_Question) =>
                  survey_question.question.question_id === question_id
              );
              if (
                parentQuestionIndex > -1 &&
                surveyQuestions[parentQuestionIndex].response === "FI"
              ) {
                return question_id;
              }
            }
          ).length ===
          checkCurrentDomainQuestions[i].question.parent_question.length
        ) {
          enqueueSnackbar("Please provide an answer or add missing comment", {
            variant: "warning",
          });
          return false;
        }
      }
    }
    return true;
  };
  if (!domainSurveyQuestions || domainSurveyQuestions?.length < 1) {
    return <Loading />;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...tempInitialValuesFormik,
          submitType: "finish",
        }}
        onSubmit={(values: any) => {
          {
            //Check first for does response is Not responded and not parent question
            // else if parent question length > 0 filter whether question is answered
          }
          let submitQuestions = surveyQuestions;
          if (
            values.submitType === "next" ||
            values.submitType === "previous"
          ) {
            submitQuestions = domainSurveyQuestions;
          }
          if (!checkAllResponded(submitQuestions)) {
            return;
          }
          if (!checkMessageResponse(domainSurveyQuestions, values)) {
            return;
          }
          if (values.submitType === "submit") {
            dispatch(changeStatusSurveyThunk({ uuid, survey_status: "OD" }))
              .then(unwrapResult)
              .then(() => {
                if (category_name === "Core Assessment") {
                  if (refresh_token && refresh_token.length > 1) {
                    dispatch(loadUser({ refresh_token }))
                      .then(unwrapResult)
                      .then(() => {
                        history.push(`/`);
                      });
                  }
                } else {
                  history.push(`/feedback/${uuid}/`);
                }
              });
          } else if (values.submitType === "next") {
            const activeDomainIndex = sidebarDomains.findIndex(
              (domain) => domain === activeDomain
            );
            dispatch(
              setActiveDomain(
                sidebarDomains[(activeDomainIndex + 1) % sidebarDomains.length]
              )
            );
          } 
          else if (values.submitType === "previous") {
          }
        }}
      >
        {(formik: any) => (
          <>
            <Appbar handleDrawerToggle={handleDrawerToggle} />
            <Sidebar
              CurrentPosition={{
                sidebarDomains: sidebarDomains,
                activeDomain: activeDomain,
              }}
              domainSurveyQuestion={domainSurveyQuestions}
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
              checkAllResponded={checkAllResponded}
              checkMessageResponse={checkMessageResponse}
            />
            <Questionnaire
              CurrentPosition={{
                sidebarDomains: sidebarDomains,
                activeDomain: activeDomain,
              }}
              domainSurveyQuestion={domainSurveyQuestions}
              surveyQuestions={surveyQuestions}
              formik={formik}
            />
          </>
        )}
      </Formik>
    </div>
  );
}
