import { server_url } from "../../types/constants";
import axios from "axios";

export async function getUploadDocData(
  access_token: string,
  type: string
) {
  const url = `${server_url}/rm/select_doc/?type=${type}`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
    console.log(response.data,'ssssssssssssssss');
  } catch (err:any) {
    throw err.response.data;
  }
}