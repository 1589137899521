import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRisk } from "../../api/risk/risk";

export const riskListFetch = createAsyncThunk(
  "risk/list",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getRisk(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface risklist {
  id: number;
  risk_status: string;
  risk_group: string;
  name: string;
  description: string;
  vulnerability: string;
  likelihood: string;
  likelihood_justification: string;
  impact: string;
  impact_justification: string;
  risk_rating: string;
  existing_controls: string;
  risk_response: string;
  residual_risk_rating: string;
  related_project: string;
  risk_owner: string;
  risk_owner_name: string;
  risk_review_date: string;
  comments: string;
  risk_closure_justification: string;
  risk_uuid: string;
  iso_control: Array<[]>;
}

export type initialStateRiskList = {
  allRiskList: Array<risklist>;
  is_notification: boolean;
};

const initialState: initialStateRiskList = {
  allRiskList: [],
  is_notification: true,
};

const allRiskListGet = createSlice({
  name: "riskListSlice",
  initialState,
  reducers: {
    resetRiskList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskListFetch.fulfilled, (state, { payload }) => {
      state.allRiskList = [];
      payload?.data?.forEach((value: any) => {
        state?.allRiskList?.push({
          id: value.id,
          risk_status: value.risk_status,
          risk_group: value.risk_group,
          name: value.name || value.risk_name,
          description: value.description,
          vulnerability: value.vulnerability,
          likelihood: value.likelihood,
          likelihood_justification: value.likelihood_justification,
          impact: value.impact,
          impact_justification: value.impact_justification,
          risk_rating: value.risk_rating,
          existing_controls: value.existing_controls,
          risk_response: value.risk_response,
          residual_risk_rating: value.residual_risk_rating,
          related_project: value.related_project,
          risk_owner: value.risk_owner,
          risk_owner_name: value.risk_owner_name,
          risk_review_date: value.risk_review_date,
          comments: value.comments,
          risk_uuid: value.risk_uuid,
          risk_closure_justification: value.risk_closure_justification,
          iso_control: value.iso_control
        });
      });
      state.is_notification = payload.is_notification;
    });
  },
});

export const { resetRiskList } = allRiskListGet.actions;

export default allRiskListGet.reducer;
