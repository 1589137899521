import React, { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { useAppDispatch } from "../../redux/store";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { riskListFetch } from "../../redux/slices/riskSlice";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { riskGroupFetch } from "../../redux/slices/riskGroupSlice";
import AddRiskGroup from "../RiskManagement/addRiskGroup";
import AddDialog from "../UsersProfile/AddDialog";
import { newControlAdd } from "../../redux/slices/addControlSlice";
import { useSnackbar } from "notistack";
// import { projectListFetch } from "../../redux/slices/projectListSlice";
import Confirmation from "../RiskManagement/confirmationModal";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 1000,
    paddingLeft: 150,
    paddingRight: 150,
    paddingTop: 90,
    paddingBottom: 100,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  riskGroupDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 13,
  },
  textFieldTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2C2C2C",
  },
  textFieldSmall: {
    flex: 1,
    maxWidth: 250,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  dropdownDiv: {
    // maxWidth: 373,
    // flex: 1,
  },
  dropdown: {
    width: 250,
  },
  dropdownSelect: {
    borderRadius: 8,
  },
  addCtrlGrp: {
    color: "#1071BA",
    textTransform: "none",
  },
  dropdownFull: {
    width: 385,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldLarge: {
    flex: 1,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldDate: {
    flex: 1,
    maxWidth: 250,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  btnDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 55,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddControl = ({ addControlState, setAddControlState }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const access_token = localStorage.getItem("Access_Token");

  const [editState, setEditState] = useState(false);
  const [addGroupState, setAddGroupState] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [controlDetails, setControlDetails] = useState({
    risk: "",
    risk_rating: "",
    risk_response: "",
    control_group_name: "",
    control_name: "",
    mitigation: "",
    status: "",
    due_date: new Date() as any,
    control_owner: "",
    closed_date: new Date() as any,
    comments: "",
    justification_to_close: "",
  });

  const [errorState, setErrorState] = useState({
    risk: false,
    // risk_rating: false,
    // risk_response: false,
    control_group_name: false,
    control_name: false,
    mitigation: false,
    status: false,
    due_date: false,
    control_owner: false,
    closed_date: false,
    // comments: false,
    justification_to_close: false,
  });

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );

  const riskList = useSelector(
    (state: RootState) => state.riskList.allRiskList
  );

  const projectList = useSelector(
    (state: RootState) => state.projectList.allprojectlist
  );

  const groupList = useSelector(
    (state: RootState) => state.riskGroup.allRiskGroups
  );

  const updateUserList = useSelector(
    (state: RootState) => state.addNewUser.email
  );

  const updateRiskGroup = useSelector(
    (state: RootState) => state.addRiskGroup.id
  );

  const logs = useSelector((state: RootState) => state);
  // console.log(logs, "gggggggg");

  useEffect(() => {
    if (addControlState) {
      dispatch(riskListFetch({ access_token }));
      dispatch(usersListFetch({ access_token }));
      dispatch(riskGroupFetch({ access_token }));
    }
  }, [addControlState]);

  useEffect(() => {
    if (addControlState) {
      dispatch(usersListFetch({ access_token }));
    }
  }, [addControlState, updateUserList]);

  useEffect(() => {
    if (addControlState) {
      dispatch(riskGroupFetch({ access_token }));
    }
  }, [addControlState, updateRiskGroup]);

  const handleCloseModal = () => {
    setAddControlState(false);
  };

  const handleChangeRiskName = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const riskData = riskList.filter((value) => value.id == event.target.value);
    if (riskData) {
      setControlDetails({
        ...controlDetails,
        risk_rating: riskData[0].risk_rating,
        risk_response: riskData[0].risk_response,
        risk: event.target.value as string,
      });
    }
  };

  const handleChangeControlGroupName = (event: any) => {
    if (event.target.value === "Add New Group") {
      setControlDetails({
        ...controlDetails,
        control_group_name: event.target.value as string,
      });
      setAddGroupState(true);
    } else {
      setControlDetails({
        ...controlDetails,
        control_group_name: event.target.value as string,
      });
    }
  };

  const handleSelectControlGroupName = (event: any) => {
    if (event?.target?.innerText === "Add New Group") {
      setAddGroupState(true);
    }
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setControlDetails({
      ...controlDetails,
      status: event.target.value as string,
    });
  };

  const handleChangeMitigation = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setControlDetails({
      ...controlDetails,
      mitigation: event.target.value as string,
    });
  };

  const handleChangeControlOwner = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (event.target.value === "Add New Control Owner") {
      setControlDetails({
        ...controlDetails,
        control_owner: event.target.value as string,
      });
      setEditState(true);
    } else {
      setControlDetails({
        ...controlDetails,
        control_owner: event.target.value as string,
      });
    }
  };

  const handleSelectAddControlOwner = (event: any) => {
    if (event?.target?.innerText === "Add New Control Owner") {
      setEditState(true);
    }
  };

  const handleCancel = () => {
    handleCloseModal();
    setControlDetails({
      risk: "",
      risk_rating: "",
      risk_response: "",
      control_group_name: "",
      control_name: "",
      mitigation: "",
      status: "",
      due_date: new Date() as any,
      control_owner: "",
      closed_date: new Date() as any,
      comments: "",
      justification_to_close: "",
    });
    setErrorState({
      risk: false,
      // risk_rating: false,
      // risk_response: false,
      control_group_name: false,
      control_name: false,
      mitigation: false,
      status: false,
      due_date: false,
      control_owner: false,
      closed_date: false,
      // comments: false,
      justification_to_close: false,
    });
  };

  const handleValidate = () => {
    const errors: any = {};
    let validated = true;
    if (!controlDetails.risk) {
      errors["risk"] = true;
      validated = false;
    }
    if (
      !controlDetails.control_group_name ||
      controlDetails.control_group_name === "Add New Group"
    ) {
      errors["control_group_name"] = true;
      validated = false;
    }
    if (!controlDetails.control_name) {
      errors["control_name"] = true;
      validated = false;
    }
    if (!controlDetails.mitigation) {
      errors["mitigation"] = true;
      validated = false;
    }
    if (!controlDetails.status) {
      errors["status"] = true;
      validated = false;
    }
    if (!controlDetails.due_date) {
      errors["due_date"] = true;
      validated = false;
    }
    if (
      !controlDetails.control_owner ||
      controlDetails.control_owner === "Add New Control Owner"
    ) {
      errors["control_owner"] = true;
      validated = false;
    }
    if (controlDetails.status === "Closed" && !controlDetails.closed_date) {
      errors["closed_date"] = true;
      validated = false;
    }
    if (
      controlDetails.status === "Closed" &&
      !controlDetails.justification_to_close
    ) {
      errors["justification_to_close"] = true;
      validated = false;
    }
    if (validated === true) {
      handleSubmit();
      setErrorState({
        risk: false,
        // risk_rating: false,
        // risk_response: false,
        control_group_name: false,
        control_name: false,
        mitigation: false,
        status: false,
        due_date: false,
        control_owner: false,
        closed_date: false,
        // comments: false,
        justification_to_close: false,
      });
    }
    setErrorState({ ...errors });
  };

  const handleSubmit = () => {
    const payload = {
      access_token: access_token,
      risk: controlDetails.risk,
      control_group_name: controlDetails.control_group_name,
      control_name: controlDetails.control_name,
      mitigation: controlDetails.mitigation,
      Status: controlDetails.status,
      due_date: moment(controlDetails.due_date).format("YYYY-MM-DD"),
      control_owner: controlDetails.control_owner,
      closed_date: moment(controlDetails.closed_date).format("YYYY-MM-DD"),
      comments: controlDetails.comments,
      justification: controlDetails.justification_to_close,
    };
    if (payload) {
      dispatch(newControlAdd(payload))
        .then(unwrapResult)
        .then((response: any) => {
          // console.log(response,"nnnnn")
          handleCloseModal();
          setControlDetails({
            risk: "",
            risk_rating: "",
            risk_response: "",
            control_group_name: "",
            control_name: "",
            mitigation: "",
            status: "",
            due_date: new Date() as any,
            control_owner: "",
            closed_date: new Date() as any,
            comments: "",
            justification_to_close: "",
          });
        })
        .catch((errorResponse) => {
          enqueueSnackbar("Error occured, Could not save control", {
            autoHideDuration: 2000,
            key: 1,
            variant: "error",
          });
        });
    }
  };

  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (
      controlDetails.risk ||
      controlDetails.risk_rating ||
      controlDetails.risk_response ||
      controlDetails.control_group_name ||
      controlDetails.control_name ||
      controlDetails.mitigation ||
      controlDetails.status ||
      controlDetails.control_owner ||
      controlDetails.comments
    ) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCloseModal();
    }
    return isConfirm;
  };

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog
          onClose={handleCheckChanges}
          aria-labelledby="customized-dialog-title"
          open={addControlState}
          // disableBackdropClick={true}
          // disableEscapeKeyDown={true}
          onBackdropClick={() => handleCheckChanges()}
          onEscapeKeyDown={() => handleCheckChanges()}
          maxWidth={"xl"}
        >
          <DialogContent>
            <div className={classes.modalContainer}>
              <div className={classes.titleCenter}>
                <Typography className={classes.profileTitle}>
                  Add new control
                </Typography>
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Risk name
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel>Risk name</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          required
                          error={errorState.risk}
                          value={controlDetails.risk}
                          onChange={handleChangeRiskName}
                          className={classes.dropdownSelect}
                          label="Risk name"
                        >
                          {riskList.map((value) => (
                            <MenuItem value={`${value.id}`}>
                              {value.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {errorState.risk && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk response
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      label="Risk response"
                      variant="outlined"
                      inputProps={{
                        readOnly: true,
                      }}
                      value={controlDetails.risk_response}
                      // error={errorState.name}
                      // helperText={
                      //   errorState.name ? "Please fill this field" : null
                      // }
                      // onChange={(e) =>
                      //   setRiskDetails({
                      //     ...riskDetails,
                      //     name: e.target.value,
                      //   })
                      // }
                    />
                  </div>

                  <div style={{ marginTop: 37, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Control group name
                      </Typography>
                      {/* <Button
                        className={classes.addCtrlGrp}
                        onClick={() => setAddGroupState(true)}
                      >
                        Add +
                      </Button> */}
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <FormControl
                      variant="outlined"
                      className={classes.dropdown}
                    >
                      <InputLabel>List of group names</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                        error={errorState.control_group_name}
                        onChange={handleChangeControlGroupName}
                        onClick={handleSelectControlGroupName}
                        className={classes.dropdownFull}
                        label="List of group names"
                      >
                        <MenuItem value={`Add New Group`}>
                          Add New Group
                        </MenuItem>
                        {groupList.map((value) => (
                          <MenuItem value={value.name}>{value.name}</MenuItem>
                        ))}
                      </Select>
                      {errorState.control_group_name && (
                        <FormHelperText style={{ color: "red" }}>
                          Please select this field
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div style={{ marginTop: 40 }}>
                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Mitigation
                        </Typography>
                      </div>
                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdown}
                        >
                          <InputLabel>Mitigation</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            error={errorState.mitigation}
                            onChange={handleChangeMitigation}
                            className={classes.dropdownSelect}
                            label="Mitigation"
                          >
                            <MenuItem value={"Likelihood"}>Likelihood</MenuItem>
                            <MenuItem value={"Impact"}>Impact</MenuItem>
                            <MenuItem value={"Likelihood and Impact"}>
                              Likelihood and Impact
                            </MenuItem>
                          </Select>
                          {errorState.mitigation && (
                            <FormHelperText style={{ color: "red" }}>
                              Please select this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>

                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Due date
                      </Typography>
                      <KeyboardDatePicker
                        className={classes.textFieldDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        value={controlDetails.due_date}
                        error={errorState.due_date}
                        helperText={
                          errorState.due_date
                            ? "Please select this field"
                            : null
                        }
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) =>
                          setControlDetails({
                            ...controlDetails,
                            due_date: date,
                          })
                        }
                      />
                    </div>

                    {controlDetails.status === "Closed" && (
                      <div className={classes.riskGroupDiv}>
                        <Typography className={classes.textFieldTitle}>
                          Closed date
                        </Typography>
                        <KeyboardDatePicker
                          className={classes.textFieldDate}
                          autoOk
                          variant="inline"
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          value={controlDetails.closed_date}
                          error={errorState.closed_date}
                          helperText={
                            errorState.closed_date
                              ? "Please select this field"
                              : null
                          }
                          InputAdornmentProps={{ position: "start" }}
                          onChange={(date) =>
                            setControlDetails({
                              ...controlDetails,
                              closed_date: date,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>

                  {controlDetails.status === "Closed" && (
                    <div style={{ marginTop: 48 }}>
                      <div className={classes.riskGroupDiv}>
                        <Typography className={classes.textFieldTitle}>
                          Justification to close
                        </Typography>
                      </div>
                      <div className={classes.riskGroupDiv}>
                        <TextField
                          className={classes.textFieldLarge}
                          id="outlined-required"
                          // label="Comments"
                          variant="outlined"
                          multiline
                          rows={6}
                          error={errorState.justification_to_close}
                          helperText={
                            errorState.justification_to_close
                              ? "Please fill this field"
                              : null
                          }
                          onChange={(e) =>
                            setControlDetails({
                              ...controlDetails,
                              justification_to_close: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>

                {/* //2nd row// */}

                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk rating
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      label="Risk rating"
                      variant="outlined"
                      inputProps={{
                        readOnly: true,
                      }}
                      value={controlDetails.risk_rating}
                      // error={errorState.name}
                      // helperText={
                      //   errorState.name ? "Please fill this field" : null
                      // }
                      // onChange={(e) =>
                      //   setRiskDetails({
                      //     ...riskDetails,
                      //     name: e.target.value,
                      //   })
                      // }
                    />
                  </div>

                  <div style={{ marginTop: 115, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Control name
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label="Enter control name"
                      variant="outlined"
                      error={errorState.control_name}
                      helperText={
                        errorState.control_name
                          ? "Please fill this field"
                          : null
                      }
                      onChange={(e) =>
                        setControlDetails({
                          ...controlDetails,
                          control_name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 40 }}>
                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Status
                        </Typography>
                      </div>
                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdown}
                        >
                          <InputLabel>Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            error={errorState.status}
                            onChange={handleChangeStatus}
                            className={classes.dropdownSelect}
                            label="Status"
                          >
                            <MenuItem value={"Closed"}>Closed</MenuItem>
                            <MenuItem value={"Approved"}>Approved</MenuItem>
                            <MenuItem value={"Inprogress"}>In-progress</MenuItem>
                            <MenuItem value={"Implemented"}>
                              Implemented
                            </MenuItem>
                            {/* <MenuItem value={"Closed"}>Closed</MenuItem> */}
                          </Select>
                          {errorState.status && (
                            <FormHelperText style={{ color: "red" }}>
                              Please select this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>

                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Control owner
                        </Typography>
                      </div>
                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdown}
                        >
                          <InputLabel>Control owner</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            error={errorState.control_owner}
                            onChange={handleChangeControlOwner}
                            onClick={handleSelectAddControlOwner}
                            className={classes.dropdownSelect}
                            label="Control owner"
                          >
                            <MenuItem value="Add New Control Owner">
                              Add New Control Owner
                            </MenuItem>
                            {usersList
                              ?.filter(
                                (val) => val.is_active === true
                                // && val.role === "Administrator" ||
                                // val.role === "Risk owner" ||
                                // val.role === "Risk manager"
                              )
                              ?.map((user, key) => (
                                <MenuItem value={`${user.id}`}>
                                  {user.email}
                                </MenuItem>
                              ))}
                          </Select>
                          {errorState.control_owner && (
                            <FormHelperText style={{ color: "red" }}>
                              Please fill this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 108 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Comments
                      </Typography>
                    </div>
                    <div className={classes.riskGroupDiv}>
                      <TextField
                        className={classes.textFieldLarge}
                        id="outlined-required"
                        // label="Comments"
                        variant="outlined"
                        multiline
                        rows={6}
                        onChange={(e) =>
                          setControlDetails({
                            ...controlDetails,
                            comments: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.btnDiv}>
                <Button className={classes.saveBtn} onClick={handleValidate}>
                  SAVE
                </Button>
                <Button className={classes.cancelBtn} onClick={handleCancel}>
                  CANCEL
                </Button>
              </div>
              <AddDialog
                setEditState={setEditState}
                editState={editState}
                addNewRiskOwner={true}
              />
              <AddRiskGroup
                addGroupState={addGroupState}
                setAddGroupState={setAddGroupState}
              />
              <Confirmation
                confirmation={confirmation}
                setConfirmation={setConfirmation}
                handleCancel={handleCancel}
              />
            </div>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default AddControl;
