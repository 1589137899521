import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { newPassword } from "../../api/forgotPassword/newPassword";

export const newPasswordSubmit = createAsyncThunk(
  "forgotPassword/newPassword",
  async ({ email, password }: any, thunkAPI) => {
    try {
      return await newPassword(email, password);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUserPassword = {
  status: string;
  email: string;
};

const initialState: initialStateUserPassword = {
  status: "",
  email: "",
};

const userSubmitPassword = createSlice({
  name: "newPassword",
  initialState,
  reducers: {
    resetNewPassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newPasswordSubmit.fulfilled, (state, { payload }) => {
      state.status = payload.status;
      state.email = payload.email;
    });
  },
});

export const { resetNewPassword } = userSubmitPassword.actions;

export default userSubmitPassword.reducer;
