import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signInApi } from "../../api/auth/signInApi";
import { AUTH_TOKEN, REFRESH_TOKEN } from "../../types/constants";
import { signUpApi } from "../../api/auth/signUpApi";
import { loadUserApi } from "../../api/auth/loadUserApi";
import { verifyOneTimeRegistrationTokenApi } from "../../api/auth/verifyOneTimeRegistrationTokenApi";

export const signInUser = createAsyncThunk(
  "users/signInUser",
  async ({ email, password }: any, thunkAPI) => {
    try {
      return await signInApi(email, password);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "users/signUpUser",
  async (
    {
      email,
      password,
      first_name,
      last_name,
      organization,
      title,
      role,
      client_id,
      isAllowedPromotion,
      registration_token,
      isTemporary,
    }: {
      email: string;
      password: string;
      first_name: string;
      last_name: string;
      organization: string;
      title: string;
      role: string;
      client_id: number;
      isAllowedPromotion: boolean;
      isTemporary: boolean;
      registration_token?: string;
    },
    thunkAPI
  ) => {
    try {
      return await signUpApi(
        email,
        password,
        first_name,
        last_name,
        organization,
        title,
        role,
        client_id,
        isAllowedPromotion,
        isTemporary,
        registration_token
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadUser = createAsyncThunk(
  "users/loadUser",
  async ({ refresh_token }: { refresh_token: string }, thunkAPI) => {
    try {
      return await loadUserApi(refresh_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const verifyOneTimeRegistrationToken = createAsyncThunk(
  "users/oneTimeLinkVerify",
  async ({ registration_token }: { registration_token: string }, thunkAPI) => {
    try {
      return await verifyOneTimeRegistrationTokenApi(registration_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  title: string;
  organization: string;
  isPremium: boolean;
  isNew: boolean;
  isAuthenticated: boolean;
  isFinishedFirstCore: boolean;
  last_core_survey_uuid?: string;
  last_core_survey_status?: string;
  last_filter_survey_uuid?: string;
  last_filter_survey_status?: string;
  last_technical_survey_uuid?: string;
  last_technical_survey_status?: string;
  access_token: string;
  refresh_token: string;
  role: string;
  is_active: boolean;
  client: number;
  risk_matrix: boolean;
  planType: string;
}

interface RequestPending {
  isPending: boolean;
  isAllowedRegister: boolean;
}

type initialStateUser = RequestPending & { error: string } & User;

const initialState: initialStateUser = {
  isAllowedRegister: false,
  isPending: false,
  error: "",
  id: -1,
  username: "",
  last_name: "",
  first_name: "",
  email: "",
  title: "",
  organization: "",
  isNew: true,
  isPremium: false,
  isAuthenticated: false,
  isFinishedFirstCore: false,
  last_core_survey_uuid: "",
  last_core_survey_status: "",
  last_filter_survey_uuid: "",
  last_filter_survey_status: "",
  last_technical_survey_uuid: "",
  last_technical_survey_status: "",
  access_token: "",
  refresh_token: "",
  role: "",
  is_active: false,
  client: -1,
  risk_matrix: true,
  planType:"",
};

const user = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    resetUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(signInUser.fulfilled, (state, { payload }) => {
      assignStateWithUser(state, payload);
      state.isAuthenticated = true;
      state.isPending = false;
    });
    builder.addCase(signUpUser.fulfilled, (state, { payload }) => {
      assignStateWithUser(state, payload);
      state.isAuthenticated = true;
      state.isPending = false;
    });

    builder.addCase(loadUser.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(loadUser.fulfilled, (state, { payload }) => {
      assignStateWithUser(state, payload);
      state.isPending = false;
      state.isAuthenticated = true;
    });
    builder.addCase(loadUser.rejected, (state) => {
      state.isPending = false;
      state.isAuthenticated = false;
    });

    builder.addCase(verifyOneTimeRegistrationToken.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(verifyOneTimeRegistrationToken.fulfilled, (state) => {
      state.isPending = false;
      state.isAllowedRegister = true;
    });
    builder.addCase(verifyOneTimeRegistrationToken.rejected, (state) => {
      state.isPending = false;
      state.isAuthenticated = false;
      state.isAllowedRegister = false;
    });
  },
});

export const { resetUser } = user.actions;

export default user.reducer;

const assignStateWithUser = (state: initialStateUser, payload: User) => {
  state.email = payload.username;
  state.username = payload.username;
  state.first_name = payload.first_name;
  state.last_name = payload.last_name;
  state.title = payload.title;
  state.organization = payload.organization;
  state.isNew = payload.isNew;
  state.isPremium = payload.isPremium;
  state.id = payload.id;
  state.error = "";
  state.access_token = payload.access_token;
  state.isFinishedFirstCore = payload.isFinishedFirstCore;
  state.is_active = payload.is_active;
  state.client = payload.client;
  state.risk_matrix = payload.risk_matrix;
  if (
    payload.last_core_survey_uuid &&
    payload.last_core_survey_uuid.length > 1
  ) {
    state.last_core_survey_uuid = payload.last_core_survey_uuid;
    state.last_core_survey_status = payload.last_core_survey_status;
  }
  if (
    payload.last_filter_survey_uuid &&
    payload.last_filter_survey_uuid.length > 1
  ) {
    state.last_filter_survey_uuid = payload.last_filter_survey_uuid;
    state.last_filter_survey_status = payload.last_filter_survey_status;
  }
  if (
    payload.last_technical_survey_uuid &&
    payload.last_technical_survey_uuid.length > 1
  ) {
    state.last_technical_survey_uuid = payload.last_technical_survey_uuid;
    state.last_technical_survey_status = payload.last_technical_survey_status;
  }
  localStorage.setItem(REFRESH_TOKEN, payload.refresh_token);
  localStorage.setItem(AUTH_TOKEN, payload.access_token);
  setTimeout(() => {
    localStorage.clear();
  }, (1000 * 60 * 60 * 0.5));
  if (payload.access_token.length > 1) {
    state.isPending = false;
    state.isAuthenticated = true;
  }
  if (payload.role) {
    state.role = payload.role;
  }
  if (payload.planType) {
    state.planType = payload.planType;
  }
};
