import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { width } from "@mui/system";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Label} from 'recharts';
import { RootState } from "../redux/rootReducer";
import { useSelector } from "react-redux";
import Button from '@mui/material/Button';
import { useAppDispatch } from "../redux/store";
import {riskResponseDashboard} from "../redux/slices/riskResponseDashboard";
import {riskResponseGraphData} from "../redux/slices/riskResponseGraphSlice";
import { filterControlListFetch } from "../redux/slices/filterControlListSlice";
import { any } from "prop-types";

const useStyles = makeStyles((theme) => ({
  card: {
    // width: "100%",
    // height: 100,
    borderRadius: 10,
    // boxShadow: "2px 3px 8px -1px rgba(0,0,0,0.2)",
    backgroundColor: "#FFFFF"
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    backgroundColor:"white",
  },
  heading: {
    fontSize: 25,
    fontWeight: 600,
  },
  riskManagementTable: {
    // width: "582px",
    width:"100%",
    // marginTop:"2%",
    borderCollapse: "collapse",
    height: "171px",
    background: "#F8F8F8",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    [`& th`]: {
      borderBottom: "1px solid #E8E8E8",
      fontWeight: "normal !important",
      padding:"4px",
      paddingTop:"8px !important",
      paddingBottom:"8px !important",
     
    },
    [`& td`]: {
      textAlign: "center",
      verticalAlign: "middle",
      padding: "4px",
      paddingTop:"9px !important",
      paddingBottom:"9px !important",
    },
    [`& td +td `]: {
        borderLeft:"1px solid #D2D2D2",
    },
    [`& th +th `]: {
        borderLeft:"1px solid #D2D2D2",
    },
    overflowY:"auto",
  },
  tableContainer: {
    height: "171px",
    overflowY:"auto",
    // width:"74%",
    width:"70%",
    //  scrollbarWidth:"thin",
    // '&::-webkit-scrollbar': {
    //   width: '0.8em',
    // },
  },
  securityControlCompDropdown: {
    display: "flex",
    marginTop: "14px",
    // width: "582px",
    justifyContent: "space-between",
  },
  dropSelect: {
    border: "1px solid #F8F8F8",
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: "#F8F8F8",
    [`& fieldset `]: {
      border: "1px solid #F8F8F8",
      borderRadius: "8px",
    }
  },
  dropdownContainer: {
    // backgroundColor: "#F8F8F8",
    width: "30%",
    marginTop: "2% !important",
  },
  sectionHeader: {
    fontSize: "14px",
    color: "#1071BA",
    marginTop: "19px",
    marginLeft: "23px",
  },
  graph: {
      marginTop:"2%",
    ".&recharts-legend-wrapper": {
      width:"0px !important",
    }
  },
  /////////////
  middleSectionContainer: {
      display:"flex",
      marginTop:"2%",
  },
  riskReviewCounter: {
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    height: "171px",
    marginLeft: "15px",
    width: "29%",
  },
  reviewData: {
     padding:"18px",
  },
  noOfRisk : {
    fontSize: "24px",
    color: "black",
    fontWeight: 600,
},
noOfRiskText: {
    color:"black",
    borderBottom: "2px solid red",
    paddingBottom: "8px",
    fontSize: 14,
    fontWeight: 500,
},
  riskStatus: {
       marginTop: "15px !important",
       color: "red !important",
  }, 
  graphContainer: {
    backgroundColor:"#F8F8F8",
    marginTop:"19px",
    paddingBottom: 12,
    borderRadius: 8,
  }
}))

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const OpenButton = withStyles((theme: Theme) => ({
  root: {
    color: "#F15642 !important",
    // height: 40,
    // width: 200,
    fontSize: 14,
    fontWeight: 300,
    backgroundColor: "#F8F8F8",
    textTransform: "lowercase",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
    borderRadius: 8,
  },
}))(Button);

const RiskResponsePlanCard = ({filteredControls}:any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [windowSize,setWindowSize] = useState(0);
  const access_token = localStorage.getItem("Access_Token");
  useEffect(()=> {
    dispatch(riskResponseDashboard({access_token}));
    dispatch(riskResponseGraphData({access_token}));
    dispatch(filterControlListFetch({access_token}));
  },[]);
  const [tableKey, setTableKey] = useState([]);

  // due to naming convention change graphdataa
  let riskResponseTableData =  useSelector((state:RootState) => state.rrDashboardTableData.tableData);
  let riskResponseGraphDataa = useSelector((state:RootState) => state.riskResponseGraphData.graphData);
  let filteredControlListData = useSelector((state:RootState) => state.filteredControlData.allFilterControlList);
  const userPlan = useSelector((state: RootState) => state.user?.planType);
  let controlLength =  filteredControlListData.length;
  
  // window.onload = ()=>{
  //   let windowLength = document.documentElement.clientWidth || window.innerWidth;
  //   setWindowSize(windowLength);
  //   // console.log("onload is calling",windowLength)
  // }
  useEffect(() =>{
    let windowLength = document.documentElement.clientWidth || window.innerWidth;
    setWindowSize(windowLength);
  },[])
  console.log("onload is calling",windowSize);
  window.onresize= ()=>{
  let windowLength = document.documentElement.clientWidth || window.innerWidth;
  setWindowSize(windowLength);
  }
  // console.log(windowSize,'wwwwwwwww',window.innerWidth); 
  return (
    <div className={classes.card} style={userPlan === "Pro" ? {}:{opacity: 0.5, pointerEvents: "none"}}>
      <div className={classes.cardContentWrapper}>
        <Typography className={classes.heading}>Risk response plan</Typography>

           <Typography className={classes.sectionHeader}>Not implemented control</Typography>
           <div className = {classes.middleSectionContainer}>
             <div className={classes.tableContainer}>
        <table className={classes.riskManagementTable}>
        <tr>
            <th style={{textAlign: "left", paddingLeft: 30}}>Control name</th>
            <th>Status</th>
            <th>Due date</th>
          </tr>
          {
            riskResponseTableData.map((values,key) => {
              if(windowSize <1800) {
              return(
                 <tr key={key}>
                   <td style={{textAlign: "left", paddingLeft: 30}}>{values.control_name.length > 30 ? values.control_name.slice(0,30)+"...": values.control_name}</td>
                   <td style={{width:"25%"}}>{values.Status}</td>
                   <td style={{width:"25%"}}>{values.due_date}</td>
                   </tr>     
            )
            }
            else{
              return(
              <tr key={key}>
              <td style={{textAlign: "left", paddingLeft: 30}}>{values.control_name.slice(0,30)+"..."}</td>
              <td style={{width:"25%"}}>{values.Status}</td>
              <td style={{width:"25%"}}>{values.due_date}</td>
              </tr> 
              )
            }
          })
          }
          
        </table>
        </div>
          <div className={classes.riskReviewCounter}>
                <div className={classes.reviewData}>
                <Typography className={classes.noOfRisk}>
                {controlLength}
                </Typography>
                <Typography className={classes.noOfRiskText}>
                Controls<br/>
                to focus
                </Typography>
                {/* <Button variant="text" className={classes.riskStatus} onClick={filteredControls}>Open</Button> */}
                <OpenButton onClick={filteredControls}><Typography style={{textTransform: "none", marginTop: 10}}>Open</Typography></OpenButton>
                </div>
              </div> 
        </div>
        <Typography className={classes.sectionHeader}>Control implementation progress</Typography>
        <ResponsiveContainer width="100%" height={270} className={classes.graphContainer}>
          <LineChart width={730} height={250} data={riskResponseGraphDataa}
            margin={{ top: 5, right: 30, left: 20, bottom: 10 }} className={classes.graph}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="days" > 
             <Label value="Days" offset={0} position="bottom" textAnchor="end"/>
             </XAxis>
            <YAxis label={{ value: 'Controls', angle: -90, position: 'insideLeft' }} />  
            <Tooltip />
            <Legend content={renderLegend}/>
           
            <Line type="linear" dataKey="allControls" stroke="#F15642" dot={false}  />
            <Line type="linear" dataKey="closedToImplemented" stroke="#1071BA" dot={false}  />
          </LineChart>  
        </ResponsiveContainer>
      </div>
     
    </div>
  );
}

const renderLegend = (props:any) => {
  const { payload } = props;

  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", paddingTop: "20px"}}>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight: 15}}>
        <div style={{height: 5, width: 5, background:"#F15642", marginRight: 5}}></div>
        <span style={{color:"#F15642"}}>All controls</span>
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight: 15}}>
        <div style={{height: 5, width: 5, background:"#1071BA", marginRight: 5}}></div>
        <span style={{color:"#1071BA"}}>Implemented Controls</span>
      </div>    
    </div>
  );
}

export default RiskResponsePlanCard;