import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAssessmentApi } from "../../api/survey/createAssessmentApi";
import { loadSurveyQuestionsApi } from "../../api/survey/loadSurveyQuestionsApi";
import { submitResponseApi } from "../../api/survey/submitResponseApi";
import { changeStatusSurveyApi } from "../../api/survey/changeStatusSurveyApi";
import { submitFeedbackApi } from "../../api/feedback/submitFeedbackApi";
import { loadFilterQuestionsApi } from "../../api/filter/loadFilterQuestionsApi";
import { submitFilterResponseApi } from "../../api/filter/submitFilterResponseApi";

export const createAssessmentThunk = createAsyncThunk(
  "survey/",
  async (
    {
      category_name,
    }: {
      category_name: string;
    },
    thunkApi
  ) => {
    try {
      const { user }: any = thunkApi.getState();
      return await createAssessmentApi(user.access_token, category_name);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loadSurveyQuestions = createAsyncThunk(
  "loadSurveyQuestions/",
  async ({ uuid }: { uuid: string }, thunkApi) => {
    try {
      const { user }: any = thunkApi.getState();
      return await loadSurveyQuestionsApi(user.access_token, uuid);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const submitResponseThunk = createAsyncThunk(
  "survey/submitResponse",
  async (
    {
      response,
      id_question,
      id_survey,
      uuid,
      response_explanation,
    }: {
      response: string;
      id_survey: string;
      id_question: string;
      uuid: string;
      response_explanation?: string;
    },
    thunkApi
  ) => {
    try {
      const { user }: any = thunkApi.getState();
      return await submitResponseApi(
        response,
        id_survey,
        id_question,
        user.access_token,
        uuid,
        response_explanation
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const changeStatusSurveyThunk = createAsyncThunk(
  "survey/finish",
  async (
    { uuid, survey_status }: { uuid: string; survey_status: string },
    thunkApi
  ) => {
    try {
      const { user }: any = thunkApi.getState();
      return await changeStatusSurveyApi(
        user.access_token,
        uuid,
        survey_status
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const submitFeedbackThunk = createAsyncThunk(
  "survey/feedback",
  async (
    {
      access_token,
      uuid,
      question1,
      question2,
      question4,
      question3_1,
      question3_2,
      question3_3,
    }: {
      access_token: string;
      uuid: string;
      question1: string;
      question2: string;
      question4: string;
      question3_1: string;
      question3_2: string;
      question3_3: string;
    },
    thunkApi
  ) => {
    try {
      return await submitFeedbackApi(
        access_token,
        uuid,
        question1,
        question2,
        question4,
        question3_1,
        question3_2,
        question3_3
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loadFilterQuestions = createAsyncThunk(
  "loadFilterQuestions/",
  async ({ uuid }: { uuid: string }, thunkApi) => {
    try {
      const { user }: any = thunkApi.getState();
      return await loadFilterQuestionsApi(user.access_token, uuid);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const submitFilterResponseThunk = createAsyncThunk(
  "survey/submitFilterResponse",
  async (
    {
      response,
      id_survey,
      id_filter,
      uuid,
      response_explanation,
    }: {
      response: string;
      id_survey: string;
      id_filter: string;
      uuid: string;
      response_explanation?: string;
    },
    thunkApi
  ) => {
    try {
      const { user }: any = thunkApi.getState();
      return await submitFilterResponseApi(
        response,
        id_survey,
        id_filter,
        user.access_token,
        uuid,
        response_explanation
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export interface Question {
  id: number;
  question_id: string;
  parent_question: string[];
  question_text: string;
  domain: string;
  category: string;
}

export interface Survey_Question {
  response_explanation: string;
  response: string;
  question: Question;
}

export interface Survey {
  id: number;
  uuid: string;
  survey_status: string;
}

export interface FilterQuestion {
  id: number;
  filter_id: string;
  text: string;
}

export interface FilterSurvey {
  filter_question: FilterQuestion;
  response_explanation: string;
  response: string;
}

export interface CurrentPosition {
  activeDomain: string;
  sidebarDomains: string[];
}

export type initialStateSurvey = {
  survey_questions: Survey_Question[];
  survey: Survey;
  filter_questions: FilterSurvey[];
  category_name: string;
} & CurrentPosition;

const initialState: initialStateSurvey = {
  survey_questions: [],
  survey: {
    id: 0,
    uuid: "",
    survey_status: "",
  },
  activeDomain: "",
  sidebarDomains: [],
  filter_questions: [],
  category_name: "",
};
const survey = createSlice({
  name: "surveySlice",
  initialState,
  reducers: {
    setActiveDomain(state, action: PayloadAction<string>) {
      state.activeDomain = action.payload;
    },
    setSidebarDomains(state, action: PayloadAction<string[]>) {
      state.sidebarDomains = action.payload;
    },
    resetSurvey: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(loadSurveyQuestions.fulfilled, (state, { payload }) => {
      setStateWithSurvey(state, payload);
      for (let i = 0; i < payload.survey_questions.length; i++) {
        state.survey_questions.push({
          question: {
            id: payload.survey_questions[i].question.id,
            question_id: payload.survey_questions[i].question.question_id,
            question_text: payload.survey_questions[i].question.question_text,
            domain: payload.survey_questions[i].question.domain,
            category: payload.survey_questions[i].question.category,
            parent_question: [
              ...payload.survey_questions[i].question.parent_question,
            ],
          },
          response: payload.survey_questions[i].response,
          response_explanation:
            payload.survey_questions[i].response_explanation,
        });
      }
    });
    builder.addCase(submitResponseThunk.fulfilled, (state, { payload }) => {
      const changedQuestionIndex = state.survey_questions.findIndex(
        (survey_question: Survey_Question) =>
          payload.survey_question.question.id === survey_question.question.id
      );
      state.survey_questions[changedQuestionIndex].response =
        payload.survey_question.response;
      state.survey_questions[changedQuestionIndex].response_explanation =
        payload.survey_question.response_explanation;
    });
    builder.addCase(changeStatusSurveyThunk.fulfilled, (state, { payload }) => {
      state.survey.survey_status = payload.survey.survey_status;
    });
    builder.addCase(loadFilterQuestions.fulfilled, (state, { payload }) => {
      setStateWithSurvey(state, payload);
      for (let i = 0; i < payload.filter_questions.length; i++) {
        state.filter_questions.push({
          filter_question: {
            id: payload.filter_questions[i].filter_question.id,
            filter_id: payload.filter_questions[i].filter_question.filter_id,
            text: payload.filter_questions[i].filter_question.text,
          },
          response: payload.filter_questions[i].response,
          response_explanation:
            payload.filter_questions[i].response_explanation,
        });
      }
    });
    builder.addCase(
      submitFilterResponseThunk.fulfilled,
      (state, { payload }) => {
        const changedFilterQuestionIndex = state.filter_questions.findIndex(
          (singleFilterSurvey: FilterSurvey) =>
            payload.survey_filter.filter_question.id ===
            singleFilterSurvey.filter_question.id
        );
        state.filter_questions[changedFilterQuestionIndex].response =
          payload.survey_filter.response;
        state.filter_questions[
          changedFilterQuestionIndex
        ].response_explanation = payload.survey_filter.response_explanation;
      }
    );
  },
});
export const { setActiveDomain, resetSurvey, setSidebarDomains } =
  survey.actions;
export default survey.reducer;

const setStateWithSurvey = (state: initialStateSurvey, payload: any) => {
  state.survey.id = payload.survey.id;
  state.survey.uuid = payload.survey.uuid;
  state.survey.survey_status = payload.survey.survey_status;
  state.category_name = payload.survey_category.category_name;
};
