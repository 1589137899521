import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {restoreRow} from "../../api/documentManagement/restoreRow";

export const restoreOneRow = createAsyncThunk(
  "/deletedocumentStatus",
  async ({ access_token, question_id}: any, thunkAPI) => {
    try {
      return await restoreRow(access_token,question_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const restoreReducer = createSlice({
  name: "reducerRestore",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(restoreOneRow.fulfilled, (state, { payload }) => {
      state.value = '';
      // payload?.forEach((value: any) => {
      //   state?.docList?.push({
      //     id: value.id,
      //     name: value.name,
      //     question_id: value.question_id,
      //     iso_control: value.iso_control,
      //     last_updated: value.last_updated,
      //     status: value.status,
      //     type: value.type,
      //     applicability: value.applicability,
      //     owner: value.owner,
      //   });
      // });
    });
  },
});

//export const { documentList } = allDocListGet.actions;
export default restoreReducer.reducer;