import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Button } from "@material-ui/core";
import { resetUser } from "../../../redux/slices/userSlice";
import { resetSurvey } from "../../../redux/slices/surveySlice";
import { useAppDispatch } from "../../../redux/store";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { drawerWidth } from "../Sidebar/Sidebar";
import { AppbarProps } from "./Appbar.props";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import clsx from "clsx";
import HeaderProfile from "../../../layout/HeaderProfile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      boxShadow: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      marginRight: theme.spacing(15),
      marginTop: theme.spacing(4),
    },
    button: {
      marginTop: theme.spacing(3),
      background: "#FFFFFF",
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      minWidth: "170px",
      paddingTop: "7px",
      borderRadius: "10px",
      fontWeight: 600,
      width: theme.spacing(22),
      height: theme.spacing(6),
    },
    mainMenuButton: {
      marginRight: theme.spacing(2),
    },
  })
);
const Appbar = (props: AppbarProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { handleDrawerToggle } = props;
  const isFinishedFirstCore = useSelector(
    (state: RootState) => state.user.isFinishedFirstCore
  );
  return (
    <AppBar position="absolute" className={classes.appBar} color="transparent">
      <HeaderProfile isFinishedFirstCore={isFinishedFirstCore} appBar={true} />
      {/* <Toolbar> */}
      {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton> */}
      {/* <div className={classes.buttonContainer}>
          {isFinishedFirstCore && (
            <Button
              color="primary"
              variant="outlined"
              className={clsx(classes.button, classes.mainMenuButton)}
              onClick={() => {
                dispatch(resetSurvey());
                history.push('/');
              }}
            >
              Main menu
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => {
              localStorage.clear();
              dispatch(resetUser());
              dispatch(resetSurvey());
              history.push('/signin');
            }}
          >
            Log out
          </Button>
        </div> */}
      {/* </Toolbar> */}
    </AppBar>
  );
};
export default Appbar;
