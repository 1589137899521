import React, { useEffect, useState } from "react";
import HeaderProfile from "../layout/HeaderProfile";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Button, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import lineBackground from "../assets/lineBackground.svg";
import { useSnackbar } from "notistack";
import { createAssessmentThunk } from "../redux/slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../redux/store";
import { confirmAlert } from "react-confirm-alert";
import { REFRESH_TOKEN } from "../types/constants";
import { loadUser, User } from "../redux/slices/userSlice";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loading from "../layout/Loading"; // Import css
import Drawer from "@material-ui/core/Drawer";
// import { matrixSettingCheck } from "../redux/slices/riskMatrixSetCheckSlice";
import CoreAssessmentModal from "../components/CoreAssessmentModal";
import { userDeviceDetails } from "../redux/slices/devicePlatformInfoSlice";
import WhiteSidebarLogo from "./Core Assessment/Sidebar/WhiteSidebarLogo";
import SecurtiyDashboardCard from "./SecurityDashboardCard";
import RiskManagementCard from "./riskManagementCard";
import RiskResponsePlanCard from "./RiskResponsePlanCard";
import DocumentManagementCard from "./DocumentManagementCard";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dashboard from "../assets/dashboard.svg";
import UsersProfile from "./UsersProfile/UsersProfile";
import RiskManagement from "./RiskManagement/RiskManagement";
import RemediationPlan from "./Remediation/RemediationPlan";
import Report from "./Report/Report";

import { securityControlDashboard } from "../redux/slices/securityControlDashboard";
import { securityControlGraphData } from "../redux/slices/securityControlGraphData";
import { riskResponseDashboard } from "../redux/slices/riskResponseDashboard";
import { riskResponseGraphData } from "../redux/slices/riskResponseGraphSlice";
import { filterControlListFetch } from "../redux/slices/filterControlListSlice";
import { dmTableDashboard } from "../redux/slices/dmDashboardSlice";

const drawerWidth = 297;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    backgroundColor: "#F8F8F8",
  },
  homepageDiv: {
    display: "flex",
    // marginTop: 10,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1071BA",
    // marginTop: 120,
    // borderTop: "1px solid #1071BA",
  },

  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    width: "100%",
    marginRight: 40,
    marginLeft: 40,
    overflow: "hidden",
    // backgroundColor:"#F8F8F8",
  },

  optionDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionDivSelected: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionText: {
    color: "#fff",
    fontSize: 16,
    marginLeft: 27,
  },
  optionTextSelected: {
    color: "#fff",
    fontSize: 16,
    marginLeft: 27,
  },

  workProgress: {
    fontSize: 24,
    fontWeight: 600,
  },

  congratulationsText: {
    color: theme.palette.primary.main,
  },
  questionnaireDescription: {
    marginTop: theme.spacing(2),
  },
  mainButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(10),
  },
  buttonGroupContainer: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    backgroundColor: "rgba(255,255,255,0.6)",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#FFFFFF",
    },
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    marginRight: theme.spacing(4),
    fontWeight: 800,
    width: theme.spacing(34),
    height: theme.spacing(14),
    marginTop: theme.spacing(3),
  },
  dashboardText: {
    display: "inline",
  },
  nestedWrapper: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow: "none !important",
    margin: "0px !important",
    "&::before": {
      height: "0px !important",
    },
  },
  nestedWrapperSummary: {
    padding: "0px 28px !important",
    outline: "none !important",
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

function MainPage() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  const [openModal, setOpenModal] = useState(false);
  const [resetSurvey, setResetSurvey] = useState("");
  const [assessmentType, setAssessmentType] = useState("");
  const [sideBarIndex, setSideBarIndex] = useState(0);
  const [report, setReport] = useState(false);
  const [technicalReport, setTechnicalReport] = useState(false);
  const [userProfileOpen, setUserProfileOpen] = useState(false);
  const [isFilteredControl, setFilteredControl] = useState(false);
  const [isRiskToReview, setRiskToReview] = useState(false);
  const [isDocToWork, setDocToWork] = useState(false);
  const [expanded, setExpanded] = React.useState<string | boolean>(false);
  // const access_token = localStorage.getItem("Access_Token");
  const history = useHistory();
  useEffect(() => {
    if (refresh_token && refresh_token.length > 1) {
      dispatch(loadUser({ refresh_token }))
        .then(unwrapResult)
        .then((user: User) => {
          const email = user?.email;
          const device = navigator.userAgent;
          const payload = {
            email: email,
            device: device,
          };
          dispatch(userDeviceDetails(payload));
          if (user.role === "Administrator" && !isFinishedFirstCore) {
            if (user.isNew) {
              history.push("/welcome-page/");
            } else if (
              user.role === "Administrator" &&
              user.last_core_survey_uuid &&
              user.last_core_survey_uuid.length > 1 &&
              user.last_core_survey_status === "OP"
            ) {
              history.push(`/core/${user.last_core_survey_uuid}/`);
            }
          }
        });
    } else {
      history.push("/signin/");
    }
  }, []);

  useEffect(() => {
    if (assessmentType === "Core") {
      if (resetSurvey === "false") {
        history.push(`/core/${last_core_survey_uuid}/`);
      } else if (resetSurvey === "true") {
        startAssessment("Core Assessment", "core");
      }
    }

    if (assessmentType === "Technical") {
      if (resetSurvey === "false") {
        if (last_filter_survey_status && last_filter_survey_status === "OF") {
          history.push(`/filter/${last_filter_survey_uuid}/`);
        } else if (
          last_filter_survey_status &&
          last_filter_survey_status === "OT"
        ) {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        } else if (
          last_filter_survey_status &&
          last_filter_survey_status === "OD"
        ) {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        } else {
          history.push(`/technical/${last_filter_survey_uuid}/`);
        }
      } else if (resetSurvey === "true") {
        startAssessment("Filter Assessment", "filter");
      }
    }
  }, [resetSurvey]);
  // filteredControlData "filtered risk control data is here"
  // dashboard api

  useEffect(() => {
    const access_token = localStorage.getItem("Access_Token");
    // dispatch(securityControlGraphData({access_token}));
    // dispatch(dmTableDashboard({access_token}));
    // dispatch(securityControlDashboard({access_token}));
    // dispatch(filterControlListFetch({access_token}));
  }, []);

  const isPending = useSelector((state: RootState) => state.user.isPending);
  const userPlan = useSelector((state: RootState) => state.user?.planType);
  const { enqueueSnackbar } = useSnackbar();
  const isFinishedFirstCore = useSelector(
    (state: RootState) => state.user.isFinishedFirstCore
  );

  const last_core_survey_uuid = useSelector(
    (state: RootState) => state.user.last_core_survey_uuid
  );
  const last_core_survey_status = useSelector(
    (state: RootState) => state.user.last_core_survey_status
  );

  const last_filter_survey_uuid = useSelector(
    (state: RootState) => state.user.last_filter_survey_uuid
  );
  const last_filter_survey_status = useSelector(
    (state: RootState) => state.user.last_filter_survey_status
  );
  const userRole = useSelector((state: RootState) => state.user.role);

  if (isPending) {
    return <Loading />;
  }

  const startCoreHandler = () => {
    if (userRole === "Administrator") {
      setOpenModal(true);
      setAssessmentType("Core");

      // if (last_core_survey_status && last_core_survey_status === "RG") {
      //   showStartCoreConfirmMessage("Core Assessment", "core");
      // } else
      // if (
      //   last_core_survey_status &&
      //   (last_core_survey_status === "OP" || last_core_survey_status === "OF")
      // ) {
      // history.push(`/core/${last_core_survey_uuid}/`);
      // }
      // else {
      //   startAssessment("Core Assessment", "core");
      // }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const coreReportHandler = () => {
    if (userRole === "Administrator") {
      if (last_core_survey_status && last_core_survey_status === "RG") {
        setReport(true);
        // history.push(`/report/${last_core_survey_uuid}/`);                     <- On previous code
      } else {
        enqueueSnackbar("Please complete core questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const startTechnicalHandler = () => {
    if (userRole === "Administrator") {
      if (!isFinishedFirstCore) {
        enqueueSnackbar("Please complete core questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OF"
      // ) {
      //   history.push(`/filter/${last_filter_survey_uuid}/`);
      // } else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OT"
      // ) {
      //   history.push(`/technical/${last_filter_survey_uuid}/`);
      // }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "RG"
      // ) {
      //   showStartCoreConfirmMessage("Filter Assessment", "filter");
      // }
      // else if (
      //   last_filter_survey_status &&
      //   last_filter_survey_status === "OD"
      // ) {
      //   history.push(`/technical/${last_filter_survey_uuid}/`);
      // }
      else {
        setOpenModal(true);
        setAssessmentType("Technical");
        // startAssessment("Filter Assessment", "filter");
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const technicalReportHandler = () => {
    if (userRole === "Administrator") {
      if (last_filter_survey_status && last_filter_survey_status === "RG") {
        setTechnicalReport(true);
        // history.push(`/report/${last_filter_survey_uuid}/`);
      } else {
        enqueueSnackbar("Please complete technical questionnaire first", {
          autoHideDuration: 2000,
          variant: "warning",
        });
      }
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }
  };

  const usersProfile = () => {
    if (userRole === "Administrator") {
      // history.push(`/usersprofile`);
      setUserProfileOpen(true);
    } else {
      enqueueSnackbar("Access denied, please contact Administrator", {
        autoHideDuration: 2000,
        variant: "error",
      });
    }

    // if (userRole === "Administrator") {
    // } else {
    //   enqueueSnackbar("Access denied, please contact Administrator", {
    //     variant: "error",
    //   });
    // }
  };

  const showStartCoreConfirmMessage = (
    assessment_category: string,
    url_to_category: string
  ) => {
    confirmAlert({
      title: "Confirm to start new assessment",
      message: "This will reset previous assessment results",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            startAssessment(assessment_category, url_to_category);
          },
        },
        {
          label: "No",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  const startAssessment = (
    assessment_category: string,
    url_to_category: string
  ) => {
    dispatch(
      createAssessmentThunk({
        category_name: assessment_category,
      })
    )
      .then(unwrapResult)
      .then((response: any) => {
        if (response.survey.uuid) {
          if (refresh_token) {
            dispatch(loadUser({ refresh_token }))
              .then(unwrapResult)
              .then(() => {
                history.push(`/${url_to_category}/${response.survey.uuid}/`);
              });
          }
        }
      })
      .catch((error) => {});
  };

  //  const [isFilteredControl,setFilteredControl] = useState(false);  at the bottom it providing error due to hook rule
  const filteredControls = () => {
    setSideBarIndex(7);
    setFilteredControl(true);
  };
  const upcomingRiskToReview = () => {
    setSideBarIndex(6);
    setRiskToReview(true);
  };

  const docTowork = () => {
    setDocToWork(true);
    // history.push(`/docmanagement/${isDocToWork}`);
    // setState affect after page redirecting
  };
  if (isDocToWork) {
    history.push(`/docmanagement/${isDocToWork}`);
  }
  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };
  console.log(expanded, "------");
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <HeaderProfile />
          <div className={classes.homepageDiv}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <WhiteSidebarLogo />
              <div style={{ marginTop: 20 }}>
                <div
                  className={
                    sideBarIndex === 0
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  onClick={() => {
                    setSideBarIndex(0);
                    setDocToWork(false);
                  }}
                >
                  <Typography
                    className={
                      sideBarIndex === 0
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                  
                    <Typography className={classes.dashboardText}>
                      Dashboard
                    </Typography>
                  </Typography>
                </div>
                <div></div>
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  className={classes.nestedWrapper}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classes.nestedWrapperSummary}
                  >
                    <Typography style={{ color: "white" }}>
                      Assesments
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div
                        className={
                          sideBarIndex === 1
                            ? classes.optionDivSelected
                            : classes.optionDiv
                        }
                        // onClick={() => setSideBarIndex(1)}
                        onClick={startCoreHandler}
                      >
                        <Typography
                          className={
                            sideBarIndex === 1
                              ? classes.optionTextSelected
                              : classes.optionText
                          }
                        >
                           Core controls
                        </Typography>
                      </div>

                      <div
                        className={
                          sideBarIndex === 2
                            ? classes.optionDivSelected
                            : classes.optionDiv
                        }
                        // onClick={() => setSideBarIndex(2)}
                        onClick={startTechnicalHandler}
                      >
                        <Typography
                          className={
                            sideBarIndex === 2
                              ? classes.optionTextSelected
                              : classes.optionText
                          }
                        >
                          Technical controls
                        </Typography>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  className={classes.nestedWrapper}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                    className={classes.nestedWrapperSummary}
                  >
                    <Typography style={{ color: "white" }}>Reports</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className={
                        sideBarIndex === 3
                          ? classes.optionDivSelected
                          : classes.optionDiv
                      }
                      onClick={() => {
                        setSideBarIndex(3);
                        coreReportHandler();
                      }}
                      // onClick={coreReportHandler}  (<- on previous code)
                    >
                      <Typography
                        className={
                          sideBarIndex === 3
                            ? classes.optionTextSelected
                            : classes.optionText
                        }
                      >
                        Core controls
                      </Typography>
                    </div>

                    <div
                      className={
                        sideBarIndex === 4
                          ? classes.optionDivSelected
                          : classes.optionDiv
                      }
                      onClick={() => {
                        setSideBarIndex(4);
                        technicalReportHandler();
                      }}
                      // onClick={technicalReportHandler}
                    >
                      <Typography
                        className={
                          sideBarIndex === 4
                            ? classes.optionTextSelected
                            : classes.optionText
                        }
                      >
                        Technical controls
                      </Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div
                  className={
                    sideBarIndex === 5
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  style={
                    userPlan === "Pro"
                      ? {}
                      : { opacity: 0.5, pointerEvents: "none" }
                  }
                  onClick={() => {
                    setSideBarIndex(5);
                    usersProfile();
                  }}
                  //  onClick={usersProfile}
                >
                  <Typography
                    className={
                      sideBarIndex === 5
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    User profiles
                  </Typography>
                </div>

                <div
                  className={
                    sideBarIndex === 6
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  onClick={() => {
                    setSideBarIndex(6);
                    setRiskToReview(false);
                  }}
                  // onClick={() => history.push(`/riskmanagement`)}
                  style={
                    userPlan === "Pro"
                      ? {}
                      : { opacity: 0.5, pointerEvents: "none" }
                  }
                >
                  <Typography
                    className={
                      sideBarIndex === 6
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Risk management
                  </Typography>
                </div>

                <div
                  className={
                    sideBarIndex === 7
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  onClick={() => {
                    setSideBarIndex(7);
                    setFilteredControl(false);
                  }}
                  // onClick={() => history.push(`/remediationplan`)}
                  style={
                    userPlan === "Pro"
                      ? {}
                      : { opacity: 0.5, pointerEvents: "none" }
                  }
                >
                  <Typography
                    className={
                      sideBarIndex === 7
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Remediation plan
                  </Typography>
                </div>

                <div
                  className={
                    sideBarIndex === 8
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  // onClick={() => setSideBarIndex(4)}
                  onClick={() => history.push(`/soa`)}
                  style={
                    userPlan === "Pro"
                      ? {}
                      : { opacity: 0.5, pointerEvents: "none" }
                  }
                >
                  <Typography
                    className={
                      sideBarIndex === 8
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Statement of applicability
                  </Typography>
                </div>

                <div
                  className={
                    sideBarIndex === 9
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  // onClick={() => setSideBarIndex(4)} //
                  onClick={() => {
                    history.push(`/docmanagement/${isDocToWork}`);
                    setDocToWork(false);
                  }}
                  style={
                    userPlan === "Pro"
                      ? {}
                      : { opacity: 0.5, pointerEvents: "none" }
                  }
                >
                  <Typography
                    className={
                      sideBarIndex === 9
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    Document management
                  </Typography>
                </div>
              </div>
            </Drawer>
            <CoreAssessmentModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              setResetSurvey={setResetSurvey}
              assessmentType={assessmentType}
            />
            {sideBarIndex === 0 && (
              <div className={classes.contentContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={6} lg={6} sm={6} xl={6}>
                    <SecurtiyDashboardCard />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={6} xl={6}>
                    <RiskManagementCard
                      upcomingRiskToReview={upcomingRiskToReview}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={6} xl={6}>
                    <RiskResponsePlanCard filteredControls={filteredControls} />
                  </Grid>
                  <Grid item xs={6} lg={6} sm={6} xl={6}>
                    <DocumentManagementCard docTowork={docTowork} />
                  </Grid>
                </Grid>
              </div>
            )}
            {sideBarIndex === 5 && userProfileOpen && (
              <div className={classes.contentContainer}>
                <UsersProfile />
              </div>
            )}
            {sideBarIndex === 6 && (
              <div className={classes.contentContainer}>
                <RiskManagement isRiskToReview={isRiskToReview} />
              </div>
            )}
            {sideBarIndex === 7 && (
              <div className={classes.contentContainer}>
                <RemediationPlan isFilteredControl={isFilteredControl} />
              </div>
            )}

            {sideBarIndex == 3 && report && (
              <div className={classes.contentContainer}>
                <Report uuid={last_core_survey_uuid} />
                {/* here instead of sending props in url, in report component i am sending props and same reciving in report component  */}
              </div>
            )}

            {sideBarIndex == 4 && technicalReport && (
              <div className={classes.contentContainer}>
                <Report uuid={last_filter_survey_uuid} />
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
      {/* <div className={classes.root}>
        <Container component="main" maxWidth="lg">
          <Typography
            variant="h4"
            gutterBottom
            align="center"
            className={classes.congratulationsText}
          >
            <Box fontWeight={800}>Welcome to the main page</Box>
          </Typography>
          <Typography
            variant="h6"
            align="center"
            className={classes.questionnaireDescription}
          >
            Please choose below action you want.
          </Typography>
          <div className={clsx(classes.mainButtonContainer)}>
            <div className={clsx(classes.buttonGroupContainer)}>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={startCoreHandler}
              >
                START CORE
                <br />
                CONTROLS ASSESSMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={coreReportHandler}
              >
                OPEN CORE
                <br />
                CONTROLS ASSESSMENT REPORT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={usersProfile}
              >
                USER PROFILES
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/remediationplan`)}
              >
                REMEDIATION PLAN
              </Button>
                
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/docmanagement`)}
              >
                DOCUMENT MANAGEMENT
              </Button>

            </div>
            <div className={clsx(classes.buttonGroupContainer)}>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={startTechnicalHandler}
              >
                START TECHNICAL
                <br />
                CONTROLS ASSESSMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={technicalReportHandler}
              >
                OPEN TECHNICAL
                <br />
                CONTROLS ASSESSMENT REPORT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/riskmanagement`)}
              >
                RISK MANAGEMENT
              </Button>
              <Button
                className={clsx(classes.button)}
                variant="contained"
                onClick={() => history.push(`/soa`)}
              >
                Statement of Applicability
              </Button>
            </div>
          </div>
          <CoreAssessmentModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            setResetSurvey={setResetSurvey}
            assessmentType={assessmentType}
          />
        </Container>
      </div> */}
    </>
  );
}

export default MainPage;
