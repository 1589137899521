import React, { useState, useEffect } from "react";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import { useAppDispatch } from "../../redux/store";
import { usersListFetch } from "../../redux/slices/usersListSlice";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { newRiskAdd } from "../../redux/slices/addRiskSlice";
import AddDialog from "../UsersProfile/AddDialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import AddRiskGroup from "./addRiskGroup";
import AddRiskProject from "./addRiskProject";
import { riskGroupFetch } from "../../redux/slices/riskGroupSlice";
import { projectListFetch } from "../../redux/slices/projectListSlice";
import goTo from "../../assets/goTo.svg";
import Confirmation from "./confirmationModal";
import { useSnackbar } from "notistack";
import { riskMatrixFetch } from "../../redux/slices/riskMatrixGetSlice";
import { allRiskMatrixFetch } from "../../redux/slices/allRiskMatrixSlice";
import { coreControls, techControls } from "./ISOControls";
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 1000,
    paddingLeft: 150,
    paddingRight: 150,
    paddingTop: 50,
    paddingBottom: 100,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  riskGroupDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  riskDescriptionDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: 13,
  },
  textFieldTitle: {
    fontSize: 18,
    fontWeight: 500,
    color: "#2C2C2C",
  },
  textFields: {
    flex: 1,
    maxWidth: 373,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldSmall: {
    flex: 1,
    maxWidth: 250,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldDate: {
    flex: 1,
    maxWidth: 227,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldLarge: {
    flex: 1,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  dropdownSelect: {
    borderRadius: 8,
  },
  btnDiv: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 55,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginRight: 10,
    marginLeft: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 59,
    maxWidth: 237,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 500,
    fontSize: 18,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
  dropdownDiv: {
    // maxWidth: 373,
    // flex: 1,
  },
  dropdown: {
    width: 250,
  },
  dropdownSmall: {
    width: 195,
  },
  dropdownFull: {
    width: 395,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  coreControl: {
    display: "flex",
  }
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddRisk = ({ setModalState, modalState }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const access_token = localStorage.getItem("Access_Token");

  // const impact5_5 = ["Low", "Moderate", "Major", "Critical", "Catastrophic"];
  // const likelihood5_5 = [
  //   "Rare",
  //   "Unlikely",
  //   "Possible",
  //   "Likely",
  //   "Almost certain",
  // ];
  // const impact4_4 = ["Low", "Moderate", "Major", "Critical"];
  // const likelihood4_4 = ["Unlikely", "Possible", "Likely", "Almost certain"];
  // const impact3_3 = ["Low", "Medium", "High"];
  // const likelihood3_3 = ["Unlikely", "Possible", "Certain"];

  const [editState, setEditState] = useState(false);
  const [addGroupState, setAddGroupState] = useState(false);
  const [addProjectState, setAddProjectState] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [selectedMatrix, setSelectedMatrix] = useState("");
  const [riskDetails, setRiskDetails] = useState({
    risk_status: "",
    risk_group: "",
    name: "",
    description: "",
    vulnerability: "",
    likelihood: "",
    likelihood_justification: "",
    impact: "",
    impact_justification: "",
    existing_controls: "",
    risk_response: "",
    residual_risk_rating: "",
    related_project: "",
    comments: "",
    risk_closure_justification: "",
    risk_review_date: new Date() as any,
    risk_rating: "",
    risk_owner: "",
    iso_control: [] as string[],
  });

  const [errorState, setErrorState] = useState({
    risk_status: false,
    risk_group: false,
    name: false,
    description: false,
    vulnerability: false,
    likelihood: false,
    likelihood_justification: false,
    impact: false,
    impact_justification: false,
    existing_controls: false,
    risk_response: false,
    residual_risk_rating: false,
    related_project: false,
    risk_review_date: false,
    risk_rating: false,
    risk_closure_justification: false,
    risk_owner: false,
    iso_control: false,
  });

  const handleCloseModal = () => {
    setModalState(false);
  };

  useEffect(() => {
    if (modalState) {
      dispatch(usersListFetch({ access_token }));
      dispatch(allRiskMatrixFetch({ access_token }));
      dispatch(riskMatrixFetch({ access_token }))
        .then(unwrapResult)
        .then((response: any) => {
          const currentMatrix = response.matrix;
          setSelectedMatrix(currentMatrix);
        })
        .catch((error) => {});
    }
  }, [modalState]);

  const usersList = useSelector(
    (state: RootState) => state.usersList.allUsersList
  );

  const projectList = useSelector(
    (state: RootState) => state.projectList.allprojectlist
  );

  const groupList = useSelector(
    (state: RootState) => state.riskGroup.allRiskGroups
  );

  const updateUserList = useSelector(
    (state: RootState) => state.addNewUser.email
  );

  const updateRiskProject = useSelector(
    (state: RootState) => state.addRiskProject.id
  );

  const updateRiskGroup = useSelector(
    (state: RootState) => state.addRiskGroup.id
  );

  const currentMatrixGet = useSelector(
    (state: RootState) => state?.riskMatrixGet.riskMatrixList
  );

  const riskMatrix3_3 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_3_3
  );

  const riskMatrix4_4 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_4_4
  );

  const riskMatrix5_5 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_5_5
  );

  const logs = useSelector((state: RootState) => state);
  // console.log(logs, "gggggggg");

  const isoControls = useSelector((state: RootState) => state.isoControls.isoControlsList);

  let impact3: string[] = [];
  riskMatrix3_3?.forEach((val) => {
    impact3.push(val.impact);
  });

  let impact4: string[] = [];
  riskMatrix4_4?.forEach((val) => {
    impact4.push(val.impact);
  });

  let impact5: string[] = [];
  riskMatrix5_5?.forEach((val) => {
    impact5.push(val.impact);
  });

  const impact3_3 = [...new Set(impact3)];

  const impact4_4 = [...new Set(impact4)];

  const impact5_5 = [...new Set(impact5)];

  let likelihood3: string[] = [];
  riskMatrix3_3?.forEach((val) => {
    likelihood3.push(val.Likelihood);
  });

  let likelihood4: string[] = [];
  riskMatrix4_4?.forEach((val) => {
    likelihood4.push(val.Likelihood);
  });

  let likelihood5: string[] = [];
  riskMatrix5_5?.forEach((val) => {
    likelihood5.push(val.Likelihood);
  });

  const likelihood3_3 = [...new Set(likelihood3)];

  const likelihood4_4 = [...new Set(likelihood4)];

  const likelihood5_5 = [...new Set(likelihood5)];

  // console.log(impact3, "YYYYYYYYYY");

  useEffect(() => {
    if (modalState) {
      dispatch(usersListFetch({ access_token }));
    }
  }, [modalState, updateUserList]);

  useEffect(() => {
    if (modalState) {
      dispatch(projectListFetch({ access_token }));
    }
  }, [modalState, updateRiskProject]);

  useEffect(() => {
    if (modalState) {
      dispatch(riskGroupFetch({ access_token }));
    }
  }, [modalState, updateRiskGroup]);

  useEffect(() => {
    const temp = currentMatrixGet?.filter(
      (value) =>
        value.impact === riskDetails.impact &&
        value.Likelihood === riskDetails.likelihood
    );
    setRiskDetails({ ...riskDetails, risk_rating: temp[0]?.result });
    // console.log(temp, "1111111",currentMatrixGet);
  }, [riskDetails.impact, riskDetails.likelihood]);

  const handleChangeRiskStatus = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskDetails({
      ...riskDetails,
      risk_status: event.target.value as string,
    });
  };

  const handleChangeLikelihood = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskDetails({
      ...riskDetails,
      likelihood: event.target.value as string,
    });
  };

  const handleChangeImpact = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRiskDetails({
      ...riskDetails,
      impact: event.target.value as string,
    });
  };

  const handleChangeRiskResponse = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskDetails({
      ...riskDetails,
      risk_response: event.target.value as string,
    });
  };

  const handleChangeResidualRiskRating = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskDetails({
      ...riskDetails,
      residual_risk_rating: event.target.value as string,
    });
  };

  const handleChangeRiskOwner = (event: any, value: any) => {
    if (event.target.value === "Add New Risk Owner") {
      setRiskDetails({
        ...riskDetails,
        risk_owner: event.target.value as string,
      });
      setEditState(true);
    } else {
      setRiskDetails({
        ...riskDetails,
        risk_owner: event.target.value as string,
      });
    }
  };

  const handleChangeISOControls = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setRiskDetails({
      ...riskDetails,
      iso_control: event.target.value as string[],
    });
  };

  const handleChangeRiskGroup = (event: any) => {
    if (event.target.value === "Add New Group") {
      setRiskDetails({
        ...riskDetails,
        risk_group: event.target.value as string,
      });
      addGroup();
    } else {
      setRiskDetails({
        ...riskDetails,
        risk_group: event.target.value as string,
      });
    }
  };

  const handleSelectRiskGroup = (event: any) => {
    // console.log(event?.target?.innerText,"kkkkk")
    if (event?.target?.innerText === "Add New Group") {
      addGroup();
    }
  };

  const handleSelectRelatedProject = (event: any) => {
    if (event?.target?.innerText === "Add New Project") {
      addProject();
    }
  };

  const handleSelectAddRiskOwner = (event: any) => {
    if (event?.target?.innerText === "Add New Risk Owner") {
      setEditState(true);
    }
  };

  const handleChangeRelatedProject = (event: any) => {
    if (event.target.value === "Add New Project") {
      setRiskDetails({
        ...riskDetails,
        related_project: event.target.value as string,
      });
      addProject();
    } else {
      setRiskDetails({
        ...riskDetails,
        related_project: event.target.value as string,
      });
    }
  };

  const resetErrors = () => {
    setErrorState({
      risk_status: false,
      risk_group: false,
      name: false,
      description: false,
      vulnerability: false,
      likelihood: false,
      likelihood_justification: false,
      impact: false,
      impact_justification: false,
      existing_controls: false,
      risk_response: false,
      residual_risk_rating: false,
      related_project: false,
      risk_review_date: false,
      risk_rating: false,
      risk_closure_justification: false,
      risk_owner: false,
      iso_control: false,
    });
  };

  const handleValidate = async () => {
    const errors: any = {};
    let validated = true;
    if (!riskDetails.risk_status) {
      errors["risk_status"] = true;
      validated = false;
    }
    if (!riskDetails.risk_group || riskDetails.risk_group === "Add New Group") {
      errors["risk_group"] = true;
      validated = false;
    }
    if (!riskDetails.name) {
      errors["name"] = true;
      validated = false;
    }
    if (!riskDetails.description) {
      errors["description"] = true;
      validated = false;
    }
    if (!riskDetails.vulnerability) {
      errors["vulnerability"] = true;
      validated = false;
    }
    if (!riskDetails.likelihood) {
      errors["likelihood"] = true;
      validated = false;
    }
    if (!riskDetails.likelihood_justification) {
      errors["likelihood_justification"] = true;
      validated = false;
    }
    if (!riskDetails.impact) {
      errors["impact"] = true;
      validated = false;
    }
    if (!riskDetails.impact_justification) {
      errors["impact_justification"] = true;
      validated = false;
    }
    if (!riskDetails.existing_controls) {
      errors["existing_controls"] = true;
      validated = false;
    }
    if (!riskDetails.risk_response) {
      errors["risk_response"] = true;
      validated = false;
    }
    if (!riskDetails.residual_risk_rating) {
      errors["residual_risk_rating"] = true;
      validated = false;
    }
    if (
      !riskDetails.related_project ||
      riskDetails.related_project === "Add New Project"
    ) {
      errors["related_project"] = true;
      validated = false;
    }
    if (!riskDetails.risk_review_date) {
      errors["risk_review_date"] = true;
      validated = false;
    }
    if (!riskDetails.risk_rating) {
      errors["risk_rating"] = true;
      validated = false;
    }
    if (
      riskDetails.risk_status === "Closed" &&
      !riskDetails.risk_closure_justification
    ) {
      errors["risk_closure_justification"] = true;
      validated = false;
    }
    if (
      !riskDetails.risk_owner ||
      riskDetails.risk_owner === "Add New Risk Owner"
    ) {
      errors["risk_owner"] = true;
      validated = false;
    }
    if (!riskDetails.iso_control) {
      errors["iso_control"] = true;
      validated = false;
    }
    // console.log(errors,validated,"ccccccc")
    if (validated === true) {
      handleSubmit();
      resetErrors();
    }
    setErrorState({ ...errors });
  };

  const handleSubmit = () => {
    if (
      riskDetails.risk_status &&
      riskDetails.risk_group &&
      riskDetails.name &&
      riskDetails.description &&
      riskDetails.vulnerability &&
      riskDetails.likelihood &&
      riskDetails.likelihood_justification &&
      riskDetails.impact &&
      riskDetails.impact_justification &&
      riskDetails.existing_controls &&
      riskDetails.risk_response &&
      riskDetails.residual_risk_rating &&
      riskDetails.related_project &&
      // riskDetails.comments &&
      // riskDetails.risk_closure_justification &&
      riskDetails.risk_review_date &&
      riskDetails.risk_rating &&
      riskDetails.risk_owner &&
      riskDetails.iso_control
    ) {
      const payload = {
        access_token: access_token,
        risk_status: riskDetails.risk_status,
        risk_group: riskDetails.risk_group,
        name: riskDetails.name,
        description: riskDetails.description,
        vulnerability: riskDetails.vulnerability,
        likelihood: riskDetails.likelihood,
        likelihood_justification: riskDetails.likelihood_justification,
        impact: riskDetails.impact,
        impact_justification: riskDetails.impact_justification,
        existing_controls: riskDetails.existing_controls,
        risk_response: riskDetails.risk_response,
        residual_risk_rating: riskDetails.residual_risk_rating,
        related_project: riskDetails.related_project,
        comments: riskDetails.comments,
        risk_closure_justification: riskDetails.risk_closure_justification,
        risk_review_date: moment(riskDetails.risk_review_date).format(
          "YYYY-MM-DD"
        ),
        risk_rating: riskDetails.risk_rating,
        risk_owner: parseInt(riskDetails.risk_owner),
        iso_control: riskDetails.iso_control,
      };

      if (payload) {
        dispatch(newRiskAdd(payload))
          .then(unwrapResult)
          .then((response: any) => {
            // console.log(response,"nnnnn")
            setModalState(false);
            setRiskDetails({
              risk_status: "",
              risk_group: "",
              name: "",
              description: "",
              vulnerability: "",
              likelihood: "",
              likelihood_justification: "",
              impact: "",
              impact_justification: "",
              existing_controls: "",
              risk_response: "",
              residual_risk_rating: "",
              related_project: "",
              comments: "",
              risk_closure_justification: "",
              risk_review_date: new Date() as any,
              risk_rating: "",
              risk_owner: "",
              iso_control: [],
            });
          })
          .catch((errorResponse) => {
            enqueueSnackbar("Error occured, Could not save risk", {
              autoHideDuration: 2000,
              key: 1,
              variant: "error",
            });
          });
      }
    }
  };

  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (
      riskDetails.risk_status ||
      riskDetails.risk_group ||
      riskDetails.name ||
      riskDetails.description ||
      riskDetails.vulnerability ||
      riskDetails.likelihood ||
      riskDetails.likelihood_justification ||
      riskDetails.impact ||
      riskDetails.impact_justification ||
      riskDetails.existing_controls ||
      riskDetails.risk_response ||
      riskDetails.residual_risk_rating ||
      riskDetails.related_project ||
      riskDetails.comments ||
      // riskDetails.risk_closure_justification ||
      // riskDetails.risk_review_date ||
      riskDetails.risk_rating ||
      riskDetails.risk_owner ||
      riskDetails.iso_control
    ) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCloseModal();
    }
    return isConfirm;
  };

  const handleCancel = () => {
    setModalState(false);
    resetErrors();
    setRiskDetails({
      risk_status: "",
      risk_group: "",
      name: "",
      description: "",
      vulnerability: "",
      likelihood: "",
      likelihood_justification: "",
      impact: "",
      impact_justification: "",
      existing_controls: "",
      risk_response: "",
      residual_risk_rating: "",
      related_project: "",
      comments: "",
      risk_closure_justification: "",
      risk_review_date: new Date() as any,
      risk_rating: "",
      risk_owner: "",
      iso_control: [],
    });
  };

  const addGroup = () => {
    setAddGroupState(true);
  };

  const addProject = () => {
    setAddProjectState(true);
  };

  const handleChecked=(val:any)=>{
    let isAvailable=riskDetails.iso_control.indexOf(val.description)
    // console.log(val,"WWWWWWWWWWWWWWWWWWWWWW")
    if(isAvailable >=0 && val?.description){
      let updatededIso=riskDetails.iso_control.filter((value:any)=>value!==val.description)
      setRiskDetails((prev)=>{
        return{
          ...prev,
          iso_control:[...updatededIso]
        }
      })
    }
    else{
      setRiskDetails((prev)=>{
        return{
          ...prev,
          iso_control:[...prev.iso_control,val.description]
        }
      })
    }
   
   
  }

  const getChecked=(value:any)=>{
    // console.log(value, "checked")
    return riskDetails?.iso_control?.filter((val:any)=>val===value?.description).length>0 ? true :false
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Dialog
          onClose={handleCheckChanges}
          aria-labelledby="customized-dialog-title"
          open={modalState}
          onBackdropClick={() => handleCheckChanges()}
          onEscapeKeyDown={() => handleCheckChanges()}
          maxWidth={"xl"}
        >
          <DialogContent>
            <div className={classes.modalContainer}>
              <div className={classes.titleCenter}>
                <Typography className={classes.profileTitle}>
                  Add New Risk
                </Typography>
              </div>

              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1, marginRight: 113 }}>
                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk group
                    </Typography>
                    {/* <Button onClick={addGroup}>Add Group</Button> */}
                    <FormControl
                      variant="outlined"
                      className={classes.dropdown}
                    >
                      <InputLabel>Risk group</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                        error={errorState.risk_group}
                        onChange={handleChangeRiskGroup}
                        onClick={handleSelectRiskGroup}
                        className={classes.dropdownSelect}
                        label="Risk group"
                      >
                        <MenuItem value={`Add New Group`}>
                          Add New Group
                        </MenuItem>
                        {groupList.map((value) => (
                          <MenuItem value={`${value.id}`} key={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errorState.risk_group && (
                        <FormHelperText style={{ color: "red" }}>
                          Please fill this field
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk Name
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      label="Enter risk name"
                      variant="outlined"
                      error={errorState.name}
                      helperText={
                        errorState.name ? "Please fill this field" : null
                      }
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Risk status
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel>Risk Status</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          required
                          error={errorState.risk_status}
                          onChange={handleChangeRiskStatus}
                          className={classes.dropdownSelect}
                          label="Risk Status"
                        >
                          <MenuItem value={"Active"}>Active</MenuItem>
                          <MenuItem value={"Closed"}>Closed</MenuItem>
                          {/* <MenuItem value={"Deleted"}>Deleted</MenuItem> */}
                        </Select>
                        {errorState.risk_status && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div style={{ marginTop: 37, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Vulnerability
                      </Typography>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label="Enter vulnerability"
                      variant="outlined"
                      multiline
                      rows={5}
                      error={errorState.vulnerability}
                      helperText={
                        errorState.vulnerability
                          ? "Please fill this field"
                          : null
                      }
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          vulnerability: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 40, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Likelihood justification
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      multiline
                      rows={5}
                      id="outlined-required"
                      label="likelihood justification"
                      variant="outlined"
                      error={errorState.likelihood_justification}
                      helperText={
                        errorState.likelihood_justification
                          ? "Please fill this field"
                          : null
                      }
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          likelihood_justification: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 50, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Existing controls
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label="Enter existing controls"
                      variant="outlined"
                      error={errorState.existing_controls}
                      helperText={
                        errorState.existing_controls
                          ? "Please fill this field"
                          : null
                      }
                      multiline
                      rows={5}
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          existing_controls: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 62, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Related Project
                      </Typography>
                      {/* <Button onClick={addProject}>Add Prjct</Button> */}
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <FormControl
                      variant="outlined"
                      className={classes.dropdown}
                    >
                      <InputLabel>Risk project</InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        required
                        error={errorState.related_project}
                        onChange={handleChangeRelatedProject}
                        onClick={handleSelectRelatedProject}
                        className={classes.dropdownFull}
                        label="Risk project"
                      >
                        <MenuItem value={`Add New Project`}>
                          Add New Project
                        </MenuItem>
                        {projectList.map((value) => (
                          <MenuItem value={`${value.id}`} key={value.id}>
                            {value.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errorState.related_project && (
                        <FormHelperText style={{ color: "red" }}>
                          Please fill this field
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>

                  <div style={{ marginTop: 44 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Comments
                      </Typography>
                    </div>
                    <div className={classes.riskGroupDiv}>
                      <TextField
                        className={classes.textFieldLarge}
                        id="outlined-required"
                        label="Comments"
                        variant="outlined"
                        multiline
                        rows={13}
                        onChange={(e) =>
                          setRiskDetails({
                            ...riskDetails,
                            comments: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                {/*================================ second ==========================*/}
                <div style={{ flex: 1, marginLeft: 113 }}>
                  <div style={{ marginBottom: 12 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Description
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      id="outlined-required"
                      label="Enter risk description"
                      error={errorState.description}
                      helperText={
                        errorState.description ? "Please fill this field" : null
                      }
                      multiline
                      rows={6}
                      variant="outlined"
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 32 }}>
                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Impact
                        </Typography>
                      </div>
                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdown}
                        >
                          <InputLabel>Impact</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            error={errorState.impact}
                            onChange={handleChangeImpact}
                            className={classes.dropdownSelect}
                            label="Impact"
                          >
                            {selectedMatrix === "json_3_3" &&
                              impact3_3.map((val, index) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                            {selectedMatrix === "json_4_4" &&
                              impact4_4.map((val, index) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                            {selectedMatrix === "json_5_5" &&
                              impact5_5.map((val, index) => (
                                <MenuItem value={val} key={index}>
                                  {val}
                                </MenuItem>
                              ))}
                          </Select>
                          {errorState.impact && (
                            <FormHelperText style={{ color: "red" }}>
                              Please fill this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Likelihood
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdown}
                      >
                        <InputLabel>Likelihood</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          required
                          onChange={handleChangeLikelihood}
                          label="Likelihood"
                          error={errorState.likelihood}
                          className={classes.dropdownSelect}
                        >
                          {selectedMatrix === "json_3_3" &&
                            likelihood3_3.map((val, index) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                          {selectedMatrix === "json_4_4" &&
                            likelihood4_4.map((val, index) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                          {selectedMatrix === "json_5_5" &&
                            likelihood5_5.map((val, index) => (
                              <MenuItem value={val} key={index}>
                                {val}
                              </MenuItem>
                            ))}
                        </Select>
                        {errorState.likelihood && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <Typography className={classes.textFieldTitle}>
                      Risk rating
                    </Typography>
                    <TextField
                      className={classes.textFieldSmall}
                      required
                      id="outlined-required"
                      // label="risk rating"
                      placeholder="risk rating"
                      variant="outlined"
                      value={riskDetails.risk_rating}
                      disabled
                    />
                  </div>

                  <div style={{ marginTop: 30, marginBottom: 27 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Impact justification
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <TextField
                      className={classes.textFieldLarge}
                      required
                      multiline
                      rows={5}
                      id="outlined-required"
                      label="Impact justification"
                      variant="outlined"
                      error={errorState.impact_justification}
                      helperText={
                        errorState.impact_justification
                          ? "Please fill this field"
                          : null
                      }
                      onChange={(e) =>
                        setRiskDetails({
                          ...riskDetails,
                          impact_justification: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div style={{ marginTop: 103 }}>
                    <div className={classes.riskGroupDiv}>
                      <div>
                        <Typography className={classes.textFieldTitle}>
                          Risk response
                        </Typography>
                      </div>

                      <div className={classes.dropdownDiv}>
                        <FormControl
                          variant="outlined"
                          className={classes.dropdownSmall}
                        >
                          <InputLabel>Risk Response</InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            required
                            error={errorState.risk_response}
                            onChange={handleChangeRiskResponse}
                            className={classes.dropdownSelect}
                            label="Risk Response"
                          >
                            <MenuItem value={"Accept"}>Accept</MenuItem>
                            <MenuItem value={"Remediate"}>Remediate</MenuItem>
                            <MenuItem value={"Transfer"}>Transfer</MenuItem>
                            <MenuItem value={"Avoid"}>Avoid</MenuItem>
                          </Select>
                          {errorState.risk_response && (
                            <FormHelperText style={{ color: "red" }}>
                              Please fill this field
                            </FormHelperText>
                          )}
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className={classes.riskGroupDiv}>
                    <div>
                      <Typography className={classes.textFieldTitle}>
                        Residual risk rating
                      </Typography>
                    </div>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownSmall}
                      >
                        <InputLabel>Residual Risk Rating</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          required
                          error={errorState.residual_risk_rating}
                          onChange={handleChangeResidualRiskRating}
                          className={classes.dropdownSelect}
                          label="Residual Risk Rating"
                        >
                          <MenuItem value={"Low"}>Low</MenuItem>
                          <MenuItem value={"Medium"}>Medium</MenuItem>
                          <MenuItem value={"High"}>High</MenuItem>
                        </Select>
                        {errorState.residual_risk_rating && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div style={{ marginTop: 65, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk owner
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownFull}
                      >
                        <InputLabel>Risk Owner</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          required
                          onChange={handleChangeRiskOwner}
                          onClick={handleSelectAddRiskOwner}
                          className={classes.dropdownSelect}
                          error={errorState.risk_owner}
                          label="Risk Owner"
                        >
                          <MenuItem value="Add New Risk Owner">
                            Add New Risk Owner
                          </MenuItem>
                          {usersList
                            ?.filter(
                              (val) => val.is_active === true
                              //   && val.role === "Administrator" ||
                              // val.role === "Risk owner" ||
                              // val.role === "Risk manager"
                            )
                            ?.map((user, index) => (
                              <MenuItem value={`${user.id}`} key={index}>
                                {user.email}
                              </MenuItem>
                            ))}
                        </Select>
                        {errorState.risk_owner && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  <div style={{ marginTop: 85, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk review date
                      </Typography>
                      <KeyboardDatePicker
                        className={classes.textFieldDate}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        value={riskDetails.risk_review_date}
                        error={errorState.risk_review_date}
                        // helperText={errorState.existing_controls ? "Please select this field" : null}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={(date) =>
                          setRiskDetails({
                            ...riskDetails,
                            risk_review_date: date,
                          })
                        }
                      />
                    </div>
                  </div>

                  <div style={{ marginTop: 21, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Remediation plan
                      </Typography>
                      <div
                        style={{
                          borderRadius: 8,
                          border: "1px solid #D2D2D2",
                          height: 50,
                          width: 225,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => window.open(`/remediationplan`)}
                      >
                        <Typography style={{ color: "#1071BA", fontSize: 16 }}>
                          <u>Remediation controls</u>
                        </Typography>
                        <img src={goTo} alt="Link" style={{ marginLeft: 15 }} />
                      </div>
                    </div>
                  </div>

                  <div style={{ marginTop: 45, marginBottom: 22 }}>
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        ISO Controls
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.riskGroupDiv}>
                    <div className={classes.dropdownDiv}>
                      <FormControl
                        variant="outlined"
                        className={classes.dropdownFull}
                      >
                        <InputLabel>ISO Statndard Controls</InputLabel>
                        <Select
                          labelId="demo-mutiple-checkbox-label"
                          id="demo-mutiple-checkbox"
                          required
                          multiple
                          onChange={handleChangeISOControls}
                          className={classes.dropdownSelect}
                          value={riskDetails.iso_control}
                          renderValue={(selected) => (selected as string[]).join(', ')}
                          error={errorState.iso_control}
                          label="ISO Controls"
                        >
                          {isoControls?.map((value, index) => (
                            <div className={classes.coreControl}>
                              <Checkbox
                                checked={getChecked(value)}
                                onChange={()=>handleChecked(value)}
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                              />
                              <MenuItem
                                value={`${value.description}`}
                                key={index}
                              >
                                {`${value.control_id} ${' - '}${value.description}`}
                              </MenuItem>
                            </div>
                          ))}
                        </Select>
                        {errorState.iso_control && (
                          <FormHelperText style={{ color: "red" }}>
                            Please fill this field
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  </div>

                  {riskDetails.risk_status === "Closed" && (
                    <div className={classes.riskGroupDiv}>
                      <Typography className={classes.textFieldTitle}>
                        Risk closure justification
                      </Typography>
                    </div>
                  )}

                  {riskDetails.risk_status === "Closed" && (
                    <div className={classes.riskGroupDiv}>
                      <TextField
                        className={classes.textFieldLarge}
                        required
                        multiline
                        rows={4}
                        id="outlined-required"
                        label="risk closure justification"
                        variant="outlined"
                        error={errorState.risk_closure_justification}
                        helperText={
                          errorState.risk_closure_justification
                            ? "Please fill this field"
                            : null
                        }
                        onChange={(e) =>
                          setRiskDetails({
                            ...riskDetails,
                            risk_closure_justification: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className={classes.btnDiv}>
                <Button className={classes.saveBtn} onClick={handleValidate}>
                  SAVE
                </Button>
                <Button className={classes.cancelBtn} onClick={handleCancel}>
                  CANCEL
                </Button>
              </div>
              <AddDialog
                setEditState={setEditState}
                editState={editState}
                addNewRiskOwner={true}
              />
              <AddRiskGroup
                addGroupState={addGroupState}
                setAddGroupState={setAddGroupState}
              />
              <AddRiskProject
                addProjectState={addProjectState}
                setAddProjectState={setAddProjectState}
              />
              <Confirmation
                confirmation={confirmation}
                setConfirmation={setConfirmation}
                handleCancel={handleCancel}
              />
            </div>
          </DialogContent>
        </Dialog>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default AddRisk;
