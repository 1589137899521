import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import arrowDown from "../../assets/arrowDown.svg";
import arrowUp from "../../assets/arrowUp.svg";

const useStyles = makeStyles((theme) => ({
  riskSummaryBtnDark: {
    backgroundColor: "#1071BA",
    color: "#fff",
    height: 54,
    width: 257,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    fontWeight: 600,
    fontSize: 20,
    borderRadius: 8,
    textTransform: "none",
    marginBottom: 30,
  },
  riskSummaryBtnLight: {
    backgroundColor: "#fff",
    height: 54,
    width: 257,
    "&:hover": {
      backgroundColor: "#fff",
    },
    color: "#1071BA",
    fontWeight: 600,
    fontSize: 20,
    borderRadius: 8,
    border: "1px solid #1071BA",
    textTransform: "none",
    marginBottom: 30,
  },
  riskSummaryTable: {
    marginBottom: 42,
  },
  tableMain: {
    border: "1px solid #DEDEDE",
    width: 400,
    height: 300,
    borderCollapse: "collapse",
    borderRadius: 8,
  },
  leftAligned: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    textAlign: "left",
    paddingLeft: 20,
  },
  centerAligned: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    textAlign: "center",
  },
  greenDot: {
    height: 16,
    width: 16,
    borderRadius: "50%",
    background: "#7EDF8B",
    display: "inline-block",
  },
  yellowDot: {
    height: 16,
    width: 16,
    borderRadius: "50%",
    background: "#FCE380",
    display: "inline-block",
  },
  pinkDot: {
    height: 16,
    width: 16,
    borderRadius: "50%",
    background: "#E98A8E",
    display: "inline-block",
  },
  redDot: {
    height: 16,
    width: 16,
    borderRadius: "50%",
    background: "#D84436",
    display: "inline-block",
  },
  brownDot: {
    height: 16,
    width: 16,
    borderRadius: "50%",
    background: "#691B01",
    display: "inline-block",
  },
}));

const RiskSummary = ({ riskList }: any) => {
  const classes = useStyles();
  const [riskSummary, setRiskSummary] = useState(false);
  const [riskCount, setRiskCount] = useState({
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
  });

  const handleRiskSummary = () => {
    setRiskSummary(!riskSummary);
  };

  useEffect(() => {
    riskTypeCount();
  }, [riskList]);

  const riskTypeCount = async () => {
    const count: any = {};
    const low = riskList.filter((val: any) => val.risk_rating === "Low");
    const medium = riskList.filter((val: any) => val.risk_rating === "Medium");
    const high = riskList.filter((val: any) => val.risk_rating === "High");
    const critical = riskList.filter(
      (val: any) => val.risk_rating === "Critical"
    );
    if (low.length) {
      count["low"] = low.length;
    }
    if (medium.length) {
      count["medium"] = medium.length;
    }
    if (high.length) {
      count["high"] = high.length;
    }
    if (critical.length) {
      count["critical"] = critical.length;
    }
    setRiskCount({...riskCount, ...count});
  };

  return (
    <>
      <Button
        className={
          riskSummary ? classes.riskSummaryBtnLight : classes.riskSummaryBtnDark
        }
        onClick={handleRiskSummary}
      >
        Risk summary{" "}
        {riskSummary ? (
          <img src={arrowUp} style={{ marginLeft: 40 }} />
        ) : (
          <img src={arrowDown} style={{ marginLeft: 40 }} />
        )}
      </Button>

      {riskSummary && (
        <div className={classes.riskSummaryTable}>
          <table className={classes.tableMain}>
            <tr>
              <th className={classes.centerAligned}>
                <Typography>
                  <b>No</b>
                </Typography>
              </th>
              <th className={classes.leftAligned}>
                <Typography>
                  <b>Risk Rating</b>
                </Typography>
              </th>
              <th className={classes.centerAligned}>
                <Typography>
                  <b>Risk count</b>
                </Typography>
              </th>
              <th className={classes.centerAligned}>
                <Typography>
                  <b>Color</b>
                </Typography>
              </th>
            </tr>

            <tr>
              <td className={classes.centerAligned}>
                <Typography>1</Typography>
              </td>
              <td className={classes.leftAligned}>
                <Typography>Low</Typography>
              </td>
              <td className={classes.centerAligned}>
                <Typography>{riskCount.low}</Typography>
              </td>
              <td className={classes.centerAligned}>
                <span className={classes.greenDot}></span>
              </td>
            </tr>

            <tr>
              <td className={classes.centerAligned}>
                <Typography>2</Typography>
              </td>
              <td className={classes.leftAligned}>
                <Typography>Medium</Typography>
              </td>
              <td className={classes.centerAligned}>
                <Typography>{riskCount.medium}</Typography>
              </td>
              <td className={classes.centerAligned}>
                <span className={classes.yellowDot}></span>
              </td>
            </tr>

            <tr>
              <td className={classes.centerAligned}>
                <Typography>3</Typography>
              </td>
              <td className={classes.leftAligned}>
                <Typography>High</Typography>
              </td>
              <td className={classes.centerAligned}>
                <Typography>{riskCount.high}</Typography>
              </td>
              <td className={classes.centerAligned}>
                <span className={classes.pinkDot}></span>
              </td>
            </tr>

            <tr>
              <td className={classes.centerAligned}>
                <Typography>4</Typography>
              </td>
              <td className={classes.leftAligned}>
                <Typography>Critical</Typography>
              </td>
              <td className={classes.centerAligned}>
                <Typography>{riskCount.critical}</Typography>
              </td>
              <td className={classes.centerAligned}>
                <span className={classes.redDot}></span>
              </td>
            </tr>
          </table>
        </div>
      )}
    </>
  );
};

export default RiskSummary;
