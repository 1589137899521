import { useState } from "react";
import lineBackground from "../../assets/lineBackground.svg";
import { makeStyles, withStyles, Theme } from "@material-ui/core/styles";
import HeaderProfile from "../../layout/HeaderProfile";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { userPasswordChange } from "../../redux/slices/changePasswordSlice";
import { RootState } from "../../redux/rootReducer";
import { useHistory } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    backgroundImage: `url(${lineBackground})`,
  },
  passwordDiv: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  titleText: {
    fontSize: 30,
    color: "#1071BA",
    fontWeight: "bold",
    marginBottom: 30,
  },
  input: {
    width: 400,
  },
  hint: {
    color: "#1071BA",
    marginTop: 30,
  },
}));

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1071BA",
    height: 40,
    width: 200,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [cnfrmPassword, setcnfrmPassword] = useState("");
  const [errorState, setErrorState] = useState({
    error: false,
    errorMsg: "",
  });
  const [cnfrmPswrdError, setcnfrmPswrdError] = useState({
    error: false,
    errorMsg: "",
  });
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const refresh_token = localStorage.getItem("Refresh_Token");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const userInfo = useSelector((state: RootState) => state.user);
  const passwordChangedSuccess = useSelector(
    (state: RootState) => state.changeUserPassword
  );

  const handleValidate = () => {
    const reg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

    if (!newPassword) {
      setErrorState({
        error: true,
        errorMsg: "Please enter the password",
      });
    } else if (reg.test(newPassword) === false) {
      setErrorState({
        error: true,
        errorMsg:
          "Password does not meet the requirements. Please enter the correct password",
      });
    } else if (newPassword !== cnfrmPassword) {
      setcnfrmPswrdError({
        error: true,
        errorMsg: "Passwords do not match",
      });
      setErrorState({
        error: false,
        errorMsg: "",
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (userInfo.email && newPassword && refresh_token) {
      const payload = {
        email: userInfo.email,
        password: newPassword,
        refresh_token: refresh_token,
      };
      dispatch(userPasswordChange(payload))
        .then(unwrapResult)
        .then((response: any) => {
          // console.log(response,"nnnnn")
          localStorage.clear();
          history.push("/signin");
          enqueueSnackbar("Password changed successfully", {
            autoHideDuration: 2000,
            key: 1,
            variant: "success",
          });
        })
        .catch((errorResponse) => {
          enqueueSnackbar("Error occured, Could not change password", {
            autoHideDuration: 2000,
            key: 1,
            variant: "error",
          });
        });
    }
  };

  // useEffect(() => {
  //   if (passwordChangedSuccess.isTemporary === false) {
  //     localStorage.clear();
  //     dispatch(resetUser());
  //     dispatch(resetSurvey());
  //     history.push("/signin");
  //   }
  // }, [passwordChangedSuccess.isTemporary]);

  return (
    <>
      <div className={classes.root}>
        <HeaderProfile />
        <div className={classes.passwordDiv}>
          <div>
            <Typography className={classes.titleText}>
              Enter New Password
            </Typography>
          </div>
          <div>
            <TextField
              className={classes.input}
              label="Password"
              type="password"
              id="outlined-basic"
              variant="outlined"
              error={errorState.error}
              helperText={errorState.error ? errorState.errorMsg : null}
              onChange={(event) => setNewPassword(event.target.value)}
              value={newPassword}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              className={classes.input}
              label="Confirm Password"
              type="password"
              id="outlined-basic"
              variant="outlined"
              error={cnfrmPswrdError.error}
              helperText={
                cnfrmPswrdError.error ? cnfrmPswrdError.errorMsg : null
              }
              onChange={(event) => setcnfrmPassword(event.target.value)}
              value={cnfrmPassword}
            />
          </div>
          {errorState.error && (
            <div style={{ width: 400 }}>
              <Typography className={classes.hint}>
                Hint: Password should be at least <b>10</b> characters long and
                contain upper and lower case letters, numbers, and symbols like
                {"# / @ ! % ^ & )"}.
              </Typography>
            </div>
          )}
          <ColorButton
            style={{ marginTop: 30 }}
            variant="contained"
            color="primary"
            onClick={handleValidate}
          >
            Submit
          </ColorButton>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
