import React, { useEffect, useState } from "react";
import HeaderProfile from "../../layout/HeaderProfile";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import MyProfile from "./MyProfile";
import Matrix from "./Matrix";
import { Typography } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import WhiteSidebarLogo from "../Core Assessment/Sidebar/WhiteSidebarLogo";
import { useAppDispatch } from "../../redux/store";
import arrowRight from "../../assets/arrowRight.svg";

const drawerWidth = 297;

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "100vh",
    margin: 0,
    padding: 0,
  },
  settingsDiv: {
    display: "flex",
    // marginTop: 10,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1071BA",
    // marginTop: 120,
    // borderTop: "1px solid #1071BA",
  },

  profileContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "70%",
    // marginRight: 100,
    // marginLeft: 100,
  },
  optionDiv: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionDivSelected: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    width: "100%",
    background: "#fff",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionText: {
    color: "#fff",
    fontSize: 18,
    marginLeft: 27,
  },
  optionTextSelected: {
    color: "#1071BA",
    fontSize: 18,
    marginLeft: 27,
  },
  circle: {
    border: "1px solid #1071BA",
    borderRadius: "50%",
    height: 23,
    width: 23,
    display: "flex",
    justifyContent: "center",
    background: "#fff",
    marginLeft: 46,
  },
  number: {
    color: "#1071BA",
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const MySettings = () => {
  const classes = useStyles();
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();
  const [sideBarIndex, setSideBarIndex] = useState(0);

  const userRole = useSelector((state: RootState) => state.user.role);

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <HeaderProfile />
          <div className={classes.settingsDiv}>
            
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <WhiteSidebarLogo />
              <div style={{ marginTop: 20 }}>
                <div
                  className={
                    sideBarIndex === 0
                      ? classes.optionDivSelected
                      : classes.optionDiv
                  }
                  onClick={() => setSideBarIndex(0)}
                >
                  <span className={classes.circle}>
                    <Typography className={classes.number}>1</Typography>
                  </span>
                  <Typography
                    className={
                      sideBarIndex === 0
                        ? classes.optionTextSelected
                        : classes.optionText
                    }
                  >
                    My Profile
                  </Typography>
                  <img
                    style={{ marginLeft: 72 }}
                    src={arrowRight}
                    alt="arrow"
                  />
                </div>
                {(userRole === "Administrator" ||
                  userRole === "Risk manager") && (
                  <div
                    className={
                      sideBarIndex === 1
                        ? classes.optionDivSelected
                        : classes.optionDiv
                    }
                    onClick={() => setSideBarIndex(1)}
                  >
                    <span className={classes.circle}>
                      <Typography className={classes.number}>2</Typography>
                    </span>
                    <Typography
                      className={
                        sideBarIndex === 1
                          ? classes.optionTextSelected
                          : classes.optionText
                      }
                    >
                      Risk Matrix
                    </Typography>
                    <img
                      style={{ marginLeft: 72 }}
                      src={arrowRight}
                      alt="arrow"
                    />
                  </div>
                )}
              </div>
            </Drawer>
             

            {sideBarIndex === 0 && (
              <div className={classes.profileContainer}>
                <MyProfile />
              </div>
            )}
            {sideBarIndex === 1 && <Matrix />}
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default MySettings;
