import { server_url } from "../../types/constants";
import axios from "axios";

export async function subscriptionData(
    session_id: string,
) {
  const url = `${server_url}/authentication/subscription/?session_id=${session_id}`;
  try {
    const response = await axios({
      method: "post",
      url: url,
    });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
