import { server_url } from "../../types/constants";
import axios from "axios";

export async function loadSurveyQuestionsApi(
  access_token: string,
  uuid: string
) {
  const url = `${server_url}/survey/${uuid}/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
