import React from "react";
import Container from "@material-ui/core/Container";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import HeaderProfile from "../../layout/HeaderProfile";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import { submitFeedbackThunk } from "../../redux/slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../redux/store";
import { REFRESH_TOKEN } from "../../types/constants";
import { loadUser } from "../../redux/slices/userSlice";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import bigWhiteLogo from "../../assets/bigWhiteLogo.svg";
import { ParamTypes } from "../../types/survey";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    backgroundImage: `url(${bigWhiteLogo})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundOrigin: "border-box",
    backgroundSize: "600px 900px",
    backgroundAttachment: "fixed",
  },
  typography: {
    marginBottom: theme.spacing(1),
  },
  gridContainer: {
    marginLeft: theme.spacing(2),
  },
  questionNumeric: {
    flexBasis: "4%",
  },
  question: {
    marginTop: theme.spacing(5),
  },
  subQuestionContainer: {
    marginLeft: theme.spacing(5),
  },
  subQuestion: {
    marginTop: theme.spacing(3),
  },
  radioGroup: {
    marginLeft: theme.spacing(2),
  },
  radioLabel: {
    marginLeft: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(5),
  },
  congratulationsText: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(4),
  },
  textField: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    width: theme.spacing(80),
  },
}));

export default function Feedback() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { uuid } = useParams<ParamTypes>();
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  const access_token = useSelector(
    (state: RootState) => state.user.access_token
  );
  const survey = useSelector((state: RootState) => state.survey.survey);
  if (survey?.survey_status !== "OD") {
    history.push("/");
  }
  const { enqueueSnackbar } = useSnackbar();

  function validateValues(values: any) {
    if (values.question1.length === 0) {
      enqueueSnackbar("Please answer to question 1", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 1,
      });
      return false;
    } else if (values.question2.length === 0) {
      enqueueSnackbar("Please answer to question 2", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 2,
      });
      return false;
    } else if (values.question4.length === 0) {
      enqueueSnackbar("Please answer to question 3", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 3,
      });
      return false;
    } else if (values.question3_1.length === 0) {
      enqueueSnackbar("Please answer to question Document templates", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 3,
      });
      return false;
    } else if (values.question3_2.length === 0) {
      enqueueSnackbar("Please answer to question Risk catalogue", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 3,
      });
      return false;
    } else if (values.question3_3.length === 0) {
      enqueueSnackbar("Please answer to question Risk catalogue", {
        variant: "warning",
        autoHideDuration: 1500,
        key: 3,
      });
      return false;
    }
    return true;
  }

  return (
    <div className={classes.logoContainer}>
      <HeaderProfile />
      <CssBaseline />

      <Formik
        initialValues={{
          question1: "",
          question2: "",
          question3_1: "",
          question3_2: "",
          question3_3: "",
          question4: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          if (!validateValues(values)) {
            return;
          }
          const feedback_arguments: any = {
            access_token,
            uuid,
            question1: values.question1,
            question2: values.question2,
            question4: values.question4,
            question3_1: values.question3_1,
            question3_2: values.question3_2,
            question3_3: values.question3_3,
          };
          dispatch(submitFeedbackThunk(feedback_arguments))
            .then(unwrapResult)
            .then(() => {
              if (refresh_token && refresh_token.length > 1) {
                dispatch(loadUser({ refresh_token }))
                  .then(unwrapResult)
                  .then(() => {
                    history.push("/");
                  });
              }
            });
          setSubmitting(false);
        }}
      >
        {(formik) => (
          <Form>
            <Container component="main" maxWidth="md">
              <Typography
                variant="h4"
                gutterBottom
                align="center"
                className={classes.congratulationsText}
              >
                <Box fontWeight={800}>
                  Congratulations, You are almost done!
                </Box>
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                className={classes.typography}
              >
                We would love to know your experience and interest. <br />
                Please provide your answers in the below feedback form.
              </Typography>
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={1}
                className={classes.gridContainer}
              >
                {
                  // start 1 question
                }
                <Grid
                  item
                  xs={1}
                  className={clsx(classes.question, classes.questionNumeric)}
                >
                  <Typography>1</Typography>
                </Grid>
                <Grid item xs={11} className={classes.question}>
                  <Typography>
                    Was the questionnaire easy to understand and complete?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    row
                    onChange={formik.handleChange}
                    value={formik.values.question1}
                    className={classes.radioGroup}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label="1"
                      labelPlacement="end"
                      name="question1"
                      value="1"
                      className={classes.radioLabel}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="2"
                      labelPlacement="end"
                      name="question1"
                      value="2"
                      className={classes.radioLabel}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="3"
                      labelPlacement="end"
                      name="question1"
                      value="3"
                      className={classes.radioLabel}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="4"
                      labelPlacement="end"
                      name="question1"
                      value="4"
                      className={classes.radioLabel}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label="5"
                      labelPlacement="end"
                      name="question1"
                      value="5"
                      className={classes.radioLabel}
                    />
                  </RadioGroup>
                </Grid>
                {
                  // start 2 question
                }
                <Grid
                  item
                  xs={1}
                  className={clsx(classes.question, classes.questionNumeric)}
                >
                  <Typography>2</Typography>
                </Grid>
                <Grid item xs={11} className={classes.question}>
                  <Typography>
                    What domains do you found the most challenging to
                    understand?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField)}
                    label="Please write you comments"
                    variant="outlined"
                    multiline
                    rows={3}
                    rowsMax={5}
                    fullWidth
                    name="question2"
                    required
                    value={formik.values.question2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Grid>
                {
                  //Start 3 question
                }
                <Grid
                  item
                  xs={1}
                  className={clsx(classes.question, classes.questionNumeric)}
                >
                  <Typography>3</Typography>
                </Grid>
                <Grid item xs={11} className={classes.question}>
                  <Typography>
                    What other features would help you to implement a security
                    program?
                  </Typography>
                </Grid>
                <Grid container className={clsx(classes.subQuestionContainer)}>
                  {
                    // question 3, 1 sub category
                  }
                  <Grid item xs={12} className={clsx(classes.subQuestion)}>
                    <Typography>
                      Security document management, to create required documents{" "}
                      <br />
                      using templates following standard requirements.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      onChange={formik.handleChange}
                      value={formik.values.question3_1}
                      className={classes.radioGroup}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="1"
                        labelPlacement="end"
                        name="question3_1"
                        value="1"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="2"
                        labelPlacement="end"
                        name="question3_1"
                        value="2"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="3"
                        labelPlacement="end"
                        name="question3_1"
                        value="3"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="4"
                        labelPlacement="end"
                        name="question3_1"
                        value="4"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="5"
                        labelPlacement="end"
                        name="question3_1"
                        value="5"
                        className={classes.radioLabel}
                      />
                    </RadioGroup>
                  </Grid>
                  {
                    // question 3, 2 sub category
                  }
                  <Grid item xs={12} className={clsx(classes.subQuestion)}>
                    <Typography>
                      Risk management, to document risks and response plan.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      onChange={formik.handleChange}
                      value={formik.values.question3_2}
                      className={classes.radioGroup}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="1"
                        labelPlacement="end"
                        name="question3_2"
                        value="1"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="2"
                        labelPlacement="end"
                        name="question3_2"
                        value="2"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="3"
                        labelPlacement="end"
                        name="question3_2"
                        value="3"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="4"
                        labelPlacement="end"
                        name="question3_2"
                        value="4"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="5"
                        labelPlacement="end"
                        name="question3_2"
                        value="5"
                        className={classes.radioLabel}
                      />
                    </RadioGroup>
                  </Grid>
                  {
                    // question 3, 2 sub category
                  }
                  <Grid item xs={12} className={clsx(classes.subQuestion)}>
                    <Typography>
                      Task management, to assing findings to the team.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      row
                      onChange={formik.handleChange}
                      value={formik.values.question3_3}
                      className={classes.radioGroup}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="1"
                        labelPlacement="end"
                        name="question3_3"
                        value="1"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="2"
                        labelPlacement="end"
                        name="question3_3"
                        value="2"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="3"
                        labelPlacement="end"
                        name="question3_3"
                        value="3"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="4"
                        labelPlacement="end"
                        name="question3_3"
                        value="4"
                        className={classes.radioLabel}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        label="5"
                        labelPlacement="end"
                        name="question3_3"
                        value="5"
                        className={classes.radioLabel}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>

                {
                  // start 4 question
                }
                <Grid
                  item
                  xs={1}
                  className={clsx(classes.question, classes.questionNumeric)}
                >
                  <Typography>4</Typography>
                </Grid>
                <Grid item xs={11} className={classes.question}>
                  <Typography>
                    What other challenges do you have to implement a security
                    program?
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField)}
                    label="Please write you comments"
                    variant="outlined"
                    name="question4"
                    multiline
                    rows={3}
                    rowsMax={5}
                    required
                    onChange={formik.handleChange}
                    value={formik.values.question4}
                  />
                </Grid>
                {
                  // end 4 question
                }
                <Grid item xs={12} className={classes.button}>
                  <Button variant="contained" color="primary" type="submit">
                    Complete the assessment
                  </Button>
                </Grid>
                {
                  //end of grid
                }
              </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </div>
  );
}
