import React from "react";
import { useState, useEffect } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { borderRadius, width } from "@mui/system";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { securityControlDashboard } from "../redux/slices/securityControlDashboard";
import { useAppDispatch } from "../redux/store";
import { RootState } from "../redux/rootReducer";
import { useSelector } from "react-redux";
import { rmDashboardTable } from "../redux/slices/rmDashboardTableSlice";
import { riskWithoutAssessment } from "../redux/slices/rmDashboardDropdownSlice";
import { risToReviewkListFetch } from "../redux/slices/riskToReviewSlice";
import Button from '@mui/material/Button';
import { rmGraphDataFetch } from "../redux/slices/riskManagementGraphSlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: "100%",
    // height: 100,
    borderRadius: 10,
    // boxShadow: "2px 3px 8px -1px rgba(0,0,0,0.2)",
    backgroundColor: "#FFFFF"
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    backgroundColor: "white",
  },
  heading: {
    fontSize: 25,
    fontWeight: 600,
    marginBottom: 20,
  },
  // riskManagementTable: {
  //   // width: "582px",
  //   // marginTop:"2%",
  //   borderCollapse: "collapse",
  //   width:"70%",
  //   height: "171px",
  //   background: "#F8F8F8",
  //   borderRadius: "8px",
  //   fontFamily: "Poppins, sans-serif",
  //   [`& th`]: {
  //     borderBottom: "1px solid #E8E8E8",
  //     fontWeight: "normal !important",
  //   },
  //   [`& td`]: {
  //     textAlign: "center",
  //     verticalAlign: "middle",
  //   },
  //   [`& td +td `]: {
  //     borderLeft:"1px solid #D2D2D2",
  // },
  // [`& th +th `]: {
  //     borderLeft:"1px solid #D2D2D2",
  // },
  // },
  riskManagementTable: {
    width: "100%",
    borderCollapse: "collapse",
    height: "171px",
    background: "#F8F8F8",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    [`& th`]: {
      borderBottom: "1px solid #E8E8E8",
      fontWeight: "normal !important",
      padding: "4px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      width: "25%",
    },
    [`& td`]: {
      textAlign: "center",
      verticalAlign: "middle",
      padding: "4px",
      paddingTop: "9px !important",
      paddingBottom: "9px !important",
      width: "25%",
    },
    [`& td +td `]: {
      borderLeft: "1px solid #D2D2D2",
    },
    [`& th +th `]: {
      borderLeft: "1px solid #D2D2D2",
    },
    overflowY: "auto",
  },
  securityControlCompDropdown: {
    display: "flex",
    marginTop: "14px",
    // width: "582px",
    justifyContent: "space-between",
  },
  dropSelect: {
    // border: "1px solid #F8F8F8",
    // marginLeft: 7,
    // marginRight: 7,
    backgroundColor: "#F8F8F8",
    [`& fieldset `]: {
      // border: "1px solid #F8F8F8",
      borderRadius: "8px",
    }
  },

  dropdownContainer: {
    width: "62%",
    marginTop: "19px !important",
  },
  sectionHeader: {
    fontSize: "14px",
    color: "#1071BA",
    marginTop: "19px",
    marginLeft: "23px",
  },
  graph: {
    marginTop: "2%",
    ".&recharts-legend-wrapper": {
      width: "0px !important",
    }
  },
  middleSectionContainer: {
    display: "flex",
    marginTop: "2%",
  },
  riskReviewCounter: {
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    height: "171px",
    marginLeft: "15px",
    width: "29%",
  },
  reviewData: {
    padding: "18px",
  },
  noOfRisk: {
    fontSize: "24px",
    color: "black",
    fontWeight: 600,
  },
  noOfRiskText: {
    color: "black",
    borderBottom: "2px solid red",
    paddingBottom: "8px",
    fontSize: 14,
    fontWeight: 500,
  },
  // riskStatus: {
  //  marginTop: "12px !important",
  //  color: "red !important",
  //  textTransform: 'none !important',
  // fontFamily: "Poppins, sans-serif",
  // },

  graphContainer: {
    backgroundColor: "#F8F8F8",
    marginTop: "19px",
    borderRadius: 8,
    paddingBottom: 12
  },
  selectedItem: {
    backgroundColor: "white !important",
    "&:hover": {
      backgroundColor: "rgba(16, 113, 186, 0.09) !important",
    },
  },
  tableContainer: {
    height: "171px",
    overflowY: "auto",
    width: "70%",
  },
}))

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },

});

const OpenButton = withStyles((theme: Theme) => ({
  root: {
    color: "#F15642 !important",
    // height: 40,
    // width: 200,
    fontSize: 14,
    fontWeight: 300,
    backgroundColor: "#F8F8F8",
    textTransform: "lowercase",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
    borderRadius: 8,
  },
}))(Button);

const RiskManagementCard = ({ upcomingRiskToReview }: any) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [getTableSize, setTableSize] = useState(0);
  const [riskLength, setRiskLength] = useState(0);
  const access_token = localStorage.getItem("Access_Token");
  const [windowSize, setWindowSize] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(rmDashboardTable({ access_token }));
    dispatch(riskWithoutAssessment({ access_token }));
    dispatch(risToReviewkListFetch({ access_token }));
    dispatch(rmGraphDataFetch({ access_token }));
  }, [])
  const userPlan = useSelector((state: RootState) => state.user?.planType);
  let riskManagementTableData = useSelector((state: RootState) => state.rmDashboardTableData.tableData);
  let riskWithoutAssessmentData = useSelector((state: RootState) => state.riskWithoutAssessmentData.riskWithoutAssessmentInfo);
  let riskToReviewData = useSelector((state: RootState) => state.riskToReviewData.allRiskList);
  let riskManagementGraphData = useSelector((state: RootState) => state.riskManagementGraphData.graphData);
  let upcomingRiskLength = riskToReviewData.length;
  useEffect(() => {
    if (riskManagementTableData.length > 0) {
      setTableSize(riskManagementTableData[0].size)
    }
  }, [riskManagementTableData])
  useEffect(() => {
    if (riskWithoutAssessmentData.length > 0) {
      setRiskLength(riskWithoutAssessmentData.length);
    }
  }, [riskWithoutAssessmentData])

  useEffect(() => {
    let windowLength = document.documentElement.clientWidth || window.innerWidth;
    setWindowSize(windowLength);
  }, [])
  window.onresize = () => {
    let windowLength = document.documentElement.clientWidth || window.innerWidth;
    setWindowSize(windowLength);
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.card} style={userPlan === "Pro" ? {}:{opacity: 0.5, pointerEvents: "none"}}>
        <div className={classes.cardContentWrapper}>
          <Typography className={classes.heading}>Risk management</Typography>

          {/* <FormControl className={classes.dropdownContainer}>
            <InputLabel style={{color:"black",fontFamily:"Poppins", fontSize: 14}}>{`Risk without assessment (${riskLength})`}</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.dropSelect}
              label={`Risk without assessment (${riskLength}).....`}
            >
              {
                riskWithoutAssessmentData.slice(0,5).map((values,index) =>{
                  return(
                    <MenuItem className={classes.selectedItem} value={`${values}`}>
                      <span style={{fontFamily: "Poppins, sans-serif", fontSize: 14}}>{values}</span>
                    </MenuItem>
                  )
                })
              }
            </Select>
           </FormControl> */}

          <Button
            id="demo-customized-button"
            aria-controls="demo-customized-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="contained"
            disableElevation
            onClick={handleClick}
            style={{ width: "50%", borderRadius: 8, marginRight: 15, background: "#F8F8F8", color: "black", textTransform: "none" }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: 50 }}>
              <Typography style={{ fontSize: 14 }}>{`Not assessed risks  (${riskLength})`}</Typography>
              <KeyboardArrowDownIcon />
            </div>
          </Button>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: 200,
                // width: '17%',
              },
            }}
          >
            {
              riskWithoutAssessmentData.map((values, index) => {
                return (
                  <MenuItem className={classes.selectedItem} value={`${values}`}>
                    <span style={{ fontFamily: "Poppins, sans-serif", fontSize: 14 }}>{values}</span>
                  </MenuItem>
                )
              })
            }
          </Menu>


          <Typography className={classes.sectionHeader}>Not assessed risks by category</Typography>
          <div className={classes.middleSectionContainer}>
            {getTableSize === 0 && (
              <div className={classes.tableContainer}>
                <table className={classes.riskManagementTable}>
                  <tr>
                    <th style={{ textAlign: "left", paddingLeft: 30 }}>Risk group</th>
                    <th> Low</th>
                    <th>High</th>
                    <th>Medium</th>

                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </table>
              </div>
            )}

            {getTableSize === 3 && (
              <div className={classes.tableContainer}>
                <table className={classes.riskManagementTable}>
                  <tr>
                    <th style={{ textAlign: "left", paddingLeft: 30 }}>Risk group</th>
                    <th> Low<span style={{ height: 4, width: 4, borderRadius: "50%", background: "#7EDF8B", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th> Medium<span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FFA800", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th>High <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FF0000", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                  </tr>
                  {
                    riskManagementTableData.map((values, key) => {
                      if (windowSize < 2000) {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 30 }}>{values.group_name.slice(0, 12) + "..."}</td>
                            <td>{values.Low}</td>
                            <td>{values.Medium}</td>
                            <td>{values.High}</td>
                          </tr>
                        )
                      }
                      else {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 30 }}>{values.group_name}</td>
                            <td>{values.Low}</td>
                            <td>{values.Medium}</td>
                            <td>{values.High}</td>
                          </tr>
                        )
                      }

                    })
                  }
                </table>
              </div>
            )}

            {getTableSize === 4 && (
              <div className={classes.tableContainer}>
                <table className={classes.riskManagementTable}>
                  <tr>
                    <th style={{ textAlign: "left", paddingLeft: 30 }}>Risk group</th>
                    <th>Medium <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FFA800", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th>High <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FF0000", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th>Critical <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#B10000", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                  </tr>
                  {
                    riskManagementTableData.map((values, key) => {
                      if (windowSize < 2000) {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 10 }}>{values.group_name.slice(0, 12) + "..."}</td>
                            <td>{values.Medium}</td>
                            <td>{values.High}</td>
                            <td>{values.Critical}</td>
                          </tr>
                        )
                      }
                      else {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 10 }}>{values.group_name}</td>
                            <td>{values.Medium}</td>
                            <td>{values.High}</td>
                            <td>{values.Critical}</td>
                          </tr>
                        )
                      }
                    })
                  }
                </table>
              </div>
            )}

            {getTableSize === 5 && (
              <div className={classes.tableContainer}>
                <table className={classes.riskManagementTable}>
                  <tr>
                    <th style={{ textAlign: "left", paddingLeft: 30 }}>Risk group</th>
                    <th>Medium <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FFA800", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th>High <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#FF0000", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                    <th>Critical <span style={{ height: 4, width: 4, borderRadius: "50%", background: "#B10000", display: "inline-block", marginBottom: 3, marginLeft: 8 }}></span></th>
                  </tr>
                  {
                    riskManagementTableData.map((values, key) => {
                      if (windowSize < 2000) {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 30 }}>{values.group_name.slice(0, 12) + "..."}</td>
                            <td >{values.Medium}</td>
                            <td>{values.High}</td>
                            <td>{values.Critical}</td>
                          </tr>
                        )
                      }
                      else {
                        return (
                          <tr key={key}>
                            <td style={{ textAlign: "left", paddingLeft: 30 }}>{values.group_name}</td>
                            <td>{values.Medium}</td>
                            <td>{values.High}</td>
                            <td>{values.Critical}</td>
                          </tr>
                        )
                      }
                    })
                  }
                </table>
              </div>
            )}

            <div className={classes.riskReviewCounter}>
              <div className={classes.reviewData}>
                <Typography className={classes.noOfRisk}>
                  {upcomingRiskLength}
                </Typography>
                <Typography className={classes.noOfRiskText}>
                  Upcoming<br /> risks to review
                </Typography>
                {/* <Typography className={classes.riskStatus}>
                    Open          onClick={filteredControls}
                </Typography> */}
                <OpenButton onClick={upcomingRiskToReview}><Typography style={{ textTransform: "none", marginTop: 10 }}>Open</Typography></OpenButton>
              </div>
            </div>
          </div>
          <Typography className={classes.sectionHeader}>Risk assessment progress</Typography>
          <ResponsiveContainer width="100%" height={270} className={classes.graphContainer}>
            <LineChart data={riskManagementGraphData}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }} className={classes.graph}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="days">
                <Label value="Days" offset={0} position="bottom" textAnchor="end" />
              </XAxis>
              <YAxis label={{ value: 'Risks', angle: -90, position: 'insideLeft' }} />
              <Tooltip />
              <Legend content={renderLegend} />

              <Line type="linear" dataKey="Identified" stroke="#F15642" dot={false} />
              <Line type="linear" dataKey="Assessed" stroke="#1071BA" dot={false} />
            </LineChart>


          </ResponsiveContainer>
        </div>

      </div>
    </ThemeProvider>
  );
}

const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingTop: "20px" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
        <div style={{ height: 5, width: 5, background: "#F15642", marginRight: 5 }}></div>
        <span style={{ color: "#F15642" }}>Identified</span>
      </div>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginRight: 15 }}>
        <div style={{ height: 5, width: 5, background: "#1071BA", marginRight: 5 }}></div>
        <span style={{ color: "#1071BA" }}>Accessed</span>
      </div>
    </div>
  );
}

export default RiskManagementCard;