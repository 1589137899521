import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subscriptionData } from "../../api/plans/subscription";

export const subscriptionDetail = createAsyncThunk(
  "/subscription",
  async ({ session_id }: any, thunkAPI) => {
    try {
      return await subscriptionData(session_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateSubscription = {

};

const initialState: initialStateSubscription = {

};

const subscriptionCreate = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    resetSubscription: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(subscriptionDetail.fulfilled, (state, { payload }) => {

    });
  },
});

export const { resetSubscription } = subscriptionCreate.actions;

export default subscriptionCreate.reducer;
