import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FilterSurvey,
  changeStatusSurveyThunk,
  loadFilterQuestions,
  resetSurvey,
  setActiveDomain,
  setSidebarDomains,
} from "../../redux/slices/surveySlice";
import { useAppDispatch } from "../../redux/store";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Appbar from "../Core Assessment/Appbar/Appbar";
import Sidebar from "./Sidebar/Sidebar";
import FilterQuestionnaire from "./FilterQuestionnaire/FilterQuestionnaire";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { loadUser } from "../../redux/slices/userSlice";
import { REFRESH_TOKEN } from "../../types/constants";
import { ParamTypes } from "../../types/survey";
import HeaderProfile from "../../layout/HeaderProfile";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
    },
  })
);

function findActiveDomain(filterQuestions: FilterSurvey[]) {
  const found_index: any = filterQuestions.find(
    (singleFilterQuestion, index) => {
      if (singleFilterQuestion.response === "NR") {
        return index;
      }
    }
  );
  if (found_index && found_index > 5 && found_index < 12) {
    return 1;
  } else if (found_index >= 12) {
    return 2;
  }
  return 0;
}

const FilterAssessment = () => {
  const history = useHistory();
  const { uuid } = useParams<ParamTypes>();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const refresh_token = localStorage.getItem(REFRESH_TOKEN);
  const survey = useSelector((state: RootState) => state.survey.survey);
  const filter_questions = useSelector(
    (state: RootState) => state.survey.filter_questions
  );
  const activeDomain = useSelector(
    (state: RootState) => state.survey.activeDomain
  );
  const sidebarDomains = useSelector(
    (state: RootState) => state.survey.sidebarDomains
  );
  useEffect(() => {
    dispatch(resetSurvey());
    dispatch(loadFilterQuestions({ uuid }));
  }, []);
  useEffect(() => {
    const stepsArray: string[] = ["0", "1", "3"];
    const first_domain = findActiveDomain(filter_questions);
    dispatch(setSidebarDomains(stepsArray));
    // if (activeDomain?.length > 0) {
    // } else {
    //   dispatch(setActiveDomain(stepsArray[first_domain]));
    // }
    if (activeDomain?.length <= 0) {
      dispatch(setActiveDomain(stepsArray[first_domain]));
    }
  }, [filter_questions]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  if (survey.survey_status === "OT") {
    history.push(`/technical/${survey.uuid}/`);
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const tempInitialValuesFormik: any = {};
  const domainFilterQuestions: FilterSurvey[] = [];
  if (activeDomain?.length > 0 && filter_questions?.length > 0) {
    if (activeDomain === "0") {
      for (let i = 0; i < 6; i++) {
        domainFilterQuestions.push(filter_questions[i]);
      }
    } else if (activeDomain === "1") {
      for (let i = 6; i < 12; i++) {
        domainFilterQuestions.push(filter_questions[i]);
      }
    } else {
      for (let i = 12; i < filter_questions.length; i++) {
        domainFilterQuestions.push(filter_questions[i]);
      }
    }
  }
  if (filter_questions?.length > 0) {
    for (let i = 0; i < filter_questions.length; i++) {
      tempInitialValuesFormik[
        `${filter_questions[i].response}-${filter_questions[i].filter_question.id}-${survey.id}`
      ] = filter_questions[i].response_explanation;
    }
  }

  const checkAllResponded = (checkFilterQuestions: FilterSurvey[]) => {
    for (let i = 0; i < checkFilterQuestions.length; i++) {
      if (checkFilterQuestions[i].response === "NR") {
        enqueueSnackbar("Please complete all question", {
          variant: "warning",
          key: checkFilterQuestions[i].filter_question.id,
        });
        return false;
      }
    }
    return true;
  };

  const checkMessageResponse = (
    checkCurrentDomainQuestions: FilterSurvey[],
    values: any
  ) => {
    for (let i = 0; i < checkCurrentDomainQuestions.length; i++) {
      if (
        (checkCurrentDomainQuestions[i].response === "PI" ||
          checkCurrentDomainQuestions[i].response === "NI") &&
        values[
          `${checkCurrentDomainQuestions[i].response}-${checkCurrentDomainQuestions[i].filter_question.id}-${survey.id}`
        ].length < 1
      ) {
        enqueueSnackbar("Please provide an answer or add missing comment", {
          variant: "warning",
        });
        return false;
      }
    }
    return true;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...tempInitialValuesFormik,
          submitType: "finish",
        }}
        onSubmit={(values: any) => {
          {
            //Check first for does response is Not responded and not parent question
            // else if parent question length > 0 filter whether question is answered
          }
          let submitQuestions = filter_questions;
          if (values.submitType === "next") {
            submitQuestions = domainFilterQuestions;
          }
          if (!checkAllResponded(submitQuestions)) {
            return;
          }
          if (!checkMessageResponse(domainFilterQuestions, values)) {
            return;
          }
          if (values.submitType === "submit") {
            dispatch(changeStatusSurveyThunk({ uuid, survey_status: "OT" }))
              .then(unwrapResult)
              .then(() => {
                if (refresh_token && refresh_token.length > 1) {
                  dispatch(loadUser({ refresh_token }))
                    .then(unwrapResult)
                    .then(() => {
                      history.push(`/technical/${uuid}/`);
                    });
                }
              });
          } else if (values.submitType === "next") {
            const activeDomainIndex = sidebarDomains.findIndex(
              (domain) => domain === activeDomain
            );
            dispatch(
              setActiveDomain(
                sidebarDomains[(activeDomainIndex + 1) % sidebarDomains.length]
              )
            );
          }
        }}
      >
        {(formik: any) => (
          <>
            <Appbar handleDrawerToggle={handleDrawerToggle} />
            <Sidebar
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />
            <FilterQuestionnaire
              CurrentPosition={{
                sidebarDomains: sidebarDomains,
                activeDomain: activeDomain,
              }}
              domainFilterQuestions={domainFilterQuestions}
              filter_questions={filter_questions}
              formik={formik}
            />
          </>
        )}
      </Formik>
    </div>
  );
};

export default FilterAssessment;
