import { server_url } from "../../types/constants";
import axios from "axios";

export async function newDocUpload(
    access_token: string,
    question_id: any,
    upload_type:any,
    file:any,
    type:any,
    id:any,
) {
    console.log(question_id,"ooooo",file)
  const url = `${server_url}/rm/upload/doc/`;
  const formdata = new FormData()
  console.log(question_id,'qqqqqqqqqqqq');
  formdata.append('question_id',question_id);
  formdata.append('upload_type',upload_type);
  formdata.append('new_document_file',file);
  formdata.append('type',type);
  formdata.append('id',id);

  for (var key of formdata.entries()) {
    console.log(key[0] + ', ' + key[1]);
}
    try {
    const response = await axios({
        method: "post",
        url: url,
        data:formdata,
        // data: {
        //     // document_file: document_file,
        //     data,
        //     question_id: question_id,
        // },
        headers: {
            Authorization: `Bearer ${access_token}`,
          },
      });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
