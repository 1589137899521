import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import SnackbarCloseButton from "./components/SnackbarCloseButton";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core";
import "@fontsource/open-sans";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

if (process.env.REACT_APP_SENTRY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
    fontFamily: [
      '"Open Sans"',
      "Roboto",
      "sans-serif",
      '"Segoe UI Symbol"',
      '"Poppins"',
    ].join(","),
  },
  overrides: {
    MuiRadio: {
      colorSecondary: {
        "&$checked": {
          color: "#1071BA",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#1071BA",
    },
    secondary: {
      main: "#FFFFFF",
    },
    background: {
      default: "#ffffff",
      paper: "#FFFFFF",
    },
  },
});

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              action={(key) => <SnackbarCloseButton key={key} />}
              maxSnack={3}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
