import { server_url } from "../../types/constants";
import axios from "axios";

export async function mulipleRestore(
  access_token: string,
  question_id : any,
) {
    console.log(question_id,"ooooo")
  const url = `${server_url}/rm/restore_all/doc/`;
  // need to display all question id data
   try {
    const response = await axios({
        method: "post",
        url: url,
        data: {
            question_id: question_id,
        },
        headers: {
            Authorization: `Bearer ${access_token}`,
          },
      });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}