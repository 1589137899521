import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";
import { ReportChildProps } from "../ReportChildProps.props";
import { ReportType } from "../../../redux/slices/reportSlice";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

interface BarChartData {
  name: string;
  Compliant: number;
  "Not-compliant": number;
}

const useStyles = makeStyles(() => ({
  boxContainer: {},
}));

const checkTotalCompliant = (
  reports: ReportType[],
  key: string,
  condition: string
) => {
  return reports.filter(
    (report) => report.domain === key && report.response === condition
  ).length;
};

const checkTotalNotCompliant = (reports: ReportType[], key: string) => {
  return (
    reports.filter(
      (report) => report.domain === key && report.response === "PI"
    ).length +
    reports.filter(
      (report) => report.domain === key && report.response === "NI"
    ).length
  );
};

const Barchart = (props: ReportChildProps) => {
  const classes = useStyles();
  const [barChartData, setBarChartData] = useState<BarChartData[]>([]);
  const { reports, domainAndQuestionCount } = props;
  const [domainAndQuestionCount1, setDomainAndQuestionCount] = useState(
    new Map<string, number>()
  );
  useEffect(() => {
    setDomainAndQuestionCount(domainAndQuestionCount);
  }, [reports]);
  useEffect(() => {
    const tempBarCharData: BarChartData[] = [];
    domainAndQuestionCount1.forEach((value, key) => {
      const compliant = checkTotalCompliant(reports, key, "FI");
      const notCompliant = checkTotalNotCompliant(reports, key);
      tempBarCharData.push({
        name: key,
        Compliant: compliant,
        "Not-compliant": notCompliant,
      });
    });
    setBarChartData(tempBarCharData);
  }, [domainAndQuestionCount1]);

  return (
    <>
      {barChartData && barChartData.length > 0 && (
        <ResponsiveContainer
          width="90%"
          height="99.5%"
          className={classes.boxContainer}
        >
          <BarChart data={barChartData} layout="vertical">
            <YAxis
              type="category"
              dataKey="name"
              style={{
                fontFamily: "Open-Sans",
                fontWeight: "bold",
              }}
              width={150}
            />
            <XAxis type="number" allowDecimals={false} />
            <Tooltip cursor={{ fill: "#f1f3ed" }} />
            <Legend />
            <Bar dataKey="Compliant" fill="#1071BA" radius={[0, 5, 5, 0]}/>
            <Bar dataKey="Not-compliant" fill="#FF0000	" radius={[0, 5, 5, 0]}/>
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};
export default Barchart;
