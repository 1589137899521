import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

const MatrixSelectModal = ({ selectMatrix, setSelectMatrix }: any) => {

  const history = useHistory();

  const handleSettings = () => {
    history.push("/settings/");
  };

  const handleYes = () => {
    setSelectMatrix(false);
  };

  return (
    <>
      <Dialog
        open={selectMatrix}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "We applied default risk matrix. You can change it in the System settings. Do you want to use default risk matrix?"
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleYes} color="primary">
            Yes
          </Button>
          <Button onClick={handleSettings} color="primary" autoFocus>
            Settings
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MatrixSelectModal;
