import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addRiskProject } from "../../api/risk/addRiskProject";

export const newRiskProjectAdd = createAsyncThunk(
  "risk/addRiskProject",
  async ({ name, access_token }: any, thunkAPI) => {
    try {
      return await addRiskProject(name, access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateRiskProject = {
  id: number;
};

const initialState: initialStateRiskProject = {
  id: 0,
};

const RiskProjectCreate = createSlice({
  name: "addRiskProject",
  initialState,
  reducers: {
    resetAddRiskProject: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newRiskProjectAdd.fulfilled, (state, { payload }) => {
      state.id = payload.id;
    });
  },
});

export const { resetAddRiskProject } = RiskProjectCreate.actions;

export default RiskProjectCreate.reducer;
