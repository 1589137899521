import React, { useEffect } from "react";
import { Route, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import Loading from "../layout/Loading";
import { useAppDispatch } from "../redux/store";
import {
  resetUser,
  verifyOneTimeRegistrationToken,
} from "../redux/slices/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";

export function getRegistrationTokenFromLocation(pathName: string) {
  const firstRange = pathName.indexOf("/");
  const secondRange = pathName.indexOf("/", firstRange + 1);
  return pathName.slice(firstRange + 1, secondRange);
}

const VerifyLink = ({ component: Component, ...rest }: any) => {
  const dispatch = useAppDispatch();
  const isPending = useSelector((state: RootState) => state.user.isPending);
  const isAllowedToRegister = useSelector(
    (state: RootState) => state.user.isAllowedRegister
  );
  const location = useLocation();
  const registration_token = getRegistrationTokenFromLocation(
    location.pathname
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(verifyOneTimeRegistrationToken({ registration_token }))
      .then(unwrapResult)
      .then(() => {})
      .catch(() => {
        dispatch(resetUser());
        history.push("/signin/");
      });
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isPending) {
          return <Loading />;
        } else if (isAllowedToRegister) {
          return <Component {...props} />;
        }
      }}
    />
  );
};
export default VerifyLink;
