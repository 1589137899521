import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getProjectList } from "../../api/risk/projectList";

export const projectListFetch = createAsyncThunk(
  "risk/projectList",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getProjectList(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface projectList {
  id: number;
  name: string;
}

export type initialStateProjectList = {
  allprojectlist: Array<projectList>;
};

const initialState: initialStateProjectList = {
  allprojectlist: [],
};

const projectList = createSlice({
  name: "projectList",
  initialState,
  reducers: {
    resetProjectList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(projectListFetch.fulfilled, (state, { payload }) => {
      state.allprojectlist = [];
      // for (let i = 0; i < payload.length; i++) {
      //   state.allprojectlist.push({
      //     id: payload[i].id,
      //     name: payload[i].name,
      //   });
      // }
      payload.forEach((value: any) => {
        state.allprojectlist.push({
          id: value.id,
          name: value.name,
        });
      });
    });
  },
});

export const { resetProjectList } = projectList.actions;

export default projectList.reducer;
