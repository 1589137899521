import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 656,
  },
  heading: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: 38,
    marginLeft: 32,
  },
  text: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 13,
    marginLeft: 32,
  },
  btnLight: {
    color: "#1071BA",
    background: "#fff",
    height: 42,
    width: 150,
    borderRadius: 8,
    textTransform: "none",
    border: "1px solid #1071BA",
  },
  btnDark: {
    color: "#fff",
    background: "#1071BA",
    height: 42,
    width: 150,
    borderRadius: 8,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
  },
}));

const CoreAssessmentModal = ({
  openModal,
  setOpenModal,
  setResetSurvey,
  assessmentType,
}: any) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleYes = () => {
    setResetSurvey("true");
    handleClose();
  };

  const handleNo = () => {
    setResetSurvey("false");
    handleClose();
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <div className={classes.modalContainer}>
          <Typography className={classes.heading}>
            Do you want to update or reset the {assessmentType} assessment
            results?
          </Typography>
          <Typography className={classes.text}>
            If you reset, changes made will not be saved.
          </Typography>
          <DialogActions
            style={{ paddingRight: 35, paddingBottom: 20, marginTop: 60 }}
          >
            <Button className={classes.btnDark} onClick={handleNo}>
              Update
            </Button>
            <Button className={classes.btnLight} onClick={handleYes} autoFocus>
              Reset
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default CoreAssessmentModal;
