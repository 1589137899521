import { server_url } from "../../types/constants";
import axios from "axios";

export async function riskResponsePlan(access_token: string)
{
  const url = `${server_url}/rm/response_plan/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}