import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRiskWithoutAssessment } from "../../api/dashboard/riskWithoutAssessment";
export const riskWithoutAssessment = createAsyncThunk(
  "/rmDropdown",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await getRiskWithoutAssessment(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateStatement = {
  riskWithoutAssessmentInfo: Array<string>
};

const initialState: initialStateStatement = {
    riskWithoutAssessmentInfo: []
};

const get_riskWithouAssessmentData = createSlice(
{
name:"riskWithoutAssessmentSlice",
initialState,
reducers: {
  riskwithouAssessmentData: () => initialState,
},
extraReducers: (builder) => {
  builder.addCase(riskWithoutAssessment.fulfilled, (state, { payload }) => {
    state.riskWithoutAssessmentInfo=[];
     payload?.forEach((value:any) => {
       state?.riskWithoutAssessmentInfo?.push(value);
       });
});
},
});

export const {riskwithouAssessmentData} = get_riskWithouAssessmentData.actions;

export default get_riskWithouAssessmentData.reducer;