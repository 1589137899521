import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPlans } from "../../api/plans/getPlans";

export const getAllPlansRequest = createAsyncThunk(
  "/allPlans",
  async ({ }: any, thunkAPI) => {
    try {
      return await getAllPlans();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateAllPlans = {
    allPlans: Array<any>;
};

const initialState: initialStateAllPlans = {
  allPlans: [],
};

const getAllPlansData = createSlice({
  name: "allPlans",
  initialState,
  reducers: {
    resetAllPlans: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPlansRequest.fulfilled, (state, { payload }) => {
        state.allPlans = payload;
    });
  },
});

export const { resetAllPlans } = getAllPlansData.actions;

export default getAllPlansData.reducer;