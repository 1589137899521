import { combineReducers } from "redux";
import UserSlice from "./slices/userSlice";
import SurveySlice from "./slices/surveySlice";
import ReportSlice from "./slices/reportSlice";
import UsersListSlice from "./slices/usersListSlice";
import usersUpdateInfo from "./slices/updateUserInfo";
import createToken from "./slices/createTokenSlice";
import addNewUser from "./slices/addUserSlice";
import updatemyProfile from "./slices/updateProfileSlice";
import changeUserPassword from "./slices/changePasswordSlice";
import riskListSlice from "./slices/riskSlice";
import addNewRisk from "./slices/addRiskSlice";
import editRisk from "./slices/editRiskSlice";
import riskGroup from "./slices/riskGroupSlice";
import projectList from "./slices/projectListSlice";
import addRiskGroup from "./slices/addRiskGroupSlice";
import addRiskProject from "./slices/addRiskProjectSlice";
import controlListSlice from "./slices/controlListSlice";
import filterControlListSlice from "./slices/filterControlListSlice";
import addNewControl from "./slices/addControlSlice";
import editControl from "./slices/editControlSlice";
import emailSubmit from "./slices/emailSubmitSlice";
import newPassword from "./slices/newPasswordSlice";
import riskMatrixSet from "./slices/riskMatrixSetSlice";
import riskMatrixGet from "./slices/riskMatrixGetSlice";
import allRiskMatrixGet from "./slices/allRiskMatrixSlice";
import oneTimeResetPassword from "./slices/oneTimeResetPasswordSlice";
import forgotPasswordLinkVerify from "./slices/forgotPasswordValidSlice";
import newRiskAddedStatus from "./slices/getRiskAddedSlice";
import setRiskAddedStatus from "./slices/setRiskAddedSlice";
import deviceDetailsSlice from "./slices/devicePlatformInfoSlice";
import isoControlsSlice from "./slices/isoControlsSlice";
import statementData from "./slices/getStatementSlice";
import allDomains from "./slices/getAllDomainsSlice";
import getSoaUsersSlice from "./slices/getSoaUserSlice";
import updateSoaContentSlice from "./slices/updateSoaContent";
import docListSlice from "./slices/getDocumentManagementSlice";
import idStore from "./slices/subMenuNoSlice";
import docDelete from "./slices/documentDeleteSlice";
import reducerRestore from "./slices/restoreRowSlice";
import reducerMultipleRestore from "./slices/multipleRestoreSlice"; 
import docDownload from "./slices/downloadDocSlice";
import docFilter from "./slices/filterDocumentSlice";
import reducerStatus from "./slices/statusUpdateSlice";
import reducerMultipleDownload from "./slices/downloadSelDocSlice";
import reducerUpload from "./slices/uploadFileSlice";
import reducerName from "./slices/nameUpdateSlice";
import uploadListSlice from "./slices/getUploadDocSlice";
import uploadBtnListSlice from "./slices/getUploadBtnSlice";
import newfilereducer from "./slices/newDocUploadSlice";
import securityControlData from "./slices/securityControlDashboard";
import securityGraphSlice from "./slices/securityControlGraphData";
import riskResponseTableSlice from "./slices/riskResponseDashboard";

import riskResponseGraphSlice from "./slices/riskResponseGraphSlice";
import dmDashboardTableSlice from "./slices/dmDashboardSlice";
import dmGraphSlice from "./slices/dmGraphSlice";
import docToWorkSlice from "./slices/docToWorkSlice";
import rmDashboardTableSlice from "./slices/rmDashboardTableSlice";
import riskWithoutAssessmentSlice from "./slices/rmDashboardDropdownSlice";
import riskToReviewSlice from "./slices/riskToReviewSlice";
import riskManagementGraphSlice from "./slices/riskManagementGraphSlice";
import allPlans from "./slices/getPlans";
import subscription from "./slices/subscriptionDetails";

const rootReducer = combineReducers({
  user: UserSlice,
  survey: SurveySlice,
  report: ReportSlice,
  usersList: UsersListSlice,
  updateUserInfo: usersUpdateInfo,
  createToken: createToken,
  addNewUser: addNewUser,
  updatemyProfile: updatemyProfile,
  updateSoaContent:updateSoaContentSlice,
  changeUserPassword: changeUserPassword,
  riskList: riskListSlice,
  addNewRisk: addNewRisk,
  editRisk: editRisk,
  riskGroup: riskGroup,
  projectList: projectList,
  addRiskGroup: addRiskGroup,
  addRiskProject: addRiskProject,
  controlListSlice: controlListSlice,
  filteredControlData:filterControlListSlice,
  addNewControl: addNewControl,
  editControl: editControl,
  emailSubmit: emailSubmit,
  newPassword: newPassword,
  riskMatrixSet:riskMatrixSet,
  riskMatrixGet:riskMatrixGet,
  allRiskMatrixGet:allRiskMatrixGet,
  oneTimeResetPassword:oneTimeResetPassword,
  forgotPasswordLinkVerify:forgotPasswordLinkVerify,
  newRiskAddedStatus:newRiskAddedStatus,
  setRiskAddedStatus:setRiskAddedStatus,
  deviceDetails:deviceDetailsSlice,
  isoControls: isoControlsSlice,
  statementData:statementData,
  allDomains:allDomains,
  SoaUserList:getSoaUsersSlice,
  documentlist:docListSlice,
  submenulist: idStore,
  deleteStatus:docDelete,
  restoreonerow:reducerRestore,
  restoremultiplerow:reducerMultipleRestore,
  documentdownload:docDownload ,
  documentfilter:docFilter,
  statusupdate:reducerStatus,
  multipledownload:reducerMultipleDownload,
  documentupload:reducerUpload,
  nameupdate:reducerName,
  uploadlist:uploadListSlice,
  uploadbuttonlist:uploadBtnListSlice,
  newfileupload:newfilereducer,
  securityControlDashboardData:securityControlData,
  securityControlGraphData: securityGraphSlice,
  rrDashboardTableData:riskResponseTableSlice,
  riskResponseGraphData:riskResponseGraphSlice,
  dmDashboardTableData:dmDashboardTableSlice,
  dmGraphdata:dmGraphSlice,
  docToWorkData:docToWorkSlice,
  rmDashboardTableData:rmDashboardTableSlice,
  riskWithoutAssessmentData:riskWithoutAssessmentSlice,
  riskToReviewData:riskToReviewSlice,
  riskManagementGraphData:riskManagementGraphSlice,
  allPlans: allPlans,
  subscription: subscription,
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
