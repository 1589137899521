import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {securityControlGraph} from "../../api/dashboard/securityControlGraph"; 
import { riskResponseGraph } from "../../api/dashboard/riskResponseGraph";
import {dmGraph} from "../../api/dashboard/dmGraph";
export const dmGraphData = createAsyncThunk(
  "/dmGraph",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await dmGraph(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface graphValue {
    days: number,
    allDocuments: number,
    publishedDocuments: number,
}

export type initialStateDocument = {
    graphData: Array<graphValue>;
  };

  const initialState: initialStateDocument = {
    graphData: [],
  };

  const getGraphData = createSlice({
    name: "dmGraphSlice",
    initialState,
    reducers: {
      graphDataa: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(dmGraphData.fulfilled, (state, { payload }) => {
            state.graphData = [];
            payload?.forEach((value:any,index:any) => {
               state?.graphData?.push(
                   {
                       days:value.days,
                       allDocuments:value.allDocuments,
                       publishedDocuments:value.publishedDocuments,
                   }
               );

            });
        });
    },
});
export const {graphDataa } = getGraphData.actions;
export default getGraphData.reducer;