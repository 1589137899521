import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

const useStyles = makeStyles((theme) => ({
  matrixTable: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    marginTop: 100,
    marginBottom: 118,
    width: 1280,
  },
  tableHead: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
  },
  tableCell: {
    border: "1px solid #DEDEDE",
    borderCollapse: "collapse",
    width: 320,
    verticalAlign: "top",
    textAlign: "left",
    paddingRight: 35,
    paddingLeft: 35,
    paddingTop: 18,
    paddingBottom: 18,
  },
  verticalBorderNone: {
    borderBottom: "none",
    fontWeight: "bold",
    padding: 10,
  },
  topCorner: {
    borderBottom: "1px solid #DEDEDE",
  },
  tableHeadCell: {
    padding: 10,
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const Matrix5Description = ({ matrix5_5 }: any) => {
  const classes = useStyles();
  const [matrix, setMatrix] = useState(matrix5_5);

  const riskMatrix5_5 = useSelector(
    (state: RootState) => state.allRiskMatrixGet.json_5_5
  );

  useEffect(() => {
    setMatrix(riskMatrix5_5);
  }, [riskMatrix5_5]);

  const [likelihoodOne] = matrix.filter(
    (val: any) => val.Likelihood_key === "1"
  );
  const [likelihoodTwo] = matrix.filter(
    (val: any) => val.Likelihood_key === "2"
  );
  const [likelihoodThree] = matrix.filter(
    (val: any) => val.Likelihood_key === "3"
  );
  const [likelihoodFour] = matrix.filter(
    (val: any) => val.Likelihood_key === "4"
  );
  const [likelihoodFive] = matrix.filter(
    (val: any) => val.Likelihood_key === "5"
  );
  const [impactOne] = matrix.filter((val: any) => val.impact_key === "1");
  const [impactTwo] = matrix.filter((val: any) => val.impact_key === "2");
  const [impactThree] = matrix.filter((val: any) => val.impact_key === "3");
  const [impactFour] = matrix.filter((val: any) => val.impact_key === "4");
  const [impactFive] = matrix.filter((val: any) => val.impact_key === "5");

  return (
    <>
      <table className={classes.matrixTable}>
        <tr>
          <th className={classes.tableCell}>
            <Typography>
              <b>Impact</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Description</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Likelihood</b>
            </Typography>
          </th>
          <th className={classes.tableCell}>
            <Typography>
              <b>Description</b>
            </Typography>
          </th>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactOne?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has no or low impact on the organization's processes.
              No human injury or injury that requires insignificant first aid.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodOne?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat never materialized in the organization, while the
              organization has a vulnerability that can materialize the threat.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactTwo?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a non-material financial and operational impact on
              the organization’s processes. Human suffers a minor injury that
              requires medical treatment.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodTwo?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized a few times in the past 36 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactThree?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a material financial and operational impact on the
              organization’s processes. A human suffers severe injuries and
              requires several medical treatments.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodThree?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized several times in the past 24 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactFour?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a severe financial and operational impact on the
              organization's processes, which cannot be recovered quickly. Loss
              of human life or serious injuries to a group of people.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodFour?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized a few times in the past 12 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
        <tr>
          <td className={classes.tableCell}>
            <Typography>{impactFive?.impact}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat has a critical impact on the organization's process,
              putting the organization's future at risk. Loss of several people
              or severe injuries to a group of people.
            </Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>{likelihoodFive?.Likelihood}</Typography>
          </td>
          <td className={classes.tableCell}>
            <Typography>
              The threat materialized several times in the past 12 months. The
              organization has several vulnerabilities that can materialize the
              threat.
            </Typography>
          </td>
        </tr>
      </table>
    </>
  );
};

export default Matrix5Description;
