import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { changePassword } from "../../api/auth/changePassword";

export const userPasswordChange = createAsyncThunk(
  "users/changePassword",
  async ({ email, password, refresh_token }: any, thunkAPI) => {
    try {
      return await changePassword(email, password, refresh_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  isTemporary: boolean;
};

const initialState: initialStateUsersList = {
  isTemporary: true,
};

const userChangePassword = createSlice({
  name: "changeUserPassword",
  initialState,
  reducers: {
    passwordChange: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userPasswordChange.fulfilled, (state, { payload }) => {
      state.isTemporary = payload.isTemporary;
    });
  },
});

export const { passwordChange } = userChangePassword.actions;

export default userChangePassword.reducer;
