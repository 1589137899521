import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import WhiteSidebarLogo from "../Core Assessment/Sidebar/WhiteSidebarLogo";
import mandatoryIcon from "../../assets/mandatoryIcon.svg";
import { useAppDispatch } from "../../redux/store";
import { documentStatus } from "../../redux/slices/getDocumentManagementSlice";
import { getData } from "../../redux/slices/subMenuNoSlice";
import HeaderProfile from "../../layout/HeaderProfile";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import PageDetail from "./PageDetail";
import {toast} from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import { Typography } from "@material-ui/core";
import dashboardWhite from "../../assets/dashboardWhite.svg";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
const drawerWidth = 303;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  settingsDiv: {
    display: "flex",
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1071BA",
    "&::-webkit-scrollbar": { display: "inherit" },
    overflowY:'clip',
  },

  optionList: {
    backgroundColor: "#1071BA",
    fontFamily: "Poppins, sans-serif !important",

    "&:hover": {
      cursor: "pointer",
    },
    paddingLeft: "48px !important",
  },
  optionListSelected: {
    backgroundColor: "white !important",
    fontFamily: " Poppins, sans-serif !important",
    color: "#1071BA !important",
    "&:hover": {
      cursor: "pointer",
    },
    paddingLeft: "48px !important",
  },
  rightSide: {
    width: "100%",
  },
  content: {
    margin: "10px",
  },
  listText: {
    marginLeft: "11px",
    fontFamily: "Poppins, sans-serif !important",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px !important",
    lineHeight: "137%",
    "& .MuiTypography-root": {
      fontSize: "18px !important",
      fontFamily: "Poppins, sans-serif !important",
    },
    "& .css-10hburv-MuiTypography-root": {
      fontFamily: "Poppins, sans-serif !important",
      fontWeight: 400,
      fontSize: "16px !important",
    }
  },
  collapsedList: {
    marginLeft: "11px",
    fontFamily: "Poppins, sans-serif !importatnt",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px !important",
    lineHeight: "137%",
    "& .css-10hburv-MuiTypography-root" : {
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "16px !important",
      // font family updated
    }
  },
  docTitle : {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: "137%",
    color: "#FFFFFF",
    marginLeft: "49px",
    marginBottom: "3px",
  },
  dashboardTextContainer : {
    display: "flex",
    marginBottom:"11px",
    padding:"8px",
    cursor:"pointer",
  },
  dashboardText: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color:"white",
    paddingLeft:"16px",
  },
  iconcontainer :{
    marginLeft: "32px",
  },
  listBtn:{
    paddingLeft:"14% !important",
  }
}));
const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  // collapsedList:{
  //   fontFamily: "Poppins, sans-serif",
  // },
});



  const DocumentManagementFeatures = () => {
  const {isDocToWork} = useParams<any>();
  const [open, setOpen] = useState(true);
  const [openOptional,setOptional] = useState(false);
  const handleClick = () => {
    openOptional?setOptional(!openOptional): setOptional(openOptional)
    setOpen(!open);
    
  };
  const handleClickOptional = () => {
    open?setOpen(!open):setOpen(open)
      setOptional(!openOptional);    
  }
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [sideBarIndex, setSideBarIndex] = useState(0);
  const [selectedDomain, setSelectedDomain] = useState("");
  const token = localStorage.getItem("Access_Token");
  // console.log(token, "aaaaaaaaaaaaaaas");
  const [docCredential, setDocCredential] = useState({
    access_token: token,
    applicability: "Mandatory",
    type: "Policies",
    docToWork: isDocToWork,
  });
    const handleOption = (key: string, id: number, applicability: any) => {
    setDocCredential({ ...docCredential, applicability: applicability, type: key });
    setSideBarIndex(id);
    id ===13 ? dispatch(getData(6)):dispatch(getData(id));
    // dispatch(getData(id));     // get data is for create a global store functionality same as seSidebarinedx
  };
  if (docCredential.type.length > 2) {    
    dispatch(documentStatus({ ...docCredential }));
  }
  const history = useHistory(); 
  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <HeaderProfile/>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <WhiteSidebarLogo />
            {/* <div className={classes.docTitle}>
            Documents
            </div> */}
            <div style={{ marginTop: "20px" }}>
            <div className={classes.dashboardTextContainer} onClick={()=> history.push("/")}>
            {/* <img src={dashboardWhite} alt="dashboard icon"/> */}
             <Typography className={classes.dashboardText}>
                    Dashboard 
                      </Typography>
              </div>
            <div className={classes.docTitle}>
            Documents
            </div>
              <List
                sx={{ width: "100%", bgcolor: "#1071BA", color: "white" }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton onClick={handleClick}
                className={classes.listBtn}>
                 {/* onClick={(e)=>{openOptional? toast.warning('please select file first'):handleClick()}} */}
               <img src={mandatoryIcon} alt="mandatoryicon"></img>
                  <ListItemText
                    primary="Mandatory"
                    className={classes.listText}
                  />
           
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 0
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Policies", 0, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Policies</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 1
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Procedures", 1, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Procedures</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 2
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Standards", 2, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Standards</Typography>}

                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 3
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Guidelines", 3, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Guidelines</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 4
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Meeting", 4, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Meeting minutes</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 5
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Others", 5, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Others</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 6
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Deleted", 6, "Mandatory")}
                    >
                      <ListItemText
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Deleted</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
                <ListItemButton onClick={handleClickOptional} className={classes.listBtn}>
                {/* {open?toast.warning('please select file first'):handleClickOptional()} */}
                <img src={mandatoryIcon} alt="mandatoryicon"></img>
                  <ListItemText
                    primary="Optional"
                    className={classes.listText}
                  />
                </ListItemButton>
                 {/* optional collapse is starting from here  */}
                 <Collapse in={openOptional} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 7
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Policies", 7, "Optional")}
                    >
                      <ListItemText
                       
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Policies</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 8
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Procedures", 8, "Optional")}
                    >
                      <ListItemText
                        
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Procedures</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 9
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Standards", 9, "Optional")}
                    >
                      <ListItemText
                        
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Standards</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 10
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Guidelines", 10, "Optional")}
                    >
                      <ListItemText
                        
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Guidelines</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 11
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Meeting", 11, "Optional")}
                    >
                      <ListItemText
                       
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Meeting minutes</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 12
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Others", 12, "Optional")}
                    >
                      <ListItemText
                        
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Others</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sideBarIndex === 13
                          ? classes.optionListSelected
                          : classes.optionList
                      }
                      onClick={(e) => handleOption("Deleted", 13, "Optional")}
                    >
                      <ListItemText
                        
                        disableTypography
                        primary={<Typography style={{ fontFamily: "Poppins, sans-serif", }}>Deleted</Typography>}
                        className={classes.collapsedList}
                      />
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </div>
          </Drawer>
          <div style={{ marginLeft: "299px" }}>
            <PageDetail docupdate={docCredential} isDocToWork={isDocToWork}/>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};
export default DocumentManagementFeatures;
