import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { securityControl } from "../../api/dashboard/securityControl";
export const securityControlDashboard = createAsyncThunk(
  "/dashboard",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await securityControl(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

 export interface notImplemented {
  Domain:string,
  Count:number,
 }
 export interface implemented {
  Domain:string,
  Count:number,
 }

export type initialStateStatement = {
  Core_NI: number,
  NI: Array<notImplemented>,
  Core_PI: number,
  PI: Array<implemented>,
  Tech_NI: number,
  Tech_PI:number,
};

const initialState: initialStateStatement = {
  Core_NI: -1,
  NI: [],
  Core_PI: -1,
  PI: [],
  Tech_NI: -1,
  Tech_PI:-1,
};

const get_securityControlData = createSlice(
{
name:"securityControlData",
initialState,
reducers: {
  resetSecurityControlData: () => initialState,
},
extraReducers: (builder) => {
  builder.addCase(securityControlDashboard.fulfilled, (state, { payload }) => {
     state.NI =[];
     state.PI=[];
     state.Core_NI = payload.Core_NI;
     payload.NI.forEach((value:any) => {
       state?.NI?.push( {
        Domain: value.Domain,
        Count: value.Count,
       });
       
     });
     state.Core_PI = payload.Core_PI;
     payload.PI.forEach((value:any) => {
      state?.PI?.push( {
       Domain: value.Domain,
       Count: value.Count,
      });
     
    });
    state.Tech_NI = payload.Tech_NI;
    state.Tech_PI = payload.Tech_PI;
});
},
});

export const {resetSecurityControlData} = get_securityControlData.actions;

export default get_securityControlData.reducer;