import React from "react";
import { Form, useFormikContext } from "formik";
import {
  FilterSurvey,
  setActiveDomain,
  submitFilterResponseThunk,
} from "../../../redux/slices/surveySlice";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { FilterQuestionnaireProps } from "./FilterQuestionnaire.props";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducer";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/store";
import clsx from "clsx";
import { questionnaireUseStyles } from "../../Core Assessment/Questionnaire/Questionnaire";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";
import Typography from "@material-ui/core/Typography";
import parse from "html-react-parser";
import { ParamTypes } from "../../../types/survey";
import sanitizeHtml from "sanitize-html";

const FilterQuestionnaire = (props: FilterQuestionnaireProps) => {
  const { activeDomain, sidebarDomains } = props.CurrentPosition;
  const { domainFilterQuestions } = props;
  const { filter_questions } = props;
  const { formik } = props;
  const classes = questionnaireUseStyles();
  const survey = useSelector((state: RootState) => state.survey.survey);
  const dispatch = useAppDispatch();
  const { uuid } = useParams<ParamTypes>();
  const { setFieldValue, submitForm } = useFormikContext();
  const { values }: any = useFormikContext();

  const handlePreviousPage = () => {
    let activeDomainIndex = sidebarDomains.findIndex(
      (domain) => domain === activeDomain
    );
    if (activeDomainIndex === 0) {
      activeDomainIndex = sidebarDomains.length;
    }
    dispatch(
      setActiveDomain(
        sidebarDomains[(activeDomainIndex - 1) % sidebarDomains.length]
      )
    );
  };
  const handleNextPage = async () => {
    setFieldValue("submitType", "next", false);
    submitForm();
  };

  const handleSubmit = () => {
    setFieldValue("submitType", "submit", false);
    submitForm();
  };

  function handleOnClick(event: any) {
    if (event?.target?.value) {
      const separatedResponse: string[] = event.target.value.split("-");
      //format -`NI-${surveyQuestion.question.id}-${survey.id}`
      dispatch(
        submitFilterResponseThunk({
          response: separatedResponse[0],
          id_filter: separatedResponse[1],
          id_survey: separatedResponse[2],
          uuid,
        })
      );
    }
  }

  const handleOnBlur = (event: any) => {
    if (event?.target?.id) {
      const response_explanation = values[event.target.id];
      const separatedResponse: string[] = event.target.id.split("-");
      // format -`NI-${surveyQuestion.question.id}-${survey.id}`
      if (response_explanation.length > 0) {
        dispatch(
          submitFilterResponseThunk({
            response: separatedResponse[0],
            id_filter: separatedResponse[1],
            id_survey: separatedResponse[2],
            uuid,
            response_explanation,
          })
        );
      } else {
        dispatch(
          submitFilterResponseThunk({
            response: separatedResponse[0],
            id_filter: separatedResponse[1],
            id_survey: separatedResponse[2],
            uuid,
          })
        );
      }
    }
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.paper}>
        <Form className={classes.form}>
          <Container maxWidth="md" className={classes.formContainer}>
            {domainFilterQuestions.map(
              (singleFilterSurvey: FilterSurvey, index) => {
                {
                  // here we check for parent question, iterating parent question and
                  // if parent question response is not FI return true
                }
                return (
                  <div
                    key={`div-${singleFilterSurvey.filter_question.id}${survey.id}${index}`}
                    className={`${classes.questionContainer} ${
                      singleFilterSurvey.response === "NR"
                        ? `${classes.notRespondedQuestionContainer}`
                        : ""
                    }`}
                  >
                    <Grid container direction="row" alignItems="flex-start">
                      <Grid item xs={1} className={classes.arrowGrid}>
                        <ChevronRightOutlinedIcon />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography variant="body1">
                          {parse(
                            sanitizeHtml(
                              singleFilterSurvey.filter_question.text
                            )
                          )}
                        </Typography>
                        <RadioGroup
                          key={`${singleFilterSurvey.filter_question.id}${survey.id}${index}group`}
                          value={`${singleFilterSurvey.response}-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                          row
                          className={clsx(classes.radioGroup)}
                        >
                          <FormControlLabel
                            value={`FI-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                            key={`FI-${singleFilterSurvey.filter_question.id}${survey.id}${index}label1`}
                            control={<Radio size="small" />}
                            label="Fully Implemented"
                            labelPlacement="end"
                            onClick={handleOnClick}
                            classes={{
                              label: classes.radioLabel,
                            }}
                          />
                          <FormControlLabel
                            value={`PI-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                            key={`PI-${singleFilterSurvey.filter_question.id}${survey.id}${index}label2`}
                            control={<Radio size="small" />}
                            label="Partially Implemented"
                            labelPlacement="end"
                            onClick={handleOnClick}
                            classes={{
                              label: classes.radioLabel,
                            }}
                          />
                          <FormControlLabel
                            value={`NI-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                            key={`NI-${singleFilterSurvey.filter_question.id}${survey.id}${index}label3`}
                            control={<Radio size="small" />}
                            label="Not Implemented"
                            labelPlacement="end"
                            onClick={handleOnClick}
                            classes={{
                              label: classes.radioLabel,
                            }}
                          />
                          <FormControlLabel
                            value={`NA-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                            key={`NA-${singleFilterSurvey.filter_question.id}${survey.id}${index}label3`}
                            control={<Radio size="small" />}
                            label="Not Applicable"
                            labelPlacement="end"
                            onClick={handleOnClick}
                            classes={{
                              label: classes.radioLabel,
                            }}
                          /> 
                        </RadioGroup>
                        {singleFilterSurvey.response === "NI" ||
                        singleFilterSurvey.response === "PI" ? (
                          <>
                            <TextField
                              id={`${singleFilterSurvey.response}-${singleFilterSurvey.filter_question.id}-${survey.id}`}
                              key={`${singleFilterSurvey.filter_question.id}${survey.id}`}
                              label={`Please describe the reason why you have answered ${
                                singleFilterSurvey.response === "PI"
                                  ? "Partially Implemented"
                                  : "Not Implemented"
                              }`}
                              variant="outlined"
                              className={clsx(
                                classes.partiallyNoResponseTextField
                              )}
                              fullWidth
                              required
                              multiline
                              rows={3}
                              rowsMax={5}
                              value={
                                formik.values[
                                  `${singleFilterSurvey.response}-${singleFilterSurvey.filter_question.id}-${survey.id}`
                                ]
                              }
                              onChange={formik.handleChange}
                              onBlur={handleOnBlur}
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                );
              }
            )}
          </Container>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="contained"
              className={clsx(classes.button)}
              onClick={handlePreviousPage}
              disabled={
                filter_questions &&
                filter_questions.length > 1 &&
                activeDomain === "0"
              }
            >
              Previous
            </Button>

            {filter_questions &&
            filter_questions.length > 1 &&
            activeDomain !== sidebarDomains[sidebarDomains.length - 1] ? (
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.nextButton, classes.button)}
                onClick={handleNextPage}
              >
                Next
              </Button>
            ) : (
              <></>
            )}
            {filter_questions &&
            filter_questions.length > 1 &&
            activeDomain === sidebarDomains[sidebarDomains.length - 1] ? (
              <Button
                color="primary"
                variant="contained"
                className={clsx(classes.nextButton, classes.button)}
                onClick={handleSubmit}
              >
                Finish
              </Button>
            ) : (
              <></>
            )}
          </div>
        </Form>
      </div>
    </main>
  );
};

export default FilterQuestionnaire;
