import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import Loading from "../layout/Loading";

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  // const isAuthenticated: boolean = useSelector(
  //   (state: RootState) => state.user.isAuthenticated,
  // );

  const isAuthenticated = localStorage.getItem("Access_Token");
  const isPending = useSelector((state: RootState) => state.user.isPending);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isPending) {
          return <Loading />;
        } else if (!isAuthenticated) {
          return <Redirect to="/signin" />;
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};
export default PrivateRoute;
