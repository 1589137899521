import { server_url } from "../../types/constants";
import axios from "axios";

export async function createAssessmentApi(
  access_token: string,
  category_name: string
) {
  const url = `${server_url}/survey/`;
  try {
    const response = await axios.post(
      url,
      {
        category_name: category_name,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
