import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {rmGraph } from "../../api/dashboard/rmGraph";
export const rmGraphDataFetch = createAsyncThunk(
  "/riskManagementGraph",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await rmGraph(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface graphValue {
    days: number,
    Identified: number,
    Assessed: number,
}

export type initialStateDocument = {
    graphData: Array<graphValue>;
  };

  const initialState: initialStateDocument = {
    graphData: [],
  };

  const getGraphData = createSlice({
    name: "riskManagementGraphSlice",
    initialState,
    reducers: {
      graphDataa: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(rmGraphDataFetch.fulfilled, (state, { payload }) => {
            state.graphData = [];
            payload?.forEach((value:any,index:any) => {
               state?.graphData?.push(
                   {
                       days:value.days,
                       Identified:value.Identified,
                       Assessed:value.Assessed
                   }
               );

            });
        });
    },
});
export const {graphDataa } = getGraphData.actions;
export default getGraphData.reducer;