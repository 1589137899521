import { server_url } from "../../types/constants";
import axios from "axios";

export async function verifyOneTimeRegistrationTokenApi(
  registration_token: string
) {
  const url = `${server_url}/authentication/verify/${registration_token}/`;
  try {
    return await axios.get(url);
  } catch (err) {
    throw err.response;
  }
}
