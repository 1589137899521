import { server_url } from "../../types/constants";
import axios from "axios";

export async function getIsoControls(access_token: string) {
  const url = `${server_url}/rm/get/isocontrol/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
