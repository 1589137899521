import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { riskResponsePlan } from "../../api/dashboard/riskResponsePlan";
export const riskResponseDashboard = createAsyncThunk(
  "/riskResponseDashboard",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await riskResponsePlan(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface tableDataType {
    control_name: string,
    due_date: string,
    Status: string,
}

export type initialStateDocument = {
    tableData: Array<tableDataType>;
  };

  const initialState: initialStateDocument = {
    tableData: [],
  };

  const getResponseTable = createSlice({
    name: "riskResponseTableSlice",
    initialState,
    reducers: {
      graphDataa: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(riskResponseDashboard.fulfilled, (state, { payload }) => {
            state.tableData = [];
            payload?.forEach((value:any,index:any) => {
               state?.tableData?.push(
                   {
                    control_name:value.control_name,
                    due_date:value.due_date,
                    Status:value.Status
                   }
               );

            });
        });
    },
});
export const {graphDataa } = getResponseTable.actions;
export default getResponseTable.reducer;