import React from "react";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { setActiveDomain } from "../../../redux/slices/surveySlice";
import { SidebarProps } from "./Sidebar.props";
import { useAppDispatch } from "../../../redux/store";
import { useFormikContext } from "formik";
import { Avatar } from "@material-ui/core";
import WhiteSidebarLogo from "./WhiteSidebarLogo";
import clsx from "clsx";
import dashboardWhite from "../../../assets/dashboardWhite.svg";
import { bgcolor } from "@mui/system";
import { useHistory } from "react-router-dom";

export const drawerWidth = 297;
                                                              // in this page only two changes made for dashboard page that is 1.drawerwidth 440 to 297 and dashboardbtn added
export const sidebarUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      borderRight: 0,
      boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.04)",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
    },
    listContainer: {
      // marginTop: theme.spacing(3),
    },
    listItemGeneric: {
      paddingLeft: theme.spacing(3),
    },
    activeSidebarListItem: {
      backgroundColor: "#ffffff",
      color: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.secondary.main,
      },
    },
    listItem: {
      "&:hover": {
        background: theme.palette.primary.light,
      },
    },
    numberBeforeListItem: {
      backgroundColor: "#ffffff",
      color: theme.palette.secondary.dark,
      width: theme.spacing(3),
      height: theme.spacing(3),
      marginRight: theme.spacing(2),
      fontSize: theme.spacing(2),
      alignItems: "center",
    },
    dashboardBtn : {
      // backgroundColor:"green",
      paddingLeft: "33px",
      cursor:"pointer",
    },
    listText:{

      "& .MuiTypography-body1": {
        fontFamily: "Poppins, sans-serif !important",
        fontWeight: 400,
        fontSize: "16px !important",
      }
    }
  })
);

const Sidebar = (props: SidebarProps) => {
  const classes = sidebarUseStyles();
  const { window } = props;
  const { domainSurveyQuestion } = props;
  const { sidebarDomains, activeDomain } = props.CurrentPosition;
  const {
    mobileOpen,
    handleDrawerToggle,
    checkAllResponded,
    checkMessageResponse,
  } = props;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { values }: any = useFormikContext();
  const theme = useTheme();
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleStep = (step: number) => () => {
    if (!checkAllResponded(domainSurveyQuestion)) {
      return;
    }
    if (!checkMessageResponse(domainSurveyQuestion, values)) {
      return;
    }
    dispatch(setActiveDomain(sidebarDomains[step]));
  };

  const drawer = (
    <div>
      <WhiteSidebarLogo />
      <List className={classes.listContainer}>
      <ListItem className={classes. dashboardBtn} onClick={()=> history.push("/")}> 
      {/* <img src={dashboardWhite} alt="dashboard icon"/>    */}
        <ListItemText primary="Dashboard" className={classes.listText}></ListItemText>
          </ListItem>
        {sidebarDomains.map((text, index) => {
          if (activeDomain === text) {
            return (
              <ListItem
                button
                key={text + index}
                onClick={handleStep(index)}
                className={clsx(
                  classes.listItemGeneric,
                  classes.activeSidebarListItem
                )}
              >
                <>
                  <Avatar className={classes.numberBeforeListItem}>
                    {index + 1}
                  </Avatar>
                  <ListItemText primary={text} />
                </>
              </ListItem>
            );
          } else {
            return (
              <ListItem
                button
                key={text + index}
                onClick={handleStep(index)}
                className={clsx(classes.listItemGeneric, classes.listItem)}
              >
                <>
                  <Avatar className={classes.numberBeforeListItem}>
                    {index + 1}
                  </Avatar>
                  <ListItemText primary={text} className={classes.listText}/>
                </>
              </ListItem>
            );
          }
        })}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
