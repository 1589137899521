import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmChangeMatrix = ({
  openModal,
  setOpenModal,
  handleSubmitMatrix,
  handleUpdateMatrix,
}: any) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleYes = () => {
    handleUpdateMatrix();
    handleSubmitMatrix();
    setOpenModal(false);
  };

  const handleNo = () => {
    handleClose();
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            "You are going to change the risk matrix. In the new matrix, the risks are calculated differently. You should review risks to ensure they have the correct risk ratings. You can revert if you want by choosing the previous risk matrix. Do you want to change the risk matrix anyway?"
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => handleYes()} color="primary">
            Yes
          </Button>
          <Button onClick={handleNo} color="primary" autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmChangeMatrix;
