import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { RootState } from "../../redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { tokenFetch } from "../../redux/slices/createTokenSlice";
import { newUserAdd } from "../../redux/slices/addUserSlice";
import Confirmation from "../RiskManagement/confirmationModal";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
  profileTitle: {
    fontSize: 36,
    color: "#1071BA",
    fontWeight: "bold",
    margin: theme.spacing(0, 0, 0, 0),
  },
  modalContainer: {
    width: 500,
    paddingLeft: 140,
    paddingRight: 140,
    paddingTop: 50,
    paddingBottom: 100,
  },
  subtitle: {
    color: "red",
    fontSize: 12,
  },
  names: {
    display: "flex",
    justifyContent: "space-between",
  },
  roleAndTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  accountStatus: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: 53,
  },
  titleCenter: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
  },
  rolefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
  },
  titlefield: {
    flex: 1,
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  textFieldsRight: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
    // maxWidth: 240,
  },
  textFieldsLeft: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 7,
    [`& fieldset`]: {
      borderRadius: 8,
    },
    // maxWidth: 240,
  },
  emailtextFields: {
    marginTop: 5,
    marginBottom: 5,
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  accountStatusDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  accountStatusText: {
    fontSize: 15,
    fontWeight: "bold",
  },
  formControl: {
    flex: 1,
    marginLeft: 7,
  },
  dropDownSelect: {
    borderRadius: 8,
  },
  saveBtn: {
    backgroundColor: "#1071BA",
    color: "#fff",
    flex: 1,
    height: 50,
    marginRight: 10,
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
  cancelBtn: {
    backgroundColor: "#fff",
    color: "#1071BA",
    flex: 1,
    height: 50,
    marginLeft: 10,
    "&:hover": {
      backgroundColor: "#fff",
    },
    border: "1px solid #1071BA",
    borderRadius: 8,
  },
}));

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const AddDialog = ({ editState, setEditState, addNewRiskOwner }: any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const access_token = localStorage.getItem("Access_Token");
  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    role: false,
    title: false,
    email: false,
    status: false,
  });
  const [errorMsg, setErrorMsg] = useState({
    first_name: "",
    last_name: "",
    role: "",
    title: "",
    email: "",
    status: "",
  });
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    role: addNewRiskOwner ? "Risk owner" : "Control owner",
    title: "",
    email: "",
    status: "",
  });
  const [confirmation, setConfirmation] = useState(false);

  const token = useSelector((state: RootState) => state.createToken.token);

  const client_id = useSelector((state: RootState) => state.user.client);

  // console.log(client_id,"uuuuuu")

  const addNewUserEmail = useSelector(
    (state: RootState) => state.createToken.email
  );

  const handleValidate = () => {
    const errors: any = {};
    const errorMessage: any = {};
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validated = true;
    if (!userDetails.first_name) {
      errors["first_name"] = true;
      errorMessage["first_name"] = "Please enter the first name";
      validated = false;
    }
    if (!userDetails.last_name) {
      errors["last_name"] = true;
      errorMessage["last_name"] = "Please enter the last name";
      validated = false;
    }
    if (!userDetails.role) {
      errors["role"] = true;
      errorMessage["role"] = "Please enter role";
      validated = false;
    }
    if (reg.test(userDetails.email) === false) {
      errors["email"] = true;
      errorMessage["email"] = "Please enter a valid email";
      validated = false;
    }
    if (!userDetails.email) {
      errors["email"] = true;
      errorMessage["email"] = "Please enter email";
      validated = false;
    }
    if (!userDetails.title) {
      errors["title"] = true;
      errorMessage["title"] = "Please enter title";
      validated = false;
    }
    if (!userDetails.status) {
      errors["status"] = true;
      errorMessage["status"] = "Please enter status";
      validated = false;
    }
    if (validated === true) {
      handleSubmitUser();
      resetErrors();
    }
    setError({ ...errors });
    setErrorMsg({ ...errorMessage });
  };

  const handleSubmitUser = () => {
    let payload = {
      email: userDetails.email,
    };
    dispatch(tokenFetch(payload));
  };

  useEffect(() => {
    if (addNewUserEmail === userDetails.email && addNewUserEmail !== "") {
      let userPayload = {
        email: userDetails.email,
        username: userDetails.email,
        first_name: userDetails.first_name,
        last_name: userDetails.last_name,
        password: Math.random().toString(36).substring(2, 15),
        organization: "none",
        title: userDetails.title,
        role: userDetails.role,
        isAllowedPromotion: false,
        registration_token: token,
        is_active: userDetails.status === "Active" ? true : false,
        client_id: client_id,
      };
      dispatch(newUserAdd(userPayload))
        .then(unwrapResult)
        .then((response: any) => {
          handleCancel();
        })
        .catch((errorResponse) => {
          if (
            errorResponse.error &&
            errorResponse.error === "A user with that username already exists."
          ) {
            enqueueSnackbar("A user with this email already exists.", {
              autoHideDuration: 3000,
              key: 1,
              variant: "error",
            });
            setError({ ...error, email: true });
            setErrorMsg({
              ...errorMsg,
              email: "A user with this email already exists.",
            });
          } else {
            enqueueSnackbar("Error occured, Could not add user", {
              autoHideDuration: 2000,
              key: 1,
              variant: "error",
            });
          }
        });
    }
  }, [addNewUserEmail, token]);

  const resetErrors = () => {
    setError({
      first_name: false,
      last_name: false,
      role: false,
      title: false,
      email: false,
      status: false,
    });
  };

  const handleCancel = () => {
    setEditState(false);
    setUserDetails({
      first_name: "",
      last_name: "",
      role: "",
      title: "",
      email: "",
      status: "",
    });
    resetErrors();
  };

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserDetails({
      ...userDetails,
      status: event.target.value as string,
    });
  };

  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUserDetails({
      ...userDetails,
      role: event.target.value as string,
    });
  };

  const handleOpenConfirm = (confirm: boolean) => {
    setConfirmation(confirm);
  };

  const handleCheckChanges = () => {
    let isConfirm = false;
    if (
      userDetails.email ||
      userDetails.first_name ||
      userDetails.last_name ||
      userDetails.role ||
      userDetails.title ||
      userDetails.status
    ) {
      isConfirm = true;
      handleOpenConfirm(true);
    } else {
      isConfirm = false;
      handleOpenConfirm(false);
      handleCancel();
    }
    return isConfirm;
  };

  return (
    <div>
      <Dialog
        onClose={handleCheckChanges}
        aria-labelledby="customized-dialog-title"
        open={editState}
        onBackdropClick={() => handleCheckChanges()}
        onEscapeKeyDown={() => handleCheckChanges()}
        maxWidth={"md"}
      >
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.titleCenter}>
              <Typography className={classes.profileTitle}>
                Add New user
              </Typography>
            </div>
            <div className={classes.names}>
              <TextField
                className={classes.textFieldsLeft}
                required
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    first_name: e.target.value,
                  })
                }
                id="outlined-required"
                label="First Name"
                variant="outlined"
                error={error.first_name}
                helperText={error.first_name ? errorMsg.first_name : null}
              />
              <TextField
                className={classes.textFieldsRight}
                required
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    last_name: e.target.value,
                  })
                }
                id="outlined-required"
                label="Last Name"
                variant="outlined"
                error={error.last_name}
                helperText={error.last_name ? errorMsg.last_name : null}
              />
            </div>
            <div className={classes.roleAndTitle}>
              <FormControl variant="outlined" className={classes.rolefield}>
                <InputLabel>Role</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  required
                  onChange={handleChangeRole}
                  label="Role"
                  value={userDetails.role}
                  className={classes.dropDownSelect}
                  error={error.role}
                >
                  <MenuItem disabled value={"Administrator"}>
                    Administrator
                  </MenuItem>
                  <MenuItem value={"Risk manager"}>Risk manager</MenuItem>
                  <MenuItem value={"Risk owner"}>Risk owner</MenuItem>
                  <MenuItem value={"Control owner"}>Control owner</MenuItem>
                </Select>
                {error.role && (
                  <FormHelperText style={{ color: "red" }}>
                    {errorMsg.role}
                  </FormHelperText>
                )}
              </FormControl>
              <TextField
                className={classes.titlefield}
                required
                onChange={(e) =>
                  setUserDetails({ ...userDetails, title: e.target.value })
                }
                id="outlined-required"
                label="Title"
                variant="outlined"
                error={error.title}
                helperText={error.title ? errorMsg.title : null}
              />
            </div>
            <TextField
              className={classes.emailtextFields}
              required
              onChange={(e) =>
                setUserDetails({ ...userDetails, email: e.target.value })
              }
              id="outlined-required"
              label="Email Address"
              variant="outlined"
              fullWidth
              error={error.email}
              helperText={error.email ? errorMsg.email : null}
            />
            <div className={classes.accountStatus}>
              <div className={classes.accountStatusDiv}>
                <Typography className={classes.accountStatusText}>
                  Choose account status
                </Typography>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Status</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  required
                  onChange={handleChangeStatus}
                  label="Status"
                  error={error.status}
                  className={classes.dropDownSelect}
                >
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Disabled"}>Disabled</MenuItem>
                </Select>
                {error.status && (
                  <FormHelperText style={{ color: "red" }}>
                    {errorMsg.status}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className={classes.buttons}>
              <Button className={classes.saveBtn} onClick={handleValidate}>
                Save
              </Button>
              <Button className={classes.cancelBtn} onClick={handleCancel}>
                Cancel
              </Button>
            </div>
            <Confirmation
              confirmation={confirmation}
              setConfirmation={setConfirmation}
              handleCancel={handleCancel}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddDialog;
