import { server_url } from "../../types/constants";
import axios from "axios";

export async function securityControl(access_token: string)
{
  const url = `${server_url}/survey/dashboard_assessment/`;
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
