import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import { width } from "@mui/system";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,Label} from 'recharts';
import { useAppDispatch } from "../redux/store";
import {dmTableDashboard} from "../redux/slices/dmDashboardSlice";
import {dmGraphData} from "../redux/slices/dmGraphSlice";
import {docToWork} from "../redux/slices/docToWorkSlice";
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  card: {
    // width: "100%",
    // height: 100,
    borderRadius: 10,
    // boxShadow: "2px 3px 8px -1px rgba(0,0,0,0.2)",
    backgroundColor: "#FFFFF"
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: 40,
    backgroundColor:"white",
  },
  heading: {
    fontSize: 25,
    fontWeight: 600,
  },
  riskManagementTable: {
    borderCollapse: "collapse",
    width:"100%",
    height: "171px",
    background: "#F8F8F8",
    borderRadius: "8px",
    fontFamily: "Poppins, sans-serif",
    fontSize: 14,
    [`& th`]: {
      borderBottom: "1px solid #E8E8E8",
      fontWeight: "normal !important",
      padding:"4px",
      paddingTop:"8px !important",
      paddingBottom:"8px !important",
    },
    [`& td`]: {
      textAlign: "center",
      verticalAlign: "middle",
      padding: "4px",
      paddingTop:"9px !important",
      paddingBottom:"9px !important",
    },
    [`& td +td `]: {
        borderLeft:"1px solid #D2D2D2",
    },
    [`& th +th `]: {
        borderLeft:"1px solid #D2D2D2",
    },
    overflowY:"auto",
  },
  securityControlCompDropdown: {
    display: "flex",
    marginTop: "14px",
    justifyContent: "space-between",
  },
  dropSelect: {
    border: "1px solid #F8F8F8",
    marginLeft: 7,
    marginRight: 7,
    backgroundColor: "#F8F8F8",
    [`& fieldset `]: {
      border: "1px solid #F8F8F8",
      borderRadius: "8px",
    }
  },
  dropdownContainer: {
    // backgroundColor: "#F8F8F8",
    width: "30%",
    marginTop: "2% !important",
  },
  sectionHeader: {
    fontSize: "14px",
    color: "#1071BA",
    marginTop: "19px",
    marginLeft: "23px",
  },
  graph: {
      marginTop:"2%",
    ".&recharts-legend-wrapper": {
      width:"0px !important",
    }
  },
  /////////////
  middleSectionContainer: {
      display:"flex",
      marginTop:"2%",
  },
  riskReviewCounter: {
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    height: "171px",
    marginLeft: "15px",
    width: "29%",
  },
  reviewData: {
     padding:"18px",
  },
  noOfRisk : {
    fontSize: "24px",
    color: "black",
    fontWeight: 600,
},
noOfRiskText: {
    color:"black",
    borderBottom: "2px solid red",
    paddingBottom: "8px",
    fontSize: 14,
    fontWeight: 500,
},
  riskStatus: {
       marginTop: "15px !important",
       color: "red !important",
  }, 
  graphContainer: {
    backgroundColor:"#F8F8F8",
    marginTop:"19px",
    paddingBottom: 12,
    borderRadius: 8,
  },
  tableContainer: {
    height: "171px",
    overflowY:"auto",
    width:"70%",
  }
}))

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const OpenButton = withStyles((theme: Theme) => ({
  root: {
    color: "#F15642 !important",
    // height: 40,
    // width: 200,
    fontSize: 14,
    fontWeight: 300,
    backgroundColor: "#F8F8F8",
    textTransform: "lowercase",
    "&:hover": {
      backgroundColor: "#F8F8F8",
    },
    borderRadius: 8,
  },
}))(Button);

const DocumentManagementCard = ({docTowork}:any) => {
 
  const classes = useStyles();
  let dmDashboardData =  useSelector((state:RootState) => state.dmDashboardTableData);
  let graphData = useSelector((state:RootState) => state.dmGraphdata.graphData);
  let docToWorkData = useSelector((state:RootState) => state.docToWorkData.docList);
  const docLength = docToWorkData.length;
  const dispatch = useAppDispatch();
  const access_token = localStorage.getItem("Access_Token");
  const userPlan = useSelector((state: RootState) => state.user?.planType);
  useEffect(()=> {
    dispatch(dmTableDashboard({access_token}));
    dispatch(dmGraphData({access_token}));
    dispatch(docToWork({access_token}));
  },[]);
  return (
    <div className={classes.card} style={userPlan === "Pro" ? {}:{opacity: 0.5, pointerEvents: "none"}}>
      <div className={classes.cardContentWrapper}>
        <Typography className={classes.heading}>Document management</Typography>

           <Typography className={classes.sectionHeader}>Mandatory documents in draft</Typography>
           <div className = {classes.middleSectionContainer}>
           <div className={classes.tableContainer}>     
        <table className={classes.riskManagementTable}>
          <tr>
            <th style={{textAlign: "left", paddingLeft: 30}}>Doc type</th>
            <th >Num of documents</th>
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Policy</td>
            <td >{dmDashboardData.Policy}</td> 
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Procedure</td>
            <td >{dmDashboardData.Procedure}</td>
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Standards</td>
            <td >{dmDashboardData.Standards}</td>
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Guidelines</td>
            <td >{dmDashboardData.Guidelines}</td>
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Meeting minutes</td>
            <td >{dmDashboardData.Meeting }</td>
          </tr>
          <tr>
            <td style={{textAlign: "left", paddingLeft: 30}}>Others</td>
            <td >{dmDashboardData.Others}</td>
          </tr>
        </table>
         </div>
          <div className={classes.riskReviewCounter}>
                <div className={classes.reviewData}>
                <Typography className={classes.noOfRisk}>
                {docLength}
                </Typography>
                <Typography className={classes.noOfRiskText}>
                Documents <br/>
                to work on
                </Typography>
                {/* <Button variant="text" className={classes.riskStatus} onClick={() =>{docTowork()}}>Open</Button> */}
                <OpenButton onClick={docTowork}><Typography style={{textTransform: "none", marginTop: 10}}>Open</Typography></OpenButton>
                
                </div>
              </div> 
        </div>
        <Typography className={classes.sectionHeader}>Document management progress</Typography>
        <ResponsiveContainer width="100%" height={270} className={classes.graphContainer}>
          <LineChart width={730} height={250} data={graphData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }} className={classes.graph}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="days"> 
             <Label value="Days" offset={0} position="bottom" textAnchor="end"/>
             </XAxis>
            <YAxis label={{ value: 'Documents', angle: -90, position: 'insideLeft' }} />  
            <Tooltip />
            <Legend content={renderLegend} />
           
            <Line type="linear" dataKey="allDocuments" stroke="#F15642" dot={false} />
            <Line type="linear" dataKey="publishedDocuments" stroke="#1071BA" dot={false}/>
          </LineChart>
        </ResponsiveContainer>
      </div>
     
    </div>
  );
}

const renderLegend = (props:any) => {
  const { payload } = props;

  return (
    <div style={{display:"flex", flexDirection:"row", justifyContent:"flex-end", paddingTop: "20px"}}>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight: 15}}>
        <div style={{height: 5, width: 5, background:"#F15642", marginRight: 5}}></div>
        <span style={{color:"#F15642"}}>All documents</span>
      </div>
      <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight: 15}}>
        <div style={{height: 5, width: 5, background:"#1071BA", marginRight: 5}}></div>
        <span style={{color:"#1071BA"}}>Published documents</span>
      </div>    
    </div>
  );
}

export default DocumentManagementCard;