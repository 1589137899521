import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { matrixUpdate } from "../../api/matrix/riskMatrix";

export const matrixUpdateField = createAsyncThunk(
  "matrix/update",
  async ({ access_token, type, matrix }: any, thunkAPI) => {
    try {
      return await matrixUpdate(access_token, type, matrix);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateMatrix = {
    // matrix_choice: string;
  };
  
  const initialState: initialStateMatrix = {
    // matrix_choice: "",
  };
  
  const matrixUpdated = createSlice({
    name: "matrixUpdate",
    initialState,
    reducers: {
      resetMatrixUpdate: () => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(matrixUpdateField.fulfilled, (state, { payload }) => {
        // state.matrix_choice = payload.matrix_choice
      });
    },
  });
  
  export const { resetMatrixUpdate } = matrixUpdated.actions;
  
  export default matrixUpdated.reducer;