import { server_url } from "../../types/constants";
import axios from "axios";

export async function getDocument(
  access_token: string,
  applicability: string,
  type: string,
  docToWork: any,
) {
  //  const { } = docCredential.payload;
   console.log(docToWork,"aaaaaaa");
  const url = `${server_url}/rm/filter_main/doc/?applicability=${applicability}&type=${type}${docToWork == "true" ? `&filter=true`:``}`;

  // const url2 = `${server_url}/rm/filter_main/doc/?applicability=${applicability}&type=${type}/?filter=true`

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err:any) {
    throw err.response.data;
  }
}
