import { server_url } from "../../types/constants";
import axios from "axios";

export async function forgotPasswordLinkValid(time: string) {
  const url = `${server_url}/authentication/check_forgot_password_valid`;
  try {
    const response = await axios({
      method: "post",
      url: url,
      data: {
        time: time,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
