import React, { useEffect } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import DetailSection from "./DetailSection";
import { getStatementRequest } from "../../redux/slices/getStatementSlice";
import { useAppDispatch } from "../../redux/store";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8),
  },
  header: {
    fontWeight: "bold",
    fontSize: "36px",
    color: "#1071BA",
  },
  accordians: {
    paddingTop: theme.spacing(5),
  },
  menuButton: {
    maxHeight: theme.spacing(11),
    maxWidth: theme.spacing(11),
  },
  icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    color: "#1071BA",
  },
  
}));

const DetailPage = ({domain}:any) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
      const token = localStorage.getItem("Access_Token")
      if(token){
          dispatch(getStatementRequest({access_token: token}));
      }
  }, [domain]);


  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  const isFinishedFirstCore = useSelector(
    (state: RootState) => state.user.isFinishedFirstCore
  );


  return (
    <div className={classes.root}>
      <Typography variant="h3" className={classes.header}>
      {domain ? domain : "Information security policies"}
      </Typography>
      <div className={classes.accordians}>
        <DetailSection domain={domain ? domain : "Information security policies"} />
      </div>
    </div>
  )
};

export default DetailPage;
