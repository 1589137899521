import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {mulipleRestore} from "../../api/documentManagement/multipleRestore";
import {downloadSelDoc} from "../../api/documentManagement/downloadSelDoc";

export const downloadMultipleDoc = createAsyncThunk(
  "/DownloadSelSliceStatus",
  async ({file_name, user_id}: any, thunkAPI) => {
    try {
      return await downloadSelDoc(file_name, user_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const downloadMultipleReducer = createSlice({
  name: "reducerMultipleDownload",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(downloadMultipleDoc.fulfilled, (state, { payload }) => {
      state.value = '';
      
    });
  },
});
export default downloadMultipleReducer.reducer;