import { server_url } from "../../types/constants";
import axios from "axios";

export async function submitFeedbackApi(
  access_token: string,
  uuid: string,
  question1: string,
  question2: string,
  question4: string,
  question3_1: string,
  question3_2: string,
  question3_3: string
) {
  const url = `${server_url}/feedback/${uuid}/`;
  try {
    const response = await axios.post(
      url,
      {
        question1,
        question2,
        question4,
        question3_1,
        question3_2,
        question3_3,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
