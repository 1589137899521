import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MainPage from "./views/MainPage";
import SignIn from "./views/SignIn";
import WelcomePage from "./views/WelcomePage";
import CookieConsent from "react-cookie-consent";
import Link from "@material-ui/core/Link";
import PrivateRoute from "./components/PrivateRoute";
import Feedback from "./views/CoreFeedback/Feedback";
import Assessment from "./views/Core Assessment/Assessment";
import Report from "./views/Report/Report";
import FilterAssessment from "./views/FilterAssessment/FilterAssessment";
import VerifyLink from "./components/VerifyLink";
import UsersProfile from "./views/UsersProfile/UsersProfile";
import MySettings from "./views/MySettings/MySettings";
import SignUp from "./views/SignUp";
import ChangePassword from "./views/ChangePassword/ChangePassword";
import RiskManagement from "./views/RiskManagement/RiskManagement";
import RemediationPlan from "./views/Remediation/RemediationPlan";
import EmailSubmit from "./views/ForgotPassword/EmailSubmit";
import NewPassword from "./views/ForgotPassword/NewPassword";
import HumanResourceSecurity from "./views/SOA/HumanResourceSecurity";
import DocumentManagementFeatures from "./views/Document Management/DocumentManaementFeatures";
import Payment from "./views/Plans/plan";
import PaymentSuccess from "./views/Plans/paymentSuccess";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={MainPage} />
        {/* <Route exact path="/plans" component={Payment} /> */}
        <Route exact path="/payment-success" component={PaymentSuccess} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/login" component={SignIn} />
        <Route exact path="/forgotpassword" component={EmailSubmit} />
        <Route exact path="/passwordreset" component={NewPassword} />
        <VerifyLink
          exact
          path="/:registration_token/signup"
          component={SignUp}
        />
        <PrivateRoute exact path="/welcome-page/" component={WelcomePage} />
        <PrivateRoute exact path="/core/:uuid/" component={Assessment} />
        <PrivateRoute exact path="/feedback/:uuid/" component={Feedback} />
        <PrivateRoute exact path="/report/:uuid/" component={Report} />
        <PrivateRoute
          exact
          path="/filter/:uuid/"
          component={FilterAssessment}
        />
        <PrivateRoute exact path="/technical/:uuid/" component={Assessment} />
        <PrivateRoute exact path="/usersprofile/" component={UsersProfile} />
        <PrivateRoute exact path="/settings/" component={MySettings} />
        <PrivateRoute exact path="/changepassword" component={ChangePassword} />
        <PrivateRoute exact path="/riskmanagement" component={RiskManagement} />
        <PrivateRoute exact path= "/soa" component={HumanResourceSecurity} />
        <PrivateRoute exact path= "/docmanagement/:isDocToWork/" component={DocumentManagementFeatures}/>
        <PrivateRoute
          exact
          path="/remediationplan/:riskId"
          component={RemediationPlan}
        />
        <PrivateRoute
          exact
          path="/remediationplan"
          component={RemediationPlan}
        />
        <Redirect from="*" to="/" />
      </Switch>
   
    </div>
  );
}

export default App;
