import { createSlice } from "@reduxjs/toolkit";

export const initialStateValue = 0;

export const optionId = createSlice({
  name: "idStore",
  initialState: { value: initialStateValue },
  reducers: {
    getData: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { getData } = optionId.actions;

export default optionId.reducer;