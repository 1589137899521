import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {newDocUpload} from "../../api/documentManagement/newDocUpload"

export const newFileUpload = createAsyncThunk(
  "/uploaddocumentStatus",
  async ({ access_token, question_id,upload_type,file,type,id}: any, thunkAPI) => {
    try {
      return await newDocUpload(access_token, question_id,upload_type,file,type,id);  // instead of upload name should be Replace
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const uploadReducer = createSlice({
  name: "newfilereducer",
  initialState: {
    value: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(newFileUpload.fulfilled, (state, { payload }) => {
      state.value = '';
    });
  },
});

export default uploadReducer.reducer;