import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {securityControlGraph} from "../../api/dashboard/securityControlGraph"; 
export const securityControlGraphData = createAsyncThunk(
  "/securityGraph",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await securityControlGraph(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface graphValue {
    days: number,
    Technical: number,
    Core: number,
}

export type initialStateDocument = {
    graphData: Array<graphValue>;
  };

  const initialState: initialStateDocument = {
    graphData: [],
  };

  const getGraphData = createSlice({
    name: "securityGraphSlice",
    initialState,
    reducers: {
      graphDataa: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(securityControlGraphData.fulfilled, (state, { payload }) => {
            state.graphData = [];
            payload?.forEach((value:any,index:any) => {
               state?.graphData?.push(
                   {
                       days:value.days,
                       Technical:value.Technical,
                       Core:value.Core
                   }
               );

            });
        });
    },
});
export const {graphDataa } = getGraphData.actions;
export default getGraphData.reducer;