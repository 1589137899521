import { User } from "../../redux/slices/userSlice";
import { server_url } from "../../types/constants";
import axios from "axios";

export async function signUpApi(
  email: string,
  password: string,
  first_name: string,
  last_name: string,
  organization: string,
  title: string,
  role: string,
  client_id: number,
  isAllowedPromotion: boolean,
  isTemporary: boolean,
  registration_token?: string
): Promise<User> {
  const url = `${server_url}/authentication/register/`;
  try {
    const signUpResponse = await axios.post<User>(url, {
      username: email,
      email: email,
      password: password,
      first_name,
      last_name,
      organization,
      title,
      role,
      client_id,
      isAllowedPromotion,
      registration_token,
      isTemporary,
      is_active: true,
    });
    if (signUpResponse.status >= 400) {
      throw signUpResponse;
    }
    return signUpResponse.data as User;
  } catch (err) {
    throw err.response.data;
  }
}
