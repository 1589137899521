import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { documentManagement } from "../../api/dashboard/documentManagement";
export const dmTableDashboard = createAsyncThunk(
  "/dmtable",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await documentManagement(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export type initialStateType = {
        Policy: number,
        Procedure: number,
        Standards: number,
        Guidelines: number,
        Meeting :number,
        Others:number,
  };
 const initialState: initialStateType = {
    Policy:-1,
    Procedure:-1,
    Standards:-1,
    Guidelines:-1,
    Meeting:-1,
    Others:-1,

  };

  const getDmTableData = createSlice({
    name: "dmDashboardTableSlice",
    initialState,
    reducers: {
      tableData: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(dmTableDashboard.fulfilled, (state, { payload }) => {
          state.Policy=payload.Policy;
          state.Procedure=payload.Procedure;
          state.Standards=payload.Standards;
          state.Guidelines=payload.Guidelines;
          state.Meeting=payload.Meeting;
          state.Others=payload.Others;
        });
    },
});
export const {tableData } = getDmTableData.actions;
export default getDmTableData.reducer;