import { useState, useEffect } from "react";
import { Grid, Typography, TextField, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAppDispatch } from "../../redux/store";
import { getAllSoaUsers } from "../../redux/slices/getSoaUserSlice";
import ContentData from "./ContentData";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
  btn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 13,
    width: "135px",
    cursor: "pointer",
    borderBottom: "1px solid #e5e5e5",
  },
  selectedBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 8,
    padding: 13,
    width: "135px",
    backgroundColor: "#1071BA",
    color: "#FFFFFF",
    cursor: "pointer",
  },
  down: {
    display: "flex",
  },
  gridContainer: {
    margin: "30px 0",
  },
  gridTitle: {
    margin: "0px 0",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  select: {
    marginTop: "-1%",
  },
}));

const Content = ({ content }: any) => {
  const classes = useStyles();
  const [state, setState] = useState(0);
  const [editState, setEditState] = useState(false);
  
  const access_token = localStorage.getItem("Access_Token");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllSoaUsers({ access_token }));
  }, [content]);

  return (
    <div>
      <hr />
      <Grid container className={classes.gridContainer} spacing={2}>
        <Grid xs={3} container>
          <div className={classes.down}>
            <div>
              {content.map((item: any, index: any) => {
                return (
                  <div
                    className={
                      state === index ? classes.selectedBtn : classes.btn
                    }
                    onClick={(e) => {setState(index)}}
                  >
                    <Typography>{item.control_id}</Typography>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
        {content.map((item: any, index: any) => {
          if(state===index){
            console.log("dfddfdfdsfsddfs",state, index)
          return (
            <Grid xs={9} container>
              <ContentData item={item} />
            </Grid>
          );
          }
        })}
      </Grid>

    </div>
  );
};

export default Content;
