import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getControls } from "../../api/remediation/controlList";

export const controlListFetch = createAsyncThunk(
  "control/list",
  async ({ access_token }: any, thunkAPI) => {
    try {
      return await getControls(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface controlList {
  Status: string;
  closed_date: string;
  comments: string;
  control_group_name: string;
  control_name: string;
  control_owner: number;
  created_date: string;
  due_date: string;
  id: number;
  justification: string;
  mitigation: string;
  risk: number;
  risk_name: string;
  risk_rating: string;
  risk_response: string;
  control_owner_name: string;
  risk_uuid: string;
}

export type initialStateRiskList = {
  allControlList: Array<controlList>;
};

const initialState: initialStateRiskList = {
  allControlList: [],
};

const allcontrolList = createSlice({
  name: "controlListSlice",
  initialState,
  reducers: {
    resetControlList: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(controlListFetch.fulfilled, (state, { payload }) => {
      state.allControlList = [];
      // for (let i = 0; i < payload.length; i++) {
      //   state.allControlList.push({
      //     Status: payload[i].Status,
      //     closed_date: payload[i].closed_date,
      //     comments: payload[i].comments,
      //     control_group_name: payload[i].control_group_name,
      //     control_name: payload[i].control_name,
      //     control_owner: payload[i].control_owner,
      //     created_date: payload[i].created_date,
      //     due_date: payload[i].due_date,
      //     id: payload[i].id,
      //     justification: payload[i].justification,
      //     mitigation: payload[i].mitigation,
      //     risk: payload[i].risk,
      //     risk_name: payload[i].risk_name,
      //     risk_rating: payload[i].risk_rating,
      //     risk_response: payload[i].risk_response,
      //     control_owner_name: payload[i].control_owner_name,
      //     risk_uuid: payload[i].risk_uuid,
      //   });
      // }
      payload.forEach((value: any) => {
        state.allControlList.push({
          Status: value.Status,
          closed_date: value.closed_date,
          comments: value.comments,
          control_group_name: value.control_group_name,
          control_name: value.control_name,
          control_owner: value.control_owner,
          created_date: value.created_date,
          due_date: value.due_date,
          id: value.id,
          justification: value.justification,
          mitigation: value.mitigation,
          risk: value.risk,
          risk_name: value.risk_name,
          risk_rating: value.risk_rating,
          risk_response: value.risk_response,
          control_owner_name: value.control_owner_name,
          risk_uuid: value.risk_uuid,
        });
      });
    });
  },
});

export const { resetControlList } = allcontrolList.actions;

export default allcontrolList.reducer;
