import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addRisk } from "../../api/risk/addRisk";

export const newRiskAdd = createAsyncThunk(
  "risk/addRisk",
  async (
    {
      access_token,
      risk_status,
      risk_group,
      name,
      description,
      vulnerability,
      likelihood,
      likelihood_justification,
      impact,
      impact_justification,
      risk_rating,
      existing_controls,
      risk_response,
      residual_risk_rating,
      related_project,
      risk_owner,
      risk_review_date,
      comments,
      risk_closure_justification,
      iso_control,
    }: any,
    thunkAPI
  ) => {
    try {
      return await addRisk(
        access_token,
        risk_status,
        risk_group,
        name,
        description,
        vulnerability,
        likelihood,
        likelihood_justification,
        impact,
        impact_justification,
        risk_rating,
        existing_controls,
        risk_response,
        residual_risk_rating,
        related_project,
        risk_owner,
        risk_review_date,
        comments,
        risk_closure_justification,
        iso_control,
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  id: number;
};

const initialState: initialStateUsersList = {
  id: 0,
};

const RiskCreate = createSlice({
  name: "addNewRisk",
  initialState,
  reducers: {
    newRisk: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newRiskAdd.fulfilled, (state, { payload }) => {
      state.id = payload.id;
    });
  },
});

export const { newRisk } = RiskCreate.actions;

export default RiskCreate.reducer;
