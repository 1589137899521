import React, { useEffect, useState } from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";
import Card from "@material-ui/core/Card";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../redux/store";
import { subscriptionDetail } from "../../redux/slices/subscriptionDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    overflowY: "auto",
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

const ColorButton = withStyles((theme: Theme) => ({
  root: {
    color: "#fff",
  backgroundColor: "#1071BA",
    height: 50,
    width: 250,
    fontSize: 18,
    fontWeight: 600,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#1071BA",
    },
    borderRadius: 8,
  },
}))(Button);

const PaymentSuccess = (props: any) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(()=>{
    const query = props.location.search;
    const urlParams = new URLSearchParams(query);
    const session_id: string = urlParams.get("session_id") || "";
    const success: string = urlParams.get("success") || "";
    console.log(session_id,"uuuuuu");
    
    if(session_id && success){
      const payload = {
        session_id: session_id,
      }
      dispatch(subscriptionDetail(payload)).then(unwrapResult).then((response: any) => {
        history.replace(`https://www.4ciso.com/`);
      })
    }
  },[])

  return (
    <>
      <div className={classes.root}>
        Payment successfull. Redirecting....
      </div>
    </>
  );
}

export default PaymentSuccess;