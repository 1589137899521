import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUploadButtonData } from "../../api/documentManagement/getUploadButtonData";

export const uploadBtnDataCategory = createAsyncThunk(
  "/getuploadbtndata",
  async ({ access_token,type }: any, thunkAPI) => {
    console.log(type,'typpppppppppppppp');
    try {
      return await getUploadButtonData(access_token,type);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export interface uploadbtnlist {
  question_id: string;
  isoControls: string;
}
export type initialStateDocument = {
  uploadBtnList: Array<uploadbtnlist>;
};
const initialState: initialStateDocument = {
uploadBtnList: [],
};
const uploadListGet = createSlice({
  name: "uploadBtnListSlice",
  initialState,
  reducers: {
    getuploadbtnlist: () => initialState,
  },
  // here getting an error if getuploaddocdata api does not provide payload "unhandeled error" 
  extraReducers: (builder) => {
    builder.addCase(uploadBtnDataCategory.fulfilled, (state, { payload }) => {
      state.uploadBtnList = [];
    //   console.log(payload,'rrrrrrrrrrrr');
      payload?.forEach((value: any) => {
        state?.uploadBtnList?.push({
          question_id: value.question_id,
          isoControls: value.isoControls,
        });
      });
    });
  },
});
export const { getuploadbtnlist } = uploadListGet.actions;
export default uploadListGet.reducer;