import { server_url } from "../../types/constants";
import axios from "axios";

export async function getSoaUser(access_token:string) {
  const url = `${server_url}/rm/adduser/`;
  try {
    const response = await axios({
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
