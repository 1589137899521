import { server_url } from "../../types/constants";
import axios from "axios";

export async function editControl(
  access_token: string,
  id: number,
  risk: string,
  control_group_name: string,
  control_name: string,
  mitigation: string,
  Status: string,
  due_date: string,
  control_owner: string,
  closed_date: string,
  comments: string,
  justification: string
) {
  const url = `${server_url}/rm/update/riskresponse/${id}/`;
  try {
    const response = await axios({
      method: "patch",
      url: url,
      data: {
        risk: risk,
        control_group_name: control_group_name,
        control_name: control_name,
        mitigation: mitigation,
        Status: Status,
        due_date: due_date,
        control_owner: control_owner,
        closed_date: closed_date,
        comments: comments,
        justification: justification,
      },
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
