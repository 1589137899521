import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { riskMatrixSet } from "../../api/matrix/riskMatrix";

export const riskMatrixPush = createAsyncThunk(
  "matrix/set",
  async ({ access_token, matrix }: any, thunkAPI) => {
    try {
      return await riskMatrixSet(access_token, matrix);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateMatrix = {
  matrix_choice: string;
};

const initialState: initialStateMatrix = {
  matrix_choice: "",
};

const matrix = createSlice({
  name: "riskMatrixSet",
  initialState,
  reducers: {
    resetRiskMatrix: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(riskMatrixPush.fulfilled, (state, { payload }) => {
      state.matrix_choice = payload.matrix_choice
    });
  },
});

export const { resetRiskMatrix } = matrix.actions;

export default matrix.reducer;
