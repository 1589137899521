import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterDocument } from "../../api/documentManagement/filterDocument";

export const documentFilterStatus = createAsyncThunk(
  "/filterdocumentStatus",
  async ({ access_token,applicability,status,type}: any, thunkAPI) => {

    try {
      return await filterDocument(access_token,applicability,status,type);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export interface doclist {
    id: number;
    name: string;
    question_id: string;
    iso_control: string;
    last_updated: string;
    status: string;
    type: string;
    applicability: string;
    owner: string;
    ownerid:string;
    index:number
  }
  export type initialStateDocument = {
    docList: Array<doclist>;
  };

  const initialState: initialStateDocument = {
    docList: [],
  };
  
const filterDoc = createSlice({
    name: "docFilter",
    initialState,
    reducers: {
      documentList: () => initialState,
    },
    extraReducers: (builder) => {
      builder.addCase(documentFilterStatus.fulfilled, (state, { payload }) => {
        state.docList = [];
        payload?.forEach((value: any, index:any) => {
          let nameWithId = value.name.split('@');
          state?.docList?.push({
            id: value.id,
            name: nameWithId[0],
            question_id: value.question_id,
            iso_control: value.iso_control,
            last_updated: value.last_updated,
            status: value.status,
            type: value.type,
            applicability: value.applicability,
            owner: value.owner,
            ownerid:value.ownerid,
            index:index+1
          });
        });
      });
    },
  });
  export const { documentList } = filterDoc.actions;
  export default filterDoc.reducer;