import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"; 
import { getRmTable } from "../../api/dashboard/riskmanagementTable";
export const rmDashboardTable = createAsyncThunk(
  "/riskManagementTable",
  async ({access_token}: any, thunkAPI) => {
    try {
      return await getRmTable(access_token);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export interface tableDataType {
    size:number,
    group_name: string,
    Low: number,
    Moderate: number,
    Critical: number,
    Catastrophic: number,
    Medium:number,
    High:number,
    Major:number,
}

export type initialStateDocument = {
    tableData: Array<tableDataType>;
  };

  const initialState: initialStateDocument = {
    tableData: [],
  };
  const getResponseTable = createSlice({
    name: "rmDashboardTableSlice",
    initialState,
    reducers: {
      graphDataa: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(rmDashboardTable.fulfilled, (state, { payload }) => {
            state.tableData = [];
            payload?.forEach((value:any,index:any) => {
               state?.tableData?.push(
                   {
                    size:value.size,
                    group_name:value.group_name,
                    Low:value.Low,
                    Moderate:value.Moderate,
                    Critical:value.Critical,
                    Catastrophic:value.Catastrophic,
                    Medium:value.Medium,
                    High:value.High,
                    Major:value.Major
                   }
               );

            });
        });
    },
});
export const {graphDataa } = getResponseTable.actions;
export default getResponseTable.reducer;