import React from "react";
import { Button, Container, Typography } from "@material-ui/core";
import HeaderProfile from "../layout/HeaderProfile";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../redux/store";
import { createAssessmentThunk } from "../redux/slices/surveySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import Box from "@material-ui/core/Box";
import bigWhiteLogo from "../assets/bigWhiteLogo.svg";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    backgroundImage: `url(${bigWhiteLogo})`,
    backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundOrigin: "border-box",
    backgroundSize: "600px 900px",
    background: "#FFFFFF",
  },
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
    marginTop: theme.spacing(2),
    padding: theme.spacing(6),
  },
  header: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(2),
    color: "#000000",
  },
  button: {
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(4),
    fontWeight: 800,
    width: theme.spacing(40),
    height: theme.spacing(12),
    // backgroundImage: `url(${buttonBackground})`,
    // backgroundOrigin: 'border-box',
  },
}));
export default function WelcomePage() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  return (
    <>
      <div className={classes.imageContainer}>
        <HeaderProfile />
        <Container maxWidth="md" className={classes.container}>
          <Typography className={classes.header} variant="h5" gutterBottom>
            <Box fontWeight={700}>Hello and welcome!</Box>
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            paragraph
            className={classes.text}
          >
            Great job! We build this system to help professionals like you
            quickly start building the organization's information security
            management program (ISMP). Tool designed based on ISO 27001 and ISO
            27002 standards, so it helps you prepare the organization for the
            ISO 27001 certification. Without further due, let us briefly explain
            the process.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            paragraph
            className={classes.text}
          >
            You will start with the "Core controls" assessment. It helps you
            evaluate the core controls, which are essential to building an
            effective information security program. Further, you can run the
            "Technical controls" assessment to evaluate the controls' readiness
            in the areas such as HR, access controls, operational and network
            security, and other areas. Upon completing each assessment, you will
            get access to the report, including recommendations for missing
            controls and a list of mandatory documents.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            paragraph
            className={classes.text}
          >
            Please keep in mind self-assessment does not evaluate the control's
            quality. Therefore, the report can not be used for the Information
            Security Management program audit purposes. If you have any
            questions or feedback, please send them to hello@4ciso.com. We will
            be glad to help you.
          </Typography>
          <Button
            className={clsx(classes.button)}
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(
                createAssessmentThunk({
                  category_name: "Core Assessment",
                })
              )
                .then(unwrapResult)
                .then((response: any) => {
                  if (response.survey.uuid) {
                    history.push(`/core/${response.survey.uuid}/`);
                  }
                })
                .catch((error) => {});
            }}
          >
            START CORE
            <br />
            CONTROLS ASSESSMENT
          </Button>
        </Container>
      </div>
    </>
  );
}
