import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addControl } from "../../api/remediation/addControl";

export const newControlAdd = createAsyncThunk(
  "control/addControl",
  async (
    {
      access_token,
      risk,
      control_group_name,
      control_name,
      mitigation,
      Status,
      due_date,
      control_owner,
      closed_date,
      comments,
      justification,
    }: any,
    thunkAPI
  ) => {
    try {
      return await addControl(
        access_token,
        risk,
        control_group_name,
        control_name,
        mitigation,
        Status,
        due_date,
        control_owner,
        closed_date,
        comments,
        justification
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export type initialStateUsersList = {
  id: number;
};

const initialState: initialStateUsersList = {
  id: 0,
};

const ControlCreate = createSlice({
  name: "addNewControl",
  initialState,
  reducers: {
    newControl: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(newControlAdd.fulfilled, (state, { payload }) => {
      state.id = payload.id;
    });
  },
});

export const { newControl } = ControlCreate.actions;

export default ControlCreate.reducer;
